import * as shortenURLActions from '@dashboard/store/actions/url/shorten'
import { LoadingStates } from '@dashboard/lib/types'

export interface State {
  shortenURLStates: Record<string, LoadingStates | undefined>
  shortenURLResults: Record<string, string | undefined>
}

const INITIAL_STATE: State = {
  shortenURLStates: {},
  shortenURLResults: {},
}

type Action = shortenURLActions.Action

export default function urlReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Get Public Relationship Data
    case shortenURLActions.REQUEST:
      return {
        ...state,
        shortenURLStates: {
          ...state.shortenURLStates,
          [action.url]: 'loading',
        },
      }
    case shortenURLActions.REQUEST_SUCCESS:
      return {
        ...state,
        shortenURLStates: {
          ...state.shortenURLStates,
          [action.url]: 'success',
        },
        shortenURLResults: {
          ...state.shortenURLResults,
          [action.url]: action.shortened,
        },
      }
    case shortenURLActions.REQUEST_FAILURE:
      return {
        ...state,
        shortenURLStates: {
          ...state.shortenURLStates,
          [action.url]: 'failure',
        },
      }

    default:
      return state
  }
}
