import { SkillAssessmentCategory } from '@dashboard/lib/types'

export interface SkillAssessmentResponse {
  id: string
  skill: SkillAssessmentCategory
  percentage_score: number
}

export const REQUEST = 'GET_SKILL_ASSESSMENTS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'GET_SKILL_ASSESSMENTS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: SkillAssessmentResponse[]
}

export const REQUEST_FAILURE = 'GET_SKILL_ASSESSMENTS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
