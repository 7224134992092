import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export const REQUEST = 'GLOBAL_PROFILES_GET_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'GLOBAL_PROFILES_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profiles: LinkedInProfile[]
  query: string
}
const requestSuccess = (
  profiles: LinkedInProfile[],
  query: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  profiles,
  query,
})

export const REQUEST_FAILURE = 'GLOBAL_PROFILES_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  query: string
}
const requestFailure = (
  error: unknown,
  query: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  query,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface LinkedInProfile {
  name: string
  linkedInUrl: string
}

export function globalApiSearch(query: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request(query))

      Api()
        .get<APIResponse<LinkedInProfile[]>>('/globalProfiles', {
          params: query,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(data.data, query))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(err, query))
          reject(
            `Error calling globalApiSearch (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
