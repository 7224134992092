export const REQUEST = 'CHANGE_ROLE_START'
interface RequestAction {
  type: typeof REQUEST
  userUUID: string
}

export const REQUEST_SUCCESS = 'CHANGE_ROLE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  userUUID: string
}

export const REQUEST_FAILURE = 'CHANGE_ROLE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  userUUID: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
