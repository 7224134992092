import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'UPDATE_PHOTO_START'
type RequestAction = {
  type: typeof REQUEST
  profileId: string
}
const request = (profileId: string): RequestAction => ({
  type: REQUEST,
  profileId,
})

export const REQUEST_SUCCESS = 'UPDATE_PHOTO_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  profileId: string
}
const requestSuccess = (profileId: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  profileId,
})

export const REQUEST_FAILURE = 'UPDATE_PHOTO_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  profileId: string
}
const requestFailure = (profileId: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  profileId,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function update(profileId: string, file: File) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(profileId))

      if (file instanceof Blob) {
        const data = new FormData()
        const fileName = file && file.name ? file.name : ''
        data.append('photo', file, fileName)

        Api()
          .put(`/profiles/${profileId}/photo`, data)
          .then(() => {
            dispatch(requestSuccess(profileId))
            resolve()
          })
          .catch(err => {
            dispatch(requestFailure(profileId))
            reject(
              `Error calling updatePhoto (Status: ${err.response
                ?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      } else {
        dispatch(requestFailure(profileId))
      }
    })
  }
}
