import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export const REQUEST = 'PROFILE_TAGS_DESTROY_START'
type requestAction = { key: string; type: typeof REQUEST }
const request = (key: string): requestAction => ({
  key,
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PROFILE_TAGS_DESTROY_SUCCESS'
type requestSuccessAction = {
  key: string
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (key: string): requestSuccessAction => ({
  key,
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'PROFILE_TAGS_DESTROY_FAILURE'
type requestFailureAction = { key: string; type: typeof REQUEST_FAILURE }
const requestFailure = (key: string): requestFailureAction => ({
  key,
  type: REQUEST_FAILURE,
})

export type Action = requestAction | requestSuccessAction | requestFailureAction

export function destroyTag(tagId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(tagId))

      Api()
        .delete(`/tags/${tagId}`)
        .then(() => {
          dispatch(requestSuccess(tagId))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(tagId))
          reject(
            `Error calling destroyTag (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
