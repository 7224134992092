import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api, AuthedHeaders } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface JobBehavior {
  average_score: number
  text: string
}

export const REQUEST = 'JOB_BEHAVIORS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'JOB_BEHAVIORS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: JobBehavior[]
}
const requestSuccess = (
  key: string,
  data: JobBehavior[],
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'JOB_BEHAVIORS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadJobBehaviors(
  job_id: string,
  authedHeaders?: AuthedHeaders,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<JobBehavior[]>((resolve, reject) => {
      dispatch(request(job_id))

      Api(authedHeaders)
        .get<APIResponse<JobBehavior[]>>('playbooks/job_behaviors', {
          params: { job_id },
        })
        .then(({ data }) => {
          dispatch(requestSuccess(job_id, data.data))

          resolve(data.data)
        })
        .catch(error => {
          dispatch(requestFailure(job_id, error))

          reject(error.response?.data)
        })
    })
  }
}
