export const REQUEST = 'SKILL_ASSESSMENTS_UPDATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'SKILL_ASSESSMENTS_UPDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}

export const REQUEST_FAILURE = 'SKILL_ASSESSMENTS_UPDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
