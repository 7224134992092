import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

export const REQUEST = 'DISMISS_TOAST'
const request = (toastId: string) =>
  ({
    type: REQUEST,
    toastId,
  }) as const

export type Action = ReturnType<typeof request>

export function dismissToast(toastId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>(resolve => {
      dispatch(request(toastId))
      resolve()
    })
  }
}
