import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse, EmailResponse } from '@dashboard/lib/types'

export const REQUEST = 'TEAM_CUSTOM_EMAIL_GET_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'TEAM_CUSTOM_EMAIL_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: EmailResponse[]
}
const requestSuccess = (data: EmailResponse[]): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'TEAM_CUSTOM_EMAIL_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getEmails(teamId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request())

      Api()
        .get<APIResponse<EmailResponse[]>>(`/teams/${teamId}/mailers`)
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling getEmails (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
