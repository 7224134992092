import { APIPagedResponse, JobResponse } from '@dashboard/lib/types'

export const REQUEST = 'JOBS_FOR_CANDIDATE_GET_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'JOBS_FOR_CANDIDATE_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<JobResponse>
}

export const REQUEST_FAILURE = 'JOBS_FOR_CANDIDATE_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
