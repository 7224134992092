import { CustomContentProfileResponse } from '@dashboard/lib/types'

export const REQUEST = 'CUSTOM_CONTENT_PROFILE_GET_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'CUSTOM_CONTENT_PROFILE_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: CustomContentProfileResponse
}

export const REQUEST_FAILURE = 'CUSTOM_CONTENT_PROFILE_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
