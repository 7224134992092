import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'

export const REQUEST = 'CONNECT_SOCIAL_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'CONNECT_SOCIAL_SUCCESS'
type RequestSuccessAction = { type: typeof REQUEST_SUCCESS }
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'CONNECT_SOCIAL_FAILURE'
type RequestFailureAction = { type: typeof REQUEST_FAILURE }
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function connectSocial(social_id: string, profile_id: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      PublicApi()
        .post('public/social', { social_id, profile_id })
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling connectSocial (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
