import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIErrorResponse } from '@dashboard/lib/types'

export const REQUEST = 'TEAM_INVITE_CANDIDATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_INVITE_CANDIDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'TEAM_INVITE_CANDIDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: APIErrorResponse | null
}
const requestFailure = (
  key: string,
  error: APIErrorResponse | null,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function inviteCandidate(
  teamId: string,
  email: string,
  candidateId: string,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(candidateId))

      const params = { email, candidate_id: candidateId }

      Api()
        .post(`/teams/${teamId}/invites/correlation`, params)
        .then(() => {
          dispatch(requestSuccess(candidateId))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(candidateId, err.response?.data))
          reject(err.response?.data || null)
        })
    })
  }
}
