import { APIPagedResponse } from '@dashboard/lib/types'

type SupportedIntegrationSources = 'workable'

export interface IntegrationTokenResponse {
  id: string
  identifier: string
  source: SupportedIntegrationSources
  token: string
  active: boolean
}

export const REQUEST = 'GET_INTEGRATIONS_START'
type RequestAction = { type: typeof REQUEST; key: string }

export const REQUEST_SUCCESS = 'GET_INTEGRATIONS_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<IntegrationTokenResponse>
}

export const REQUEST_FAILURE = 'GET_INTEGRATIONS_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
