export const REQUEST = 'UPDATE_INTEGRATION_TOKEN_START'
type RequestAction = { type: typeof REQUEST; key: string }

export const REQUEST_SUCCESS = 'UPDATE_INTEGRATION_TOKEN_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
}

export const REQUEST_FAILURE = 'UPDATE_INTEGRATION_TOKEN_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
