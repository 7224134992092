import * as InviteActions from '@dashboard/store/actions/invite'

import { LoadingStates } from '@dashboard/lib/types'

export type State = {
  invitesStates: Record<string, LoadingStates | undefined>
  invitesResults: Record<string, InviteActions.InviteResponse[] | undefined>
}

const INITIAL_STATE: State = {
  invitesStates: {},
  invitesResults: {},
}

type Action = InviteActions.Action

export default function inviteReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case InviteActions.REQUEST:
      return {
        ...state,
        invitesStates: {
          ...state.invitesStates,
          [action.query]: 'loading',
        },
      }

    // Get invites
    case InviteActions.REQUEST_SUCCESS:
      return {
        ...state,
        invitesStates: {
          ...state.invitesStates,
          [action.query]: 'success',
        },
        invitesResults: {
          ...state.invitesResults,
          [action.query]: action.data,
        },
      }

    case InviteActions.REQUEST_FAILURE:
      return {
        ...state,
        invitesStates: {
          ...state.invitesStates,
          [action.query]: 'failure',
        },
      }

    default:
      return state
  }
}
