import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, AuthUserPrivacy } from '@dashboard/lib/types'

export interface UpdateJobParams {
  name?: string
  company_name?: string
  description?: string | null
  profile_id?: string | null
  privacy?: AuthUserPrivacy
  active?: boolean
}

export const REQUEST = 'JOB_UPDATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'JOB_UPDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'JOB_UPDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateJob(jobId: string, params: UpdateJobParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(jobId))
      Api()
        .put<APIResponse<{ id: string }>>(`/jobs/${jobId}`, params)
        .then(() => {
          dispatch(requestSuccess(jobId))

          resolve()
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(jobId, errorMessage))
          reject(
            `Error calling updateJob (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
