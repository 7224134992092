import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'

import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface CreateGroupParams {
  name: string
  profile_ids: string[]
  leader_id: string
  leader_experience_level: string
  group_age: string
  department_ids: string[]
  collaborator_ids: string[]
}

export const createGroupKey = (params: CreateGroupParams) => {
  return `${params.name}--${params.profile_ids.join('-')}`
}

export const REQUEST = 'GROUPS_CREATE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'GROUPS_CREATE_SUCCESS'
const requestSuccess = (key: string, data: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'GROUPS_CREATE_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function createGroup(params: CreateGroupParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      const key = createGroupKey(params)

      dispatch(request(key))

      Api()
        .post<APIResponse<{ id: string }>>('/groups', params)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data.id))
          resolve(data.data.id)
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling createGroup (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
