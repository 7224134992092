import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'

export interface PublicTeam {
  assessment_logo_url: null | string
  logo_url: string | null
  intro: null | string
  completion_button_text: null | string
  completion_button_url: null | string
  job_survey_button_text: string | null
  job_survey_button_url: string | null
  show_results: boolean
}

export const REQUEST = 'TEAM_PUBLIC_LOAD_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_PUBLIC_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: PublicTeam
}
const requestSuccess = (
  key: string,
  data: PublicTeam,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'TEAM_PUBLIC_LOAD_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadPublicTeamRequest(teamId: string) {
  return PublicApi().get<PublicTeam>(`public/teams/${teamId}`)
}

export function loadPublicTeam(teamId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PublicTeam>((resolve, reject) => {
      dispatch(request(teamId))

      loadPublicTeamRequest(teamId)
        .then(({ data }) => {
          dispatch(requestSuccess(teamId, data))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(teamId, err))
          reject(
            `Error calling loadPublicTeam (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
