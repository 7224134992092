import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { DemographicsAttributes } from './submit'

export const REQUEST = 'DEMOGRAPHIC_UPDATE_START'
type RequestAction = { type: typeof REQUEST }
const requestStart = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'DEMOGRAPHIC_UPDATE_SUCCESS'
type RequestSuccessAction = { type: typeof REQUEST_SUCCESS }
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'DEMOGRAPHIC_UPDATE_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateDemographics(data: DemographicsAttributes, uuid: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(requestStart())

      Api()
        .put(`/demographics/${uuid}`, data)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(error => {
          dispatch(requestFailure(error))
          reject(error.response?.data)
        })
    })
  }
}
