export interface InviteResponse {
  email: string
  created_at: string
}

export const REQUEST = 'GET_INVITES_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}

export const REQUEST_SUCCESS = 'GET_INVITES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: string
  data: InviteResponse[]
}

export const REQUEST_FAILURE = 'GET_INVITES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
