import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'TEAM_CUSTOM_EMAIL_TEST_START'
export interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'TEAM_CUSTOM_EMAIL_TEST_SUCCESS'
export interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'TEAM_CUSTOM_EMAIL_TEST_FAILURE'
export interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function testEmail(teamId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .post(`/teams/${teamId}/mailers/test`)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling testEmail (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
