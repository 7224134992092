export interface CrmIntegrationObject {
  id: string
  name: string
  verified: boolean
  base_url: string
}

export const REQUEST = 'LOAD_CRM_INTEGRATIONS_START'
const request = () =>
  ({
    type: REQUEST,
  }) as const

export const REQUEST_SUCCESS = 'LOAD_CRM_INTEGRATIONS_SUCCESS'
const requestSuccess = (data: CrmIntegrationObject[]) =>
  ({
    type: REQUEST_SUCCESS,
    data,
  }) as const

export const REQUEST_FAILURE = 'LOAD_CRM_INTEGRATIONS_FAILURE'
const requestFailure = (error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>
