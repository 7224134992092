export const REQUEST = 'SUBMIT_NOTE_START'
interface RequestAction {
  type: typeof REQUEST
  section: string
}

export const REQUEST_SUCCESS = 'SUBMIT_NOTE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  section: string
}

export const REQUEST_FAILURE = 'SUBMIT_NOTE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  section: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
