export const REQUEST = 'UPDATE_BIG_FIVE_ASSESSMENT_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'UPDATE_BIG_FIVE_ASSESSMENT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'UPDATE_BIG_FIVE_ASSESSMENT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
