import * as publicAssessmentQuestionsActions from '@dashboard/store/actions/assessments/public/questions'
import * as publicAssessmentCreateActions from '@dashboard/store/actions/assessments/public/create'

import * as assessmentCreateActions from '@dashboard/store/actions/assessments/submit'

import * as enneagramAssessmentCreateActions from '@dashboard/store/actions/assessments/enneagram/create'
import * as enneagramAssessmentUpdateActions from '@dashboard/store/actions/assessments/enneagram/update'
import * as enneagramAssessmentDeleteActions from '@dashboard/store/actions/assessments/enneagram/delete'

import * as strengthsAssessmentCreateActions from '@dashboard/store/actions/assessments/strengths/create'
import * as strengthsAssessmentUpdateActions from '@dashboard/store/actions/assessments/strengths/update'
import * as strengthsAssessmentDeleteActions from '@dashboard/store/actions/assessments/strengths/delete'

import * as myersBriggsAssessmentCreateActions from '@dashboard/store/actions/assessments/myersBriggs/create'
import * as myersBriggsAssessmentUpdateActions from '@dashboard/store/actions/assessments/myersBriggs/update'
import * as myersBriggsAssessmentDeleteActions from '@dashboard/store/actions/assessments/myersBriggs/delete'

import * as valuesAssessmentCreateActions from '@dashboard/store/actions/assessments/values/create'
import * as valuesAssessmentUpdateActions from '@dashboard/store/actions/assessments/values/update'
import * as valuesAssessmentDeleteActions from '@dashboard/store/actions/assessments/values/delete'

import * as careerAlignmentQuestionActions from '@dashboard/store/actions/assessments/career_alignment/questions'
import * as careerAlignmentsSubmitActions from '@dashboard/store/actions/assessments/career_alignment/create'
import * as careerAlignmentsIndexActions from '@dashboard/store/actions/assessments/career_alignment/index'
import * as careerAlignmentsDestroyActions from '@dashboard/store/actions/assessments/career_alignment/destroy'

import * as bigFiveAssessmentCreateActions from '@dashboard/store/actions/assessments/bigFive/create'
import * as bigFiveAssessmentUpdateActions from '@dashboard/store/actions/assessments/bigFive/update'
import * as bigFiveAssessmentDeleteActions from '@dashboard/store/actions/assessments/bigFive/delete'

import * as selfAssessmentQuestionsActions from '@dashboard/store/actions/assessments/selfAssessment/questions'
import * as submitSAssessmentAnswerActions from '@dashboard/store/actions/assessments/selfAssessment/create'

import * as submitPeerAssessmentActions from '@dashboard/store/actions/assessments/peer/submit'
import * as getPeerAssessmentActions from '@dashboard/store/actions/assessments/peer/get'
import * as deleteAssessmentActions from '@dashboard/store/actions/assessments/peer/destroy'

import { PeerAssessmentIndexResponse } from '@dashboard/store/actions/assessments/peer/get'

import * as getSkillAssessmentsActions from '@dashboard/store/actions/assessments/skillAssessments'
import * as getSkillAssessmentQuestionsActions from '@dashboard/store/actions/assessments/skillAssessments/questions'
import * as createSkillAssessmentActions from '@dashboard/store/actions/assessments/skillAssessments/create'
import * as updateSkillAssessmentActions from '@dashboard/store/actions/assessments/skillAssessments/update'

import {
  LoadingStates,
  AssessmentQuestion,
  Personality,
  KeyedStateHelper,
} from '@dashboard/lib/types'

export interface State {
  createState: LoadingStates
  deleteAssessmentStates: Record<string, LoadingStates | undefined>
  personality: Personality | null
  questions: Array<AssessmentQuestion>
  questionsState: LoadingStates
  enneagramState: LoadingStates
  enneagramDeleteState: LoadingStates
  strengthsState: LoadingStates
  strengthsDeleteState: LoadingStates
  myersBriggsState: LoadingStates
  myersBriggsDeleteState: LoadingStates
  valuesState: LoadingStates
  valuesDeleteState: LoadingStates
  careerAlignmentQuestions: careerAlignmentQuestionActions.CareerAlignmentQuestion[]
  careerAlignmentQuestionsState: LoadingStates
  careerAlignmentSubmitState: LoadingStates
  careerAlignments: careerAlignmentsIndexActions.CareerAlignmentResponse | null
  careerAlignmentsState: LoadingStates
  careerAlignmentsDestroyState: LoadingStates
  publicAssessmentSubmitState: LoadingStates
  publicAssessmentQuestionsStates: KeyedStateHelper<LoadingStates>
  publicAssessmentQuestions: KeyedStateHelper<AssessmentQuestion[]>
  publicAssessmentSubmitResults: Personality | null
  assessmentCreateState: LoadingStates
  assessmentSubmitResults: Personality | null
  bigFiveState: LoadingStates
  bigFiveDeleteState: LoadingStates
  selfAssessmentQuestionsState: LoadingStates
  selfAssessmentQuestions: selfAssessmentQuestionsActions.SelfAssessmentQuestion[]
  submitSelfAssessmentAnswerStates: Record<string, LoadingStates | undefined>
  submitPeerAssessmentStates: Record<string, LoadingStates | undefined>
  submitPeerAssessmentResults: Record<string, Personality | undefined>
  getPeerAssessmentStates: Record<string, LoadingStates | undefined>
  getPeerAssessmentResults: Record<
    string,
    PeerAssessmentIndexResponse | undefined
  >
  getSkillAssessmentsStates: KeyedStateHelper<LoadingStates>
  getSkillAssessmentsResults: KeyedStateHelper<
    getSkillAssessmentsActions.SkillAssessmentResponse[]
  >
  getSkillAssessmentQuestionsStates: KeyedStateHelper<LoadingStates>
  getSkillAssessmentQuestionsResults: KeyedStateHelper<
    getSkillAssessmentQuestionsActions.SkillAssessmentQuestionResponse[]
  >
  createSkillAssessmentStates: KeyedStateHelper<LoadingStates>
  updateSkillAssessmentStates: KeyedStateHelper<LoadingStates>
}

const INITIAL_STATE: State = {
  createState: 'default',
  deleteAssessmentStates: {},
  personality: null,
  questions: [],
  questionsState: 'default',
  enneagramState: 'default',
  enneagramDeleteState: 'default',
  strengthsState: 'default',
  strengthsDeleteState: 'default',
  myersBriggsState: 'default',
  myersBriggsDeleteState: 'default',
  valuesState: 'default',
  valuesDeleteState: 'default',
  careerAlignmentQuestions: [],
  careerAlignmentQuestionsState: 'default',
  careerAlignmentSubmitState: 'default',
  careerAlignments: null,
  careerAlignmentsState: 'default',
  careerAlignmentsDestroyState: 'default',
  publicAssessmentSubmitState: 'default',
  publicAssessmentQuestionsStates: {},
  publicAssessmentQuestions: {},
  publicAssessmentSubmitResults: null,
  assessmentCreateState: 'default',
  assessmentSubmitResults: null,
  bigFiveState: 'default',
  bigFiveDeleteState: 'default',
  selfAssessmentQuestionsState: 'default',
  selfAssessmentQuestions: [],
  submitSelfAssessmentAnswerStates: {},
  submitPeerAssessmentStates: {},
  submitPeerAssessmentResults: {},
  getPeerAssessmentStates: {},
  getPeerAssessmentResults: {},
  getSkillAssessmentsStates: {},
  getSkillAssessmentsResults: {},
  getSkillAssessmentQuestionsStates: {},
  getSkillAssessmentQuestionsResults: {},
  createSkillAssessmentStates: {},
  updateSkillAssessmentStates: {},
}

type Action =
  | deleteAssessmentActions.Action
  | publicAssessmentQuestionsActions.Action
  | publicAssessmentCreateActions.Action
  | enneagramAssessmentCreateActions.Action
  | enneagramAssessmentUpdateActions.Action
  | enneagramAssessmentDeleteActions.Action
  | strengthsAssessmentCreateActions.Action
  | strengthsAssessmentUpdateActions.Action
  | strengthsAssessmentDeleteActions.Action
  | myersBriggsAssessmentCreateActions.Action
  | myersBriggsAssessmentUpdateActions.Action
  | myersBriggsAssessmentDeleteActions.Action
  | valuesAssessmentCreateActions.Action
  | valuesAssessmentUpdateActions.Action
  | valuesAssessmentDeleteActions.Action
  | careerAlignmentQuestionActions.Action
  | careerAlignmentsSubmitActions.Action
  | careerAlignmentsIndexActions.Action
  | careerAlignmentsDestroyActions.Action
  | assessmentCreateActions.Action
  | bigFiveAssessmentCreateActions.Action
  | bigFiveAssessmentUpdateActions.Action
  | bigFiveAssessmentDeleteActions.Action
  | selfAssessmentQuestionsActions.Action
  | submitSAssessmentAnswerActions.Action
  | submitPeerAssessmentActions.Action
  | getPeerAssessmentActions.Action
  | getSkillAssessmentsActions.Action
  | getSkillAssessmentQuestionsActions.Action
  | createSkillAssessmentActions.Action
  | updateSkillAssessmentActions.Action

export default function assessmentsReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Get Public Assessment Questions
    case publicAssessmentQuestionsActions.REQUEST:
      return {
        ...state,
        publicAssessmentQuestionsStates: {
          ...state.publicAssessmentQuestionsStates,
          [action.key]: 'loading',
        },
      }
    case publicAssessmentQuestionsActions.REQUEST_FAILURE:
      return {
        ...state,
        publicAssessmentQuestionsStates: {
          ...state.publicAssessmentQuestionsStates,
          [action.key]: 'failure',
        },
      }
    case publicAssessmentQuestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        publicAssessmentQuestionsStates: {
          ...state.publicAssessmentQuestionsStates,
          [action.key]: 'success',
        },
        publicAssessmentQuestions: {
          ...state.publicAssessmentQuestions,
          [action.key]: action.questions,
        },
      }

    // Submit Assessment
    case assessmentCreateActions.REQUEST:
      return { ...state, assessmentCreateState: 'loading' }
    case assessmentCreateActions.REQUEST_FAILURE:
      return { ...state, assessmentCreateState: 'failure' }
    case assessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        assessmentCreateState: 'success',
        publicAssessmentSubmitState: 'success',
        assessmentSubmitResults: action.personality,
      }

    // Submit Public Assessment
    case publicAssessmentCreateActions.REQUEST:
      return { ...state, publicAssessmentSubmitState: 'loading' }
    case publicAssessmentCreateActions.REQUEST_FAILURE:
      return { ...state, publicAssessmentSubmitState: 'failure' }
    case publicAssessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        publicAssessmentSubmitState: 'success',
        publicAssessmentSubmitResults: action.personality,
      }

    case publicAssessmentQuestionsActions.REQUEST:
      return { ...state, questionsState: 'loading' }

    case publicAssessmentQuestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        questionsState: 'success',
        questions: action.questions,
      }

    case publicAssessmentQuestionsActions.REQUEST_FAILURE:
      return { ...state, questionsState: 'failure' }

    // Delete Peer Assessment
    case deleteAssessmentActions.REQUEST:
      return {
        ...state,
        deleteAssessmentStates: {
          ...state.deleteAssessmentStates,
          [action.key]: 'loading',
        },
      }
    case deleteAssessmentActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteAssessmentStates: {
          ...state.deleteAssessmentStates,
          [action.key]: 'success',
        },
      }
    case deleteAssessmentActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteAssessmentStates: {
          ...state.deleteAssessmentStates,
          [action.key]: 'failure',
        },
      }

    // Post Enneagram Assessment
    case enneagramAssessmentCreateActions.REQUEST:
      return {
        ...state,
        enneagramState: 'loading',
      }
    case enneagramAssessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        enneagramState: 'success',
      }
    case enneagramAssessmentCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        enneagramState: 'failure',
      }

    // Update Enneagram Assessment
    case enneagramAssessmentUpdateActions.REQUEST:
      return {
        ...state,
        enneagramState: 'loading',
      }
    case enneagramAssessmentUpdateActions.REQUEST_SUCCESS:
      return {
        ...state,
        enneagramState: 'success',
      }
    case enneagramAssessmentUpdateActions.REQUEST_FAILURE:
      return {
        ...state,
        enneagramState: 'failure',
      }

    // Delete Enneagram Assessment
    case enneagramAssessmentDeleteActions.REQUEST:
      return {
        ...state,
        enneagramDeleteState: 'loading',
      }
    case enneagramAssessmentDeleteActions.REQUEST_SUCCESS:
      return {
        ...state,
        enneagramDeleteState: 'success',
      }
    case enneagramAssessmentDeleteActions.REQUEST_FAILURE:
      return {
        ...state,
        enneagramDeleteState: 'failure',
      }

    // Post Strengths Assessment
    case strengthsAssessmentCreateActions.REQUEST:
      return {
        ...state,
        strengthsState: 'loading',
      }
    case strengthsAssessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        strengthsState: 'success',
      }
    case strengthsAssessmentCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        strengthsState: 'failure',
      }

    // Update Strengths Assessment
    case strengthsAssessmentUpdateActions.REQUEST:
      return {
        ...state,
        strengthsState: 'loading',
      }
    case strengthsAssessmentUpdateActions.REQUEST_SUCCESS:
      return {
        ...state,
        strengthsState: 'success',
      }
    case strengthsAssessmentUpdateActions.REQUEST_FAILURE:
      return {
        ...state,
        strengthsState: 'failure',
      }

    // Delete Strengths Assessment
    case strengthsAssessmentDeleteActions.REQUEST:
      return {
        ...state,
        strengthsDeleteState: 'loading',
      }
    case strengthsAssessmentDeleteActions.REQUEST_SUCCESS:
      return {
        ...state,
        strengthsDeleteState: 'success',
      }
    case strengthsAssessmentDeleteActions.REQUEST_FAILURE:
      return {
        ...state,
        strengthsDeleteState: 'failure',
      }

    // Post Myers-Briggs Assessment
    case myersBriggsAssessmentCreateActions.REQUEST:
      return {
        ...state,
        myersBriggsState: 'loading',
      }
    case myersBriggsAssessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        myersBriggsState: 'success',
      }
    case myersBriggsAssessmentCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        myersBriggsState: 'failure',
      }

    // Update Myers-Briggs Assessment
    case myersBriggsAssessmentUpdateActions.REQUEST:
      return {
        ...state,
        myersBriggsState: 'loading',
      }
    case myersBriggsAssessmentUpdateActions.REQUEST_SUCCESS:
      return {
        ...state,
        myersBriggsState: 'success',
      }
    case myersBriggsAssessmentUpdateActions.REQUEST_FAILURE:
      return {
        ...state,
        myersBriggsState: 'failure',
      }

    // Delete Myers-Briggs Assessment
    case myersBriggsAssessmentDeleteActions.REQUEST:
      return {
        ...state,
        myersBriggsDeleteState: 'loading',
      }
    case myersBriggsAssessmentDeleteActions.REQUEST_SUCCESS:
      return {
        ...state,
        myersBriggsDeleteState: 'success',
      }
    case myersBriggsAssessmentDeleteActions.REQUEST_FAILURE:
      return {
        ...state,
        myersBriggsDeleteState: 'failure',
      }

    // Post Values Assessment
    case valuesAssessmentCreateActions.REQUEST:
      return {
        ...state,
        valuesState: 'loading',
      }
    case valuesAssessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        valuesState: 'success',
      }
    case valuesAssessmentCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        valuesState: 'failure',
      }

    // Update Values Assessment
    case valuesAssessmentUpdateActions.REQUEST:
      return {
        ...state,
        valuesState: 'loading',
      }
    case valuesAssessmentUpdateActions.REQUEST_SUCCESS:
      return {
        ...state,
        valuesState: 'success',
      }
    case valuesAssessmentUpdateActions.REQUEST_FAILURE:
      return {
        ...state,
        valuesState: 'failure',
      }

    // Delete Values Assessment
    case valuesAssessmentDeleteActions.REQUEST:
      return {
        ...state,
        valuesDeleteState: 'loading',
      }
    case valuesAssessmentDeleteActions.REQUEST_SUCCESS:
      return {
        ...state,
        valuesDeleteState: 'success',
      }
    case valuesAssessmentDeleteActions.REQUEST_FAILURE:
      return {
        ...state,
        valuesDeleteState: 'failure',
      }

    // Career Alignment Questions
    case careerAlignmentQuestionActions.REQUEST:
      return {
        ...state,
        careerAlignmentQuestionsState: 'loading',
      }
    case careerAlignmentQuestionActions.REQUEST_SUCCESS:
      return {
        ...state,
        careerAlignmentQuestionsState: 'success',
        careerAlignmentQuestions: action.careerAlignmentQuestions,
      }
    case careerAlignmentQuestionActions.REQUEST_FAILURE:
      return {
        ...state,
        careerAlignmentQuestionsState: 'failure',
      }

    // Career Alignment Submit Assessment
    case careerAlignmentsSubmitActions.REQUEST:
      return {
        ...state,
        careerAlignmentSubmitState: 'loading',
      }
    case careerAlignmentsSubmitActions.REQUEST_SUCCESS:
      return {
        ...state,
        careerAlignmentSubmitState: 'success',
      }
    case careerAlignmentsSubmitActions.REQUEST_FAILURE:
      return {
        ...state,
        careerAlignmentSubmitState: 'failure',
      }

    // Career Alignments Index
    case careerAlignmentsIndexActions.REQUEST:
      return {
        ...state,
        careerAlignmentsState: 'loading',
      }
    case careerAlignmentsIndexActions.REQUEST_SUCCESS:
      return {
        ...state,
        careerAlignmentsState: 'success',
        careerAlignments: action.alignments,
      }
    case careerAlignmentsIndexActions.REQUEST_FAILURE:
      return {
        ...state,
        careerAlignmentsState: 'failure',
      }

    // Career Alignments Destroy
    case careerAlignmentsDestroyActions.REQUEST:
      return {
        ...state,
        careerAlignmentsDestroyState: 'loading',
      }
    case careerAlignmentsDestroyActions.REQUEST_SUCCESS:
      return {
        ...state,
        careerAlignmentsDestroyState: 'success',
      }
    case careerAlignmentsDestroyActions.REQUEST_FAILURE:
      return {
        ...state,
        careerAlignmentsDestroyState: 'failure',
      }

    // Post Big Five Assessment
    case bigFiveAssessmentCreateActions.REQUEST:
      return {
        ...state,
        bigFiveState: 'loading',
      }
    case bigFiveAssessmentCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        bigFiveState: 'success',
      }
    case bigFiveAssessmentCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        bigFiveState: 'failure',
      }

    // Update Big Five Assessment
    case bigFiveAssessmentUpdateActions.REQUEST:
      return {
        ...state,
        bigFiveState: 'loading',
      }
    case bigFiveAssessmentUpdateActions.REQUEST_SUCCESS:
      return {
        ...state,
        bigFiveState: 'success',
      }
    case bigFiveAssessmentUpdateActions.REQUEST_FAILURE:
      return {
        ...state,
        bigFiveState: 'failure',
      }

    // Delete Big Five Assessment
    case bigFiveAssessmentDeleteActions.REQUEST:
      return {
        ...state,
        bigFiveDeleteState: 'loading',
      }
    case bigFiveAssessmentDeleteActions.REQUEST_SUCCESS:
      return {
        ...state,
        bigFiveDeleteState: 'success',
      }
    case bigFiveAssessmentDeleteActions.REQUEST_FAILURE:
      return {
        ...state,
        bigFiveDeleteState: 'failure',
      }

    // Self Assessment Questions
    case selfAssessmentQuestionsActions.REQUEST:
      return {
        ...state,
        selfAssessmentQuestionsState: 'loading',
      }
    case selfAssessmentQuestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        selfAssessmentQuestionsState: 'success',
        selfAssessmentQuestions: action.data,
      }
    case selfAssessmentQuestionsActions.REQUEST_FAILURE:
      return {
        ...state,
        selfAssessmentQuestionsState: 'failure',
      }

    // Submit Self Assessment Answer
    case submitSAssessmentAnswerActions.REQUEST:
      return {
        ...state,
        submitSelfAssessmentAnswerStates: {
          ...state.submitSelfAssessmentAnswerStates,
          [action.key]: 'loading',
        },
      }
    case submitSAssessmentAnswerActions.REQUEST_SUCCESS:
      return {
        ...state,
        submitSelfAssessmentAnswerStates: {
          ...state.submitSelfAssessmentAnswerStates,
          [action.key]: 'success',
        },
      }
    case submitSAssessmentAnswerActions.REQUEST_FAILURE:
      return {
        ...state,
        submitSelfAssessmentAnswerStates: {
          ...state.submitSelfAssessmentAnswerStates,
          [action.key]: 'failure',
        },
      }

    // Submit Peer Assessment
    case submitPeerAssessmentActions.REQUEST:
      return {
        ...state,
        submitPeerAssessmentStates: {
          ...state.submitPeerAssessmentStates,
          [action.id]: 'loading',
        },
      }
    case submitPeerAssessmentActions.REQUEST_SUCCESS:
      return {
        ...state,
        submitPeerAssessmentStates: {
          ...state.submitPeerAssessmentStates,
          [action.id]: 'success',
        },
        submitPeerAssessmentResults: {
          ...state.submitPeerAssessmentResults,
          [action.id]: action.data,
        },
      }
    case submitPeerAssessmentActions.REQUEST_FAILURE:
      return {
        ...state,
        submitPeerAssessmentStates: {
          ...state.submitPeerAssessmentStates,
          [action.id]: 'failure',
        },
      }

    // Get Peer Assessment
    case getPeerAssessmentActions.REQUEST:
      return {
        ...state,
        getPeerAssessmentStates: {
          ...state.getPeerAssessmentStates,
          [action.id]: 'loading',
        },
      }
    case getPeerAssessmentActions.REQUEST_SUCCESS:
      return {
        ...state,
        getPeerAssessmentStates: {
          ...state.getPeerAssessmentStates,
          [action.id]: 'success',
        },
        getPeerAssessmentResults: {
          ...state.getPeerAssessmentResults,
          [action.id]: action.data,
        },
      }
    case getPeerAssessmentActions.REQUEST_FAILURE:
      return {
        ...state,
        getPeerAssessmentStates: {
          ...state.getPeerAssessmentStates,
          [action.id]: 'failure',
        },
      }

    // Get Skill Assessments
    case getSkillAssessmentsActions.REQUEST:
      return {
        ...state,
        getSkillAssessmentsStates: {
          ...state.getSkillAssessmentsStates,
          [action.key]: 'loading',
        },
      }
    case getSkillAssessmentsActions.REQUEST_SUCCESS:
      return {
        ...state,
        getSkillAssessmentsStates: {
          ...state.getSkillAssessmentsStates,
          [action.key]: 'success',
        },
        getSkillAssessmentsResults: {
          ...state.getSkillAssessmentsResults,
          [action.key]: action.data,
        },
      }
    case getSkillAssessmentsActions.REQUEST_FAILURE:
      return {
        ...state,
        getSkillAssessmentsStates: {
          ...state.getSkillAssessmentsStates,
          [action.key]: 'failure',
        },
      }

    // Get Skill Assessment Questions
    case getSkillAssessmentQuestionsActions.REQUEST:
      return {
        ...state,
        getSkillAssessmentQuestionsStates: {
          ...state.getSkillAssessmentQuestionsStates,
          [action.key]: 'loading',
        },
      }
    case getSkillAssessmentQuestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        getSkillAssessmentQuestionsStates: {
          ...state.getSkillAssessmentQuestionsStates,
          [action.key]: 'success',
        },
        getSkillAssessmentQuestionsResults: {
          ...state.getSkillAssessmentQuestionsResults,
          [action.key]: action.data,
        },
      }
    case getSkillAssessmentQuestionsActions.REQUEST_FAILURE:
      return {
        ...state,
        getSkillAssessmentQuestionsStates: {
          ...state.getSkillAssessmentQuestionsStates,
          [action.key]: 'failure',
        },
      }

    // Create Skill Assessment
    case createSkillAssessmentActions.REQUEST:
      return {
        ...state,
        createSkillAssessmentStates: {
          ...state.createSkillAssessmentStates,
          [action.key]: 'loading',
        },
      }
    case createSkillAssessmentActions.REQUEST_SUCCESS:
      return {
        ...state,
        createSkillAssessmentStates: {
          ...state.createSkillAssessmentStates,
          [action.key]: 'success',
        },
      }
    case createSkillAssessmentActions.REQUEST_FAILURE:
      return {
        ...state,
        createSkillAssessmentStates: {
          ...state.createSkillAssessmentStates,
          [action.key]: 'failure',
        },
      }

    // Update Skill Assessment
    case updateSkillAssessmentActions.REQUEST:
      return {
        ...state,
        updateSkillAssessmentStates: {
          ...state.updateSkillAssessmentStates,
          [action.key]: 'loading',
        },
      }
    case updateSkillAssessmentActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateSkillAssessmentStates: {
          ...state.updateSkillAssessmentStates,
          [action.key]: 'success',
        },
      }
    case updateSkillAssessmentActions.REQUEST_FAILURE:
      return {
        ...state,
        updateSkillAssessmentStates: {
          ...state.updateSkillAssessmentStates,
          [action.key]: 'failure',
        },
      }

    default:
      return state
  }
}
