import * as SearchActions from '@dashboard/store/actions/coach/search'

import {
  LoadingStates,
  DataSuggestionResponse,
  KeyedStateHelper,
} from '@dashboard/lib/types'

export type State = {
  searchStates: KeyedStateHelper<LoadingStates>
  searchResults: KeyedStateHelper<DataSuggestionResponse>
}

const INITIAL_STATE: State = {
  searchStates: {},
  searchResults: {},
}

type Action = SearchActions.Action

export default function coachReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Search Coach Data
    case SearchActions.REQUEST:
      return {
        ...state,
        searchStates: {
          ...state.searchStates,
          [action.query]: 'loading',
        },
      }

    case SearchActions.REQUEST_SUCCESS:
      return {
        ...state,
        searchStates: {
          ...state.searchStates,
          [action.query]: 'success',
        },
        searchResults: {
          ...state.searchResults,
          [action.query]: action.data,
        },
      }

    case SearchActions.REQUEST_FAILURE:
      return {
        ...state,
        searchStates: {
          ...state.searchStates,
          [action.query]: 'failure',
        },
      }

    default:
      return state
  }
}
