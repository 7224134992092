import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export const REQUEST = 'JOB_DUPLICATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'JOB_DUPLICATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: string
}
const requestSuccess = (key: string, data: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'JOB_DUPLICATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function duplicateJob(jobId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(request(jobId))

      Api()
        .post<APIResponse<{ id: string }>>(`/jobs/${jobId}/duplicate`)
        .then(({ data }) => {
          dispatch(requestSuccess(jobId, data.data.id))
          resolve(data.data.id)
        })
        .catch(error => {
          dispatch(requestFailure(jobId, error))
          reject(error.response?.data)
        })
    })
  }
}
