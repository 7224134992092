export const REQUEST = 'REMOVE_USER_START'
interface RequestAction {
  type: typeof REQUEST
  profileId: string
}

export const REQUEST_SUCCESS = 'REMOVE_USER_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profileId: string
}

export const REQUEST_FAILURE = 'REMOVE_USER_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  profileId: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
