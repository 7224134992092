export const REQUEST = 'INVITE_SELF_START'
interface RequestAction {
  type: typeof REQUEST
  email: string
}

export const REQUEST_SUCCESS = 'INVITE_SELF_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  email: string
}

export const REQUEST_FAILURE = 'INVITE_SELF_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  email: string
}

export const REQUEST_EXISTS = 'INVITE_SELF_EXISTS'
interface RequestExistsAction {
  type: typeof REQUEST_EXISTS
  error: unknown
  email: string
}

export type Action =
  | RequestAction
  | RequestSuccessAction
  | RequestFailureAction
  | RequestExistsAction
