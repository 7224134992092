import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { FullAuthUser, APIResponse, SegmentTypes } from '@dashboard/lib/types'

export interface UpdateSettingsParams {
  current_segment?: SegmentTypes
  profile_personalization?: boolean
  profile_personalization_teammate?: boolean
  profile_personalization_job_candidate?: boolean
  profile_personalization_predicted?: boolean
  account_activity_notification?: boolean
}

export const REQUEST = 'UPDATE_USER_SETTINGS_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  data: FullAuthUser
}
const requestSuccess = (data: FullAuthUser): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateUserSettings(params: UpdateSettingsParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<FullAuthUser>((resolve, reject) => {
      dispatch(request())

      Api()
        .put<APIResponse<{ refreshed_token: string; user: FullAuthUser }>>(
          '/settings',
          params,
        )
        .then(({ data }) => {
          dispatch(requestSuccess(data.data.user))
          resolve(data.data.user)
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling updateSettings (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
