import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { Team } from '@dashboard/lib/types'

export const REQUEST = 'TEAM_LOAD_START'
interface RequestAction {
  type: typeof REQUEST
  id: string
}
const request = (id: string): RequestAction => ({
  type: REQUEST,
  id,
})

export const REQUEST_SUCCESS = 'TEAM_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  id: string
  team: Team
}
const requestSuccess = (id: string, team: Team): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  id,
  team,
})

export const REQUEST_FAILURE = 'TEAM_LOAD_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  id: string
  error: unknown
}
const requestFailure = (id: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  id,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadTeam(id: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request(id))

      Api()
        .get(`/teams/${id}`)
        .then(({ data }) => {
          dispatch(requestSuccess(id, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(id, err))
          reject(
            `Error calling loadTeam (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
