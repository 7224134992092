import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'DELETE_CREDIT_START'
type RequestAction = { type: typeof REQUEST; creditType: string }
const request = (creditType: string): RequestAction => ({
  type: REQUEST,
  creditType,
})

export const REQUEST_SUCCESS = 'DELETE_CREDIT_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  creditType: string
}
const requestSuccess = (creditType: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  creditType,
})

export const REQUEST_FAILURE = 'DELETE_CREDIT_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
  creditType: string
}
const requestFailure = (
  error: unknown,
  creditType: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  creditType,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function spendCredit(type?: string, identifier?: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const defaultType = type || 'none'

      dispatch(request(defaultType))

      Api()
        .delete('/credits', { params: { type, identifier } })
        .then(() => {
          dispatch(requestSuccess(defaultType))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err, defaultType))
          reject(
            `Error calling spendCredit (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
