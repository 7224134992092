import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'GRANT_ACCESS_LEVEL_START'
interface RequestAction {
  type: typeof REQUEST
  userID: string
}
const request = (userID: string): RequestAction => ({
  type: REQUEST,
  userID,
})

export const REQUEST_SUCCESS = 'GRANT_ACCESS_LEVEL_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  userID: string
}
const requestSuccess = (userID: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  userID,
})

export const REQUEST_FAILURE = 'GRANT_ACCESS_LEVEL_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  userID: string
}
const requestFailure = (
  error: unknown,
  userID: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  userID,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function grantAccessLevel(
  team_id: string,
  id: string,
  resource_type: string,
  role: string,
  resource_id: string,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(id))

      Api()
        .post(`/teams/${team_id}/roles`, {
          role,
          resource_type,
          resource_id,
          id,
        })
        .then(() => {
          dispatch(requestSuccess(id))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err, id))
          reject(
            `Error calling grantAccessLevel (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
