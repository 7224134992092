import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  Team,
  APIResponse,
  TeamLayoutSettings,
  APIErrorResponse,
} from '@dashboard/lib/types'

export interface UpdateTeamParams {
  name?: string
  billing_notification?: boolean
  billing_emails?: string
  privacy?: string | null
  assessment_logo_url?: null
  show_celebrities?: boolean
  enable_global_profile_enrichment?: boolean
  job_myers_briggs?: boolean
  job_enneagram?: boolean
  job_strengths?: boolean
  job_life_values?: boolean
  job_big_five?: boolean
  profile_myers_briggs?: boolean
  profile_enneagram?: boolean
  profile_strengths?: boolean
  profile_life_values?: boolean
  profile_big_five?: boolean
  profile_layout_settings?: TeamLayoutSettings
  use_product_branding?: boolean
  use_email_branding?: boolean
  logo?: File | null
}

export const REQUEST = 'TEAM_UPDATE_START'
interface RequestAction {
  type: typeof REQUEST
  id: string
}
const request = (id: string): RequestAction => ({
  type: REQUEST,
  id,
})

export const REQUEST_SUCCESS = 'TEAM_UPDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  id: string
  team: Team
}
const requestSuccess = (id: string, team: Team): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  id,
  team,
})

export const REQUEST_FAILURE = 'TEAM_UPDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  id: string
  error: APIErrorResponse | null
}

const requestFailure = (
  id: string,
  error: APIErrorResponse | null,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  id,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateTeam(id: string, params: UpdateTeamParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request(id))

      const oldParams = params
      let payloadWithLogo: FormData
      let payload: FormData | UpdateTeamParams
      if (params.logo) {
        payloadWithLogo = new FormData()
        payloadWithLogo.append('logo', params.logo)
        delete oldParams.logo
        Object.entries(oldParams).forEach(([key, value]) => {
          payloadWithLogo.append(key, value)
        })
        payload = payloadWithLogo
      } else {
        payload = params
      }
      Api()
        .put<APIResponse<Team>>(`/teams/${id}`, payload)
        .then(({ data }) => {
          dispatch(requestSuccess(id, data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(id, err))
          reject(
            `Error calling updateTeam (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
