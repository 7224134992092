import type { SalesforceApiResponse } from '@dashboard/lib/types'
export type { SalesforceApiResponse } from '@dashboard/lib/types'

export const REQUEST = 'CONNECT_SALESFORCE_API_START'
const request = () =>
  ({
    type: REQUEST,
  }) as const

export const REQUEST_SUCCESS = 'CONNECT_SALESFORCE_API_SUCCESS'
const requestSuccess = (data: SalesforceApiResponse) =>
  ({
    type: REQUEST_SUCCESS,
    data,
  }) as const

export const REQUEST_FAILURE = 'CONNECT_SALESFORCE_API_FAILURE'
const requestFailure = (error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>
