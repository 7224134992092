export const REQUEST = 'PROFILE_VIEWED_START'
type requestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'PROFILE_VIEWED_SUCCESS'
type requestSuccessAction = {
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'PROFILE_VIEWED_FAILURE'
type requestFailureAction = { type: typeof REQUEST_FAILURE }

export type Action = requestAction | requestSuccessAction | requestFailureAction
