import { PublicApi } from '@dashboard/lib/api'
import { DiscType, APIResponse } from '@dashboard/lib/types'

export interface JobTitlePersonalityResponse {
  disc_type: DiscType
  archetype: string
  degrees: string
  intensity: string
  job_titles: string[]
}

export const REQUEST = 'GET_JOB_TITLE_PERSONALITY_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  jobTitle: string
}

export const REQUEST_SUCCESS = 'GET_JOB_TITLE_PERSONALITY_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  jobTitle: string
  data: JobTitlePersonalityResponse
}

export const REQUEST_FAILURE = 'GET_JOB_TITLE_PERSONALITY_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  jobTitle: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadJobTitlePersonalityRequest(job_title: string) {
  return PublicApi().post<APIResponse<JobTitlePersonalityResponse>>(
    'public/job_titles',
    {
      job_title,
    },
  )
}
