import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'GROUPS_DESTROY_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'GROUPS_DESTROY_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'GROUPS_DESTROY_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function destroyGroup(groupId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(groupId))

      Api()
        .delete(`/groups/${groupId}`)
        .then(() => {
          dispatch(requestSuccess(groupId))
          resolve()
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(groupId, errorMessage))
          reject(
            `Error calling destroyGroup (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
