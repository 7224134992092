import { Team } from '@dashboard/lib/types'

export const REQUEST = 'TEAMS_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'TEAMS_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  team: Team
}

export const REQUEST_FAILURE = 'TEAMS_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
