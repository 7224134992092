import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIPagedResponse, TeamUnit } from '@dashboard/lib/types'
import md5 from 'md5'

export interface LoadTeamUnitsParams {
  page: number
  query?: string
  per_page?: number
  sort?: 'name'
  direction?: 'asc' | 'desc'
}

export function teamUnitsKey(teamId: string, params: LoadTeamUnitsParams) {
  return md5(
    `${teamId}-${params?.query}-${params?.page}-${params?.per_page}-${params.sort}-${params.direction}`,
  )
}

export const REQUEST = 'TEAM_UNIT_INDEX_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'TEAM_UNIT_INDEX_SUCCESS'
const requestSuccess = (key: string, data: APIPagedResponse<TeamUnit>) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'TEAM_UNIT_INDEX_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function loadTeamUnits(teamId: string, params: LoadTeamUnitsParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<TeamUnit>>((resolve, reject) => {
      const key = teamUnitsKey(teamId, params)
      dispatch(request(key))

      Api()
        .get<APIPagedResponse<TeamUnit>>(`/teams/${teamId}/team_units`, {
          params,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))

          reject(
            `Error calling loadTeamUnits (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
