import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api, AuthedHeaders } from '@dashboard/lib/api'

import { APIResponse, ProfileContent } from '@dashboard/lib/types'

export type PlaybookGroupDataResponse = Record<string, ProfileContent>

export function playbookGroupKey(playbookId: string) {
  return `${playbookId}`
}

export const REQUEST = 'PLAYBOOK_DISC_GROUPS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_DISC_GROUPS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: PlaybookGroupDataResponse
}
const requestSuccess = (
  key: string,
  data: PlaybookGroupDataResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_DISC_GROUPS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function fetchPlaybookGroupsData(
  playbookId: string,
  headers?: AuthedHeaders,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PlaybookGroupDataResponse>((resolve, reject) => {
      const key = playbookGroupKey(playbookId)
      dispatch(request(key))

      Api(headers)
        .post<APIResponse<PlaybookGroupDataResponse>>(
          `/playbooks/${playbookId}/group`,
          {},
        )
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling fetchPlaybookGroupsData (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
