export const REQUEST = 'TEAM_UPLOAD_ASSESSMENT_LOGO_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'TEAM_UPLOAD_ASSESSMENT_LOGO_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'TEAM_UPLOAD_ASSESSMENT_LOGO_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
