import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIResponse, PlaybookResponse } from '@dashboard/lib/types'

export const REQUEST = 'PLAYBOOKS_SAMPLE_START'
interface RequestAction {
  type: typeof REQUEST
  templateId: string
}
const request = (templateId: string): RequestAction => ({
  type: REQUEST,
  templateId,
})

export const REQUEST_SUCCESS = 'PLAYBOOKS_SAMPLE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  templateId: string
  data: PlaybookResponse
}
const requestSuccess = (
  templateId: string,
  data: PlaybookResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  templateId,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOKS_SAMPLE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  templateId: string
  error: unknown
}
const requestFailure = (
  templateId: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  templateId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadSample(templateId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PlaybookResponse>((resolve, reject) => {
      dispatch(request(templateId))

      const params = {
        template_id: templateId,
      }

      Api()
        .get<APIResponse<PlaybookResponse>>('/playbooks/sample', {
          params,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(templateId, data.data))

          resolve(data.data)
        })
        .catch(error => {
          dispatch(requestFailure(templateId, error))

          reject(error.response?.data)
        })
    })
  }
}
