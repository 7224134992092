import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export type UserCriteriaResponse = Record<string, any>

export const REQUEST = 'USER_CRITERIA_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'USER_CRITERIA_SUCCESS'
const requestSuccess = (key: string, data: Record<string, any>) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'USER_CRITERIA_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function getUserCriteria(type: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<UserCriteriaResponse>((resolve, reject) => {
      dispatch(request(type))

      Api()
        .post<APIResponse<UserCriteriaResponse>>('/criteria', { type })
        .then(({ data }) => {
          dispatch(requestSuccess(type, data.data))
          resolve(data.data)
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(type, err))
          reject(
            `Error calling getUserCriteria (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
