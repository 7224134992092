export const REQUEST = 'USER_EMAILS_CREATE_START'
type RequestAction = { type: typeof REQUEST; email: string }

export const REQUEST_SUCCESS = 'USER_EMAILS_CREATE_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  email: string
}

export const REQUEST_FAILURE = 'USER_EMAILS_CREATE_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  email: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
