import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { AxiosError } from 'axios'

export interface UpdateJobSurveyAnswerParams {
  jobId: string
  surveyId: string
  answerId: number
  score: number
}

export function updateJobSurveyAnswerKey(params: UpdateJobSurveyAnswerParams) {
  const { jobId, surveyId, answerId } = params

  return `${jobId}-${surveyId}-${answerId}`
}

export const REQUEST = 'SURVEY_UPDATE_ANSWER_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'SURVEY_UPDATE_ANSWER_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'SURVEY_UPDATE_ANSWER_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function updateJobSurveyAnswer(params: UpdateJobSurveyAnswerParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = updateJobSurveyAnswerKey(params)
      dispatch(request(key))

      Api()
        .put(`/jobs/${params.jobId}/surveys/${params.surveyId}/answer`, {
          score: params.score,
          answer_id: params.answerId,
        })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch((err: AxiosError<{ message?: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling updateJobSurveyAnswer (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
