export const LOADING = 'NOTIFY_LOADING'
interface LoadingAction {
  type: typeof LOADING
}

export const SUCCESS = 'NOTIFY_SUCCESS'
interface SuccessAction {
  type: typeof SUCCESS
  text: React.ReactNode
  showCredits: boolean
}

export const CLEAR = 'NOTIFY_CLEAR'
interface ClearAction {
  type: typeof CLEAR
}

export type Action = LoadingAction | SuccessAction | ClearAction
