import { CrystalChecklistMode } from '@dashboard/lib/types'

const CHECKLIST_OPTIONS = [
  'install_chrome_extension',
  'predict_personality',
  'assessment_invite',
  'create_job',
  'create_playbook',
  'create_interview_playbook',
  'create_individual_playbook',
  'create_group_playbook',
  'read_sales_best_practices',
  'read_hiring_best_practices',
  'read_coaching_best_practices',
  'send_invites',
] as const

export type ChecklistOptions = Record<
  (typeof CHECKLIST_OPTIONS)[number],
  boolean | undefined
>

export type ChecklistResponse = {
  current: CrystalChecklistMode | null
  data: ChecklistOptions
}

export const REQUEST = 'GET_CHECKLIST_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'GET_CHECKLIST_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  data: ChecklistResponse
}

export const REQUEST_FAILURE = 'GET_CHECKLIST_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
