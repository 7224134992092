export const REQUEST = 'PEER_ASSESSMENT_DELETE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'PEER_ASSESSMENT_DELETE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}

export const REQUEST_FAILURE = 'PEER_ASSESSMENT_DELETE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
