import { PublicApi } from '@dashboard/lib/api'
import { ProfileContent, APIResponse } from '@dashboard/lib/types'

export const REQUEST = 'RETRIEVE_PUBLIC_PROFILE_CONTENT_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'RETRIEVE_PUBLIC_PROFILE_REQUEST_CONTENT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: ProfileContent
}

export const REQUEST_FAILURE = 'RETRIEVE_PUBLIC_PROFILE_REQUEST_CONTENT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getContentRequest(profileId: string) {
  return PublicApi().get<APIResponse<ProfileContent>>(
    `public/profiles/${profileId}/data`,
  )
}
