import * as EnrichedProfilesActions from '@dashboard/store/actions/enrichedProfiles'
import { KeyedStateHelper, LoadingStates } from '@dashboard/lib/types'
import { EnrichedProfiles } from '@dashboard/store/actions/enrichedProfiles'

export type State = {
  enrichedProfilesStates: KeyedStateHelper<LoadingStates | undefined>
  enrichedProfilesResults: Record<string, EnrichedProfiles>
}

const INITIAL_STATE: State = {
  enrichedProfilesResults: {},
  enrichedProfilesStates: {},
}

type Action = EnrichedProfilesActions.Action

export default function enrichedProfilesReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case EnrichedProfilesActions.REQUEST:
      return {
        ...state,
        enrichedProfilesStates: {
          ...state.enrichedProfilesStates,
          [action.query]: 'loading',
        },
      }

    case EnrichedProfilesActions.REQUEST_SUCCESS:
      return {
        ...state,
        enrichedProfilesStates: {
          ...state.enrichedProfilesStates,
          [action.query]: 'success',
        },
        enrichedProfilesResults: {
          ...state.enrichedProfilesResults,
          [action.query]: action.data,
        },
      }

    case EnrichedProfilesActions.REQUEST_FAILURE:
      return {
        ...state,
        enrichedProfilesStates: {
          ...state.enrichedProfilesStates,
          [action.query]: 'failure',
        },
      }

    default:
      return state
  }
}
