import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  APIPagedResponse,
  CandidateProfile,
  PagedOptions,
} from '@dashboard/lib/types'

export type PersonalityOptions = 'exclude' | 'only'

export interface JobProfileOptions extends PagedOptions {
  query?: string
  personality_toggle?: PersonalityOptions
  sort?: 'name' | 'disc_type' | 'added_to_job' | 'job_fit'
  direction?: 'asc' | 'desc'
  job_fit?: number
}

export function jobProfilesQuery(
  jobId: string,
  {
    page,
    per_page,
    query,
    personality_toggle,
    sort,
    direction,
  }: JobProfileOptions,
) {
  return `${jobId}-${page}-${per_page}-${query}-${personality_toggle}-${sort}-${direction}`
}

export const REQUEST = 'LOAD_JOB_PROFILES_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'LOAD_JOB_PROFILES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<CandidateProfile>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<CandidateProfile>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'LOAD_JOB_PROFILES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadJobProfiles(jobId: string, params: JobProfileOptions = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<CandidateProfile>>(
      (resolve, reject) => {
        const key = jobProfilesQuery(jobId, params)
        dispatch(request(key))

        Api()
          .get<APIPagedResponse<CandidateProfile>>(`/jobs/${jobId}/profiles`, {
            params,
          })
          .then(({ data }) => {
            dispatch(requestSuccess(key, data))

            resolve(data)
          })
          .catch(error => {
            dispatch(requestFailure(key, error))

            reject(error.response?.data)
          })
      },
    )
  }
}
