import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export interface ValuesAnswer {
  value: number
  question: string
  strength: string
}

export const REQUEST = 'CREATE_VALUES_ASSESSMENT_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'CREATE_VALUES_ASSESSMENT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'CREATE_VALUES_ASSESSMENT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const create = (
  values: Array<string>,
  responses: Record<string, ValuesAnswer>,
) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())
      Api()
        .post('life_values', { data: values, responses })
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling assessments.values.create (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
