import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse } from '@dashboard/lib/types'

export interface FindPlaybookParams {
  template_id: string
  profile_ids: string[]
  job_ids: string[]
}

export const findPlaybookQueryKey = (params: FindPlaybookParams) => {
  const { template_id, profile_ids, job_ids } = params

  return [template_id, ...profile_ids, ...job_ids].join('-')
}

export const REQUEST = 'PLAYBOOK_FIND_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_FIND_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  uuid: string
}
const requestSuccess = (key: string, uuid: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  uuid,
})

export const REQUEST_FAILURE = 'PLAYBOOK_FIND_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function findPlaybook(params: FindPlaybookParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      const key = findPlaybookQueryKey(params)
      dispatch(request(key))

      Api()
        .post<APIResponse<{ id: string }>>('/playbooks/find', params)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data.id))
          resolve(data.data.id)
        })
        .catch(err => {
          dispatch(requestFailure(key))
          reject(
            `Error calling findPlaybook (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
