export const REQUEST = 'FULL_PAGE_SCREENSHOT_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'FULL_PAGE_SCREENSHOT_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: string
}

export const REQUEST_FAILURE = 'FULL_PAGE_SCREENSHOT_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
