import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  PlaybookTemplateObject,
  APIPagedResponse,
  PlaybookTemplateType,
} from '@dashboard/lib/types'
import md5 from 'md5'

export interface HiringTemplateOptions {
  type?: PlaybookTemplateType
  page?: number
  per_page?: number
  query?: string
}

export function hiringTemplatesQueryKey({
  type,
  per_page,
  page,
  query,
}: HiringTemplateOptions = {}) {
  return md5(`${type}-${per_page}-${page}-${query}`)
}

export const REQUEST = 'HIRING_PLAYBOOK_TEMPLATES_LOAD_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'HIRING_PLAYBOOK_TEMPLATES_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<PlaybookTemplateObject>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<PlaybookTemplateObject>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'HIRING_PLAYBOOK_TEMPLATES_LOAD_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getHiringTemplates(params: HiringTemplateOptions = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<PlaybookTemplateObject>>(
      (resolve, reject) => {
        const key = hiringTemplatesQueryKey(params)
        dispatch(request(key))

        Api()
          .get<APIPagedResponse<PlaybookTemplateObject>>(
            '/playbook_templates/hiring',
            {
              params,
            },
          )
          .then(({ data }) => {
            dispatch(requestSuccess(key, data))
            resolve(data)
          })
          .catch(err => {
            dispatch(requestFailure(key, err))
            reject(
              `Error calling getHiringTemplates (Status: ${err.response
                ?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      },
    )
  }
}
