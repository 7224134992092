import { Personality, DiscType } from '@dashboard/lib/types'

export interface AnalyzeAttributesResponse {
  personality: Personality
  archetype: string
  disc_type: DiscType
  degrees: number
  intensity: number
  derived: { attributes: boolean; external: boolean; baseline: boolean }
  metadata: unknown
}

export const REQUEST = 'ANALYZE_ATTRIBUTES_START'
interface RequestAction {
  type: typeof REQUEST
  email: string
}

export const REQUEST_SUCCESS = 'ANALYZE_ATTRIBUTES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  email: string
  content: AnalyzeAttributesResponse
}

export const REQUEST_FAILURE = 'ANALYZE_ATTRIBUTES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  email: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
