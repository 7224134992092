import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export function teamUnitDestroyKey(teamId: string, unitId: string) {
  return `${teamId}-${unitId}`
}

export const REQUEST = 'TEAM_UNIT_DESTROY_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_UNIT_DESTROY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'TEAM_UNIT_DESTROY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function destroyTeamUnit(teamId: string, unitId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = teamUnitDestroyKey(teamId, unitId)
      dispatch(request(key))

      Api()
        .delete(`/teams/${teamId}/team_units/${unitId}`)
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling destroyTeamUnit (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
