export const REQUEST = 'PROFILE_PURCHASE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'PROFILE_PURCHASE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}

export const REQUEST_FAILURE = 'PROFILE_PURCHASE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  errorMessage: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
