import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export interface SendCandidateEmailParams {
  jobId: string
  emails: string[]
}

export const REQUEST = 'SEND_CANDIDATE_INVITE_EMAIL_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'SEND_CANDIDATE_INVITE_EMAIL_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'SEND_CANDIDATE_INVITE_EMAIL_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function sendCandidateEmail(sendParams: SendCandidateEmailParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { jobId, emails } = sendParams

      const key = jobId

      dispatch(request(key))

      const params = {
        emails,
      }

      Api()
        .post(`/jobs/${jobId}/profiles/invite`, params)
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling sendCandidateEmail (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
