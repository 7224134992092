interface SkillAssessmentOption {
  datum_id: string
  text: string
  correct: boolean
  response: string
  degrees: number
  intensity: number
}

export interface SkillAssessmentQuestionResponse {
  id: number
  text: string
  options: SkillAssessmentOption[]
}

export const REQUEST = 'GET_SKILL_ASSESSMENT_QUESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'GET_SKILL_ASSESSMENT_QUESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: SkillAssessmentQuestionResponse[]
}

export const REQUEST_FAILURE = 'GET_SKILL_ASSESSMENT_QUESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
