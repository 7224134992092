import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'PREVIEW_PRODUCT_SUBSCRIPTION_START'
type RequestAction = { type: typeof REQUEST; key: string }
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PREVIEW_PRODUCT_SUBSCRIPTION_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
  data: PreviewResponse
}
const requestSuccess = (
  key: string,
  data: PreviewResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PREVIEW_PRODUCT_SUBSCRIPTION_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  key: string
  error: { response: { data: { message: string } } }
}
const requestFailure = (
  key: string,
  error: {
    response: { data: { message: string } }
  },
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function previewProductQuery(
  productId: string,
  planId: string,
  quantity: number,
) {
  return [productId, planId, quantity].join('-')
}

export interface PreviewResponse {
  total: number
  upgrade: boolean
}

export function previewProduct(
  productId: string,
  planId: string,
  quantity: number,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PreviewResponse>((resolve, reject) => {
      const params = {
        product_id: productId,
        plan_id: planId,
        quantity: quantity,
      }
      const key = previewProductQuery(productId, planId, quantity)

      dispatch(request(key))

      Api()
        .get('/invoices/preview', { params })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling previewProduct (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
