import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface CompanySuggestResponse {
  name: string | null
  domains: string[]
}

export const REQUEST = 'SUGGEST_COMPANY_START'
interface RequestAction {
  type: typeof REQUEST
  domain: string
}
const request = (domain: string): RequestAction => ({
  type: REQUEST,
  domain,
})

export const REQUEST_SUCCESS = 'SUGGEST_COMPANY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  domain: string
  data: CompanySuggestResponse
}
const requestSuccess = (
  domain: string,
  data: CompanySuggestResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  domain,
  data,
})

export const REQUEST_FAILURE = 'SUGGEST_COMPANY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  domain: string
}
const requestFailure = (
  error: unknown,
  domain: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  domain,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const suggestCompany = (domain: string) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request(domain))

      PublicApi()
        .get<APIResponse<CompanySuggestResponse>>(
          `companies/suggest?domain=${domain}`,
        )
        .then(({ data }) => {
          dispatch(requestSuccess(domain, data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(err, domain))
          reject(
            `Error calling suggestCompany (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
