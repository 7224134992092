import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse } from '@dashboard/lib/types'

export const REQUEST = 'SURVEY_DESTROY_START'
interface RequestAction {
  type: typeof REQUEST
  jobId: string
}
const request = (jobId: string): RequestAction => ({
  type: REQUEST,
  jobId,
})

export const REQUEST_SUCCESS = 'SURVEY_DESTROY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  jobId: string
}
const requestSuccess = (jobId: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  jobId,
})

export const REQUEST_FAILURE = 'SURVEY_DESTROY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  jobId: string
  error: string
}
const requestFailure = (
  jobId: string,
  error: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  jobId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function destroySurvey(jobId: string, surveyId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(jobId))
      Api()
        .delete<APIResponse<{ id: string }>>(
          `/jobs/${jobId}/surveys/${surveyId}`,
        )
        .then(() => {
          dispatch(requestSuccess(jobId))
          resolve()
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(jobId, errorMessage))
          reject(
            `Error calling destroySurvey (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
