import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  APIPagedResponse,
  SurveyProfile,
  PagedOptions,
} from '@dashboard/lib/types'

export interface JobSurveyOptions extends PagedOptions {
  query?: string
}

export function jobSurveysQuery(
  jobId: string,
  { page, per_page, query }: JobSurveyOptions = {},
) {
  return `${jobId}-${page}-${per_page}-${query}`
}

export const REQUEST = 'LOAD_JOB_SURVEYS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'LOAD_JOB_SURVEYS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<SurveyProfile>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<SurveyProfile>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'LOAD_JOB_SURVEYS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadJobSurveys(jobId: string, params: JobSurveyOptions = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<SurveyProfile>>((resolve, reject) => {
      const key = jobSurveysQuery(jobId, params)
      dispatch(request(key))

      Api()
        .get<APIPagedResponse<SurveyProfile>>(`/jobs/${jobId}/surveys`, {
          params,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))

          resolve(data)
        })
        .catch(error => {
          dispatch(requestFailure(key, error))

          reject(error.response?.data)
        })
    })
  }
}
