import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import * as ls from 'local-storage'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { localAuthToken, sessionToken } from '../lib/user'
import {
  CRYSTAL_AUTH_TOKEN,
  CRYSTAL_SESSION_TOKEN,
} from '@dashboard/lib/constants'
import { deleteCookie } from '@dashboard/lib/browser'
import { AuthedHeaders } from '@dashboard/lib/api'
import { FullStoryAPI } from 'react-fullstory'
import { AuthDetails } from '@dashboard/lib/types'
import { enrichmentApiHost } from '@crystal-eyes/config'

const logout = async () => {
  // get the authentication token from local storage if it exists
  const [authToken, session] = await Promise.all([
    localAuthToken(),
    sessionToken(),
  ])

  if (!authToken || !session) {
    // They're already logged out - let's not log them out again
    return
  }

  ls.clear()
  deleteCookie(CRYSTAL_AUTH_TOKEN)
  deleteCookie(CRYSTAL_SESSION_TOKEN)

  if (
    typeof window !== 'undefined' &&
    !window.location?.href?.includes('/app/login')
  )
    window.location.assign('/app/login')
}

const httpLink = createHttpLink({
  uri: `${enrichmentApiHost}/api`,
})

const getAuthLink = (authedHeaders: AuthedHeaders | AuthDetails | undefined) =>
  setContext(async (_, { headers }) => {
    let authToken
    let sessionToken
    if (authedHeaders) {
      authToken = authedHeaders.authToken
      sessionToken = authedHeaders.sessionToken
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
        Session: sessionToken || '',
      },
    }
  })

class GraphQLQueryError extends Error {
  constructor(message: string, stack: string | undefined) {
    super(message) // (1)
    this.name = 'GraphQLQueryError' // (2)
    this.stack = stack
  }
}

const reportErrorLink = new ApolloLink((operation, forward) => {
  const newrelic: any =
    typeof (window as any).newrelic !== 'undefined'
      ? (window as any).newrelic
      : null

  const { operationName, variables } = operation

  const stack = Error().stack

  return forward(operation).map(response => {
    if (response.errors) {
      console.warn(
        `GraphQL Errors received for ${operationName}`,
        response.errors,
      )

      try {
        FullStoryAPI('event', 'GraphQLQueryError', {
          operationName,
          errors: JSON.stringify(response.errors),
        })
      } catch (e) {
      }

      newrelic?.noticeError(
        new GraphQLQueryError(
          `Error executing query '${operationName}': ${JSON.stringify(
            response.errors,
          )}`,
          stack,
        ),
        { operationName, variables: JSON.stringify(variables) },
      )
    }

    return response
  })
})

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(err => {
      if (err.message?.includes('Invalid auth token')) {
        console.warn('\'Invalid auth token\' received from GraphQL - logging out')
        logout()
      }
    })
  }
})

export function getApolloClient(
  authedHeaders: AuthedHeaders | AuthDetails | undefined,
) {
  const authLink = getAuthLink(authedHeaders)

  return new ApolloClient({
    ssrMode: true,
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            personality: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              },
            },
          },
        },
        PersonalityDatum: {
          fields: {
            endorsements: {
              merge(__existing, incoming) {
                return incoming
              },
            },
          },
        },
      },
    }),
    link: logoutLink.concat(reportErrorLink).concat(authLink).concat(httpLink),
    name: `crystalknows-web-${process.env.APP_ENV || process.env.NODE_ENV}`,
    version: process.env.REACT_APP_RELEASE_VERSION || process.env.CIRCLE_SHA1,
  })
}
