import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIResponse, Personality } from '@dashboard/lib/types'
import { AxiosError } from 'axios'

export function unownedCandidateQuery(params: CreateCandidateParams) {
  return JSON.stringify(params)
}

export const REQUEST = 'JOB_UNOWNED_CANDIDATES_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'JOB_UNOWNED_CANDIDATES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: string
  key: string
}
const requestSuccess = (key: string, data: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'JOB_UNOWNED_CANDIDATES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface CreateCandidateParams {
  jobId: string
  email?: string
  firstName: string
  lastName: string
  personality?: Personality
}

export function createCandidate(params: CreateCandidateParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIResponse<{ id: string }>>((resolve, reject) => {
      const { jobId, email, firstName, lastName, personality } = params
      const key = unownedCandidateQuery(params)

      dispatch(request(key))

      Api()
        .post<APIResponse<{ id: string }>>(`/jobs/${jobId}/profiles/shell`, {
          email,
          first_name: firstName,
          last_name: lastName,
          personality,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data.id))
          resolve(data)
        })
        .catch((err: AxiosError<{ message: string; status: number }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling createCandidate (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
