import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export interface CreateCustomEmailParams {
  teamId: string
  subject: string
  body: string
  kind: 'team_invite' | 'candidate_invite'
  logo?: File | null
}

export const REQUEST = 'TEAM_CUSTOM_EMAIL_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'TEAM_CUSTOM_EMAIL_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'TEAM_CUSTOM_EMAIL_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createEmail(createParams: CreateCustomEmailParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())
      const { subject, body, teamId, logo, kind } = createParams

      let params
      if (logo) {
        params = new FormData()
        params.append('subject', subject)
        params.append('body', body)
        params.append('logo', logo)
        params.append('kind', kind)
      } else {
        params = {
          subject: subject,
          body: body,
          logo: logo,
          kind: kind,
        }
      }

      Api()
        .post(`/teams/${teamId}/mailers`, params)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling createEmail (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
