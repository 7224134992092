import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'

import { Api } from '@dashboard/lib/api'
import { GroupsResponse } from '.'

export interface UpdateGroupParams {
  name?: string
  profile_ids?: string[]
  leader_id?: string
  leader_experience_level?: string
  group_age?: string
  department_ids?: string[]
  collaborator_ids?: string[]
  privacy?: GroupsResponse['privacy']
}

export const REQUEST = 'GROUPS_UPDATE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'GROUPS_UPDATE_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'GROUPS_UPDATE_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function updateGroup(groupId: string, params: UpdateGroupParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(groupId))

      Api()
        .put(`/groups/${groupId}`, params)
        .then(() => {
          dispatch(requestSuccess(groupId))
          resolve()
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(groupId, errorMessage))
          reject(
            `Error calling updateGroup (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
