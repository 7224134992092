import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse, DiscType } from '@dashboard/lib/types'

export interface DiscAnalysisResponse {
  degrees: number
  disc_type: DiscType
  intensity: number
}

export const queryKey = (keys: Record<number, number>): string => {
  return Object.keys(keys).join('-') || 'empty'
}

export const REQUEST = 'DISC_ANALYSIS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'DISC_ANALYSIS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: DiscAnalysisResponse
}
const requestSuccess = (
  key: string,
  data: DiscAnalysisResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'DISC_ANALYSIS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createAnalysis(keys: Record<number, number>) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      const key = queryKey(keys)

      dispatch(request(key))

      Api()
        .post<APIResponse<DiscAnalysisResponse>>('/analysis/keys', {
          version: 'v2',
          keys,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling createAnalysis (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
