export const REQUEST = 'USER_EMAILS_CONFIRM_START'
type RequestAction = { type: typeof REQUEST; email_token: string }

export const REQUEST_SUCCESS = 'USER_EMAILS_CONFIRM_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  email_token: string
}

export const REQUEST_FAILURE = 'USER_EMAILS_CONFIRM_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  email_token: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
