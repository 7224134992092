export interface CustomPitchDatum {
  id: string
  text: string
  examples: string[]
  answer: string | null
}

export const REQUEST = 'DISC_DATA_PITCHES_GET_QUESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'DISC_DATA_PITCHES_GET_QUESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: CustomPitchDatum[]
}

export const REQUEST_FAILURE = 'DISC_DATA_PITCHES_GET_QUESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
