import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { ChecklistOptions } from '.'

function completeChecklistItemsQueryKey(keys: (keyof ChecklistOptions)[]) {
  return keys.join('-')
}

export const REQUEST = 'COMPLETE_CHECKLIST_ITEM_START'
type RequestAction = { type: typeof REQUEST; key: string }
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'COMPLETE_CHECKLIST_ITEM_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'COMPLETE_CHECKLIST_ITEM_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function completeChecklistItem(keys: (keyof ChecklistOptions)[]) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = completeChecklistItemsQueryKey(keys)

      dispatch(request(key))

      Api()
        .put('/checklists/completed', { keys })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling completeChecklistItem (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
