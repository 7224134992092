import * as ChangePlanActions from '@dashboard/store/actions/company/changePlan'
import * as ChangeRoleActions from '@dashboard/store/actions/company/changeRole'
import * as InviteCoworkerActions from '@dashboard/store/actions/company/inviteCoworker'
import * as InviteSelfActions from '@dashboard/store/actions/company/inviteSelf'
import * as InviteToCompanyActions from '@dashboard/store/actions/company/inviteToCompany'
import * as RemoveActions from '@dashboard/store/actions/company/removeCoworker'
import * as SuggestCompanyActions from '@dashboard/store/actions/company/suggest'

import { LoadingStates } from '@dashboard/lib/types'

export type State = {
  confirmState: LoadingStates
  deleteStates: Record<string, string>
  inviteStates: Record<string, string>
  loadingState: LoadingStates
  planStates: Record<string, string>
  roleStates: Record<string, string>

  suggestStates: Record<string, LoadingStates | undefined>
  suggestResults: Record<
    string,
    SuggestCompanyActions.CompanySuggestResponse | undefined
  >
}

const INITIAL_STATE: State = {
  confirmState: 'default',
  deleteStates: {},
  inviteStates: {},
  loadingState: 'default',
  planStates: {},
  roleStates: {},
  suggestStates: {},
  suggestResults: {},
}

type Action =
  | ChangePlanActions.Action
  | ChangeRoleActions.Action
  | InviteCoworkerActions.Action
  | InviteSelfActions.Action
  | InviteToCompanyActions.Action
  | RemoveActions.Action
  | SuggestCompanyActions.Action

export default function companyReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case InviteCoworkerActions.INVITE_ASSESSMENT_START:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.uuid]: 'loading',
        },
      }

    case InviteCoworkerActions.INVITE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.uuid]: 'success',
        },
      }

    case InviteCoworkerActions.INVITE_ASSESSMENT_EXISTS:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.uuid]: 'exists',
        },
      }

    case InviteCoworkerActions.INVITE_ASSESSMENT_FAILURE:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.uuid]: 'failure',
        },
      }

    case RemoveActions.REQUEST:
      return {
        ...state,
        deleteStates: {
          ...state.deleteStates,
          [action.profileId]: 'loading',
        },
      }

    case RemoveActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteStates: {
          ...state.deleteStates,
          [action.profileId]: 'success',
        },
      }

    case RemoveActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteStates: {
          ...state.deleteStates,
          [action.profileId]: 'failure',
        },
      }

    case ChangeRoleActions.REQUEST:
      return {
        ...state,
        roleStates: {
          ...state.roleStates,
          [action.userUUID]: 'loading',
        },
      }

    case ChangeRoleActions.REQUEST_SUCCESS:
      return {
        ...state,
        roleStates: {
          ...state.roleStates,
          [action.userUUID]: 'success',
        },
      }

    case ChangeRoleActions.REQUEST_FAILURE:
      return {
        ...state,
        roleStates: {
          ...state.roleStates,
          [action.userUUID]: 'failure',
        },
      }

    case ChangePlanActions.REQUEST:
      return {
        ...state,
        planStates: {
          ...state.planStates,
          [action.userUUID]: 'loading',
        },
      }

    case ChangePlanActions.REQUEST_SUCCESS:
      return {
        ...state,
        planStates: {
          ...state.planStates,
          [action.userUUID]: 'success',
        },
      }

    case ChangePlanActions.REQUEST_FAILURE:
      return {
        ...state,
        planStates: {
          ...state.planStates,
          [action.userUUID]: 'failure',
        },
      }

    case InviteSelfActions.REQUEST:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'loading',
        },
      }

    case InviteSelfActions.REQUEST_SUCCESS:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'success',
        },
      }

    case InviteSelfActions.REQUEST_FAILURE:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'failure',
        },
      }

    case InviteSelfActions.REQUEST_EXISTS:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'exists',
        },
      }

    case InviteToCompanyActions.REQUEST:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'loading',
        },
      }

    case InviteToCompanyActions.REQUEST_SUCCESS:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'success',
        },
      }

    case InviteToCompanyActions.REQUEST_FAILURE:
      return {
        ...state,
        inviteStates: {
          ...state.inviteStates,
          [action.email]: 'failure',
        },
      }

    case SuggestCompanyActions.REQUEST:
      return {
        ...state,
        suggestStates: {
          ...state.suggestStates,
          [action.domain]: 'loading',
        },
      }
    case SuggestCompanyActions.REQUEST_SUCCESS:
      return {
        ...state,
        suggestStates: {
          ...state.suggestStates,
          [action.domain]: 'success',
        },
        suggestResults: {
          ...state.suggestResults,
          [action.domain]: action.data,
        },
      }
    case SuggestCompanyActions.REQUEST_FAILURE:
      return {
        ...state,
        suggestStates: {
          ...state.suggestStates,
          [action.domain]: 'failure',
        },
      }
    default:
      return state
  }
}
