import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import {
  APIResponse,
  PlaybookTemplateType,
  PlaybookTemplateProductType,
} from '@dashboard/lib/types'
import { PlaybookModuleTypes } from '@dashboard/lib/playbooks'
import { AxiosError } from 'axios'

export const REQUEST = 'PLAYBOOK_TEMPLATE_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
  name: string
}
const request = (name: string): RequestAction => ({
  type: REQUEST,
  name,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_TEMPLATE_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  name: string
  id: string
}
const requestSuccess = (name: string, id: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  name,
  id,
})

export const REQUEST_FAILURE = 'PLAYBOOK_TEMPLATE_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  name: string
  error: { errorMessage: string; status: number }
}
const requestFailure = (
  name: string,
  error: { errorMessage: string; status: number },
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  name,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface CreateTemplateParams {
  type: PlaybookTemplateType
  sections: PlaybookModuleTypes[]
  name: string
  playbook_template_tags?: PlaybookTemplateProductType[]
}

export function createTemplate(params: CreateTemplateParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(request(params.name))

      Api()
        .post<APIResponse<{ id: string }>>('/playbook_templates', params)
        .then(({ data }) => {
          dispatch(requestSuccess(params.name, data.data.id))
          resolve(data.data.id)
        })
        .catch((err: AxiosError<{ message: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          const status = err.response?.status || 0
          dispatch(
            requestFailure(params.name, {
              errorMessage,
              status,
            }),
          )
          reject(
            `Error calling createTemplate (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
