import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse, DataSuggestionResponse } from '@dashboard/lib/types'

export const REQUEST = 'COACH_SUGGESTIONS_SEARCH_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'COACH_SUGGESTIONS_SEARCH_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: string
  data: DataSuggestionResponse
}
const requestSuccess = (
  query: string,
  data: DataSuggestionResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
  data,
})

export const REQUEST_FAILURE = 'COACH_SUGGESTIONS_SEARCH_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: string
  error: unknown
}
const requestFailure = (
  query: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function searchCoachData(query: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<DataSuggestionResponse>((resolve, reject) => {
      dispatch(request(query))

      Api()
        .post<APIResponse<DataSuggestionResponse>>('/coach/suggestions', {
          text: query,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(query, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(query, err))
          reject(
            `Error calling searchCoachData (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
