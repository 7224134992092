import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { FullAuthUser, APIResponse, USER_ACTIONS } from '@dashboard/lib/types'

export type UserActionKeys = keyof typeof USER_ACTIONS

export const REQUEST = 'USER_ACTION_CREATE_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'USER_ACTION_CREATE_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => {
  return {
    type: REQUEST_SUCCESS,
  }
}

export const REQUEST_FAILURE = 'USER_ACTION_CREATE_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createUserAction(property: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())
      Api()
        .post<APIResponse<{ user: FullAuthUser }>>('/actions', { property })
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling createUserAction (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
