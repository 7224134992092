export const INVITE_ASSESSMENT_START = 'COMPANY_INVITE_ASSESSMENT_START'
interface InviteAssessmentStartAction {
  type: 'COMPANY_INVITE_ASSESSMENT_START'
  uuid: string
}

export const INVITE_ASSESSMENT_SUCCESS = 'COMPANY_INVITE_ASSESSMENT_SUCCESS'
interface InviteAssessmentSuccessAction {
  type: 'COMPANY_INVITE_ASSESSMENT_SUCCESS'
  uuid: string
}

export const INVITE_ASSESSMENT_EXISTS = 'COMPANY_INVITE_ASSESSMENT_EXISTS'
interface InviteAssessmentExistsAction {
  type: 'COMPANY_INVITE_ASSESSMENT_EXISTS'
  uuid: string
}

export const INVITE_ASSESSMENT_FAILURE = 'COMPANY_INVITE_ASSESSMENT_FAILURE'
interface InviteAssessmentFailureAction {
  type: 'COMPANY_INVITE_ASSESSMENT_FAILURE'
  uuid: string
  error: unknown
}

export type Action =
  | InviteAssessmentStartAction
  | InviteAssessmentSuccessAction
  | InviteAssessmentExistsAction
  | InviteAssessmentFailureAction
