import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api, AuthedHeaders } from '@dashboard/lib/api'

import { APIResponse, DiscType } from '@dashboard/lib/types'

export interface DiscArchetype {
  archetype: string
  disc_type: DiscType
  overview: string
  strengths: string[]
}

export const REQUEST = 'PLAYBOOK_ARCHETYPES_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_ARCHETYPES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: Partial<Record<DiscType, DiscArchetype>>
}
const requestSuccess = (
  key: string,
  data: Partial<Record<DiscType, DiscArchetype>>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_ARCHETYPES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function fetchArchetypes(playbookId: string, headers?: AuthedHeaders) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<Partial<Record<DiscType, DiscArchetype>>>(
      (resolve, reject) => {
        dispatch(request(playbookId))

        Api(headers)
          .get<APIResponse<Partial<Record<DiscType, DiscArchetype>>>>(
            `/playbooks/${playbookId}/archetypes`,
          )
          .then(({ data }) => {
            dispatch(requestSuccess(playbookId, data.data))

            resolve(data.data)
          })
          .catch(err => {
            dispatch(requestFailure(playbookId, err))
            reject(
              `Error calling fetchArchetypes (Status: ${err.response
                ?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      },
    )
  }
}
