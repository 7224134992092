export const REQUEST = 'PROFILE_ENDORSE_OVERVIEW_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'PROFILE_ENDORSE_OVERVIEW_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}

export const REQUEST_FAILURE = 'PROFILE_ENDORSE_OVERVIEW_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
