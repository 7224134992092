import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { PublicApi } from '@dashboard/lib/api'
import { Datum } from '@dashboard/lib/types'

export interface CurrentIdealResponse {
  personality: { degrees: number; intensity: number }
  qualities: Datum[]
  energizers: Datum[]
}

export interface JobCompatibilityResponse {
  current: CurrentIdealResponse
  ideal: CurrentIdealResponse
  fit_score: number
  fit_score_explanation: string
  overview: string
}

export const REQUEST = 'PUBLIC_JOB_COMPATIBILITY_REQUEST'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PUBLIC_JOB_COMPATIBILITY_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: JobCompatibilityResponse
}
const requestSuccess = (
  data: JobCompatibilityResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'PUBLIC_JOB_COMPATIBILITY_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const getJobCompatibility = (
  text: string,
  degrees: number,
  intensity: number,
) => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  ): Promise<JobCompatibilityResponse> => {
    return new Promise<JobCompatibilityResponse>(resolve => {
      dispatch(request())

      const params = {
        text: text,
        degrees: degrees,
        intensity: intensity,
      }

      PublicApi()
        .post<JobCompatibilityResponse>('public/job_fit', params)
        .then(({ data }) => {
          dispatch(requestSuccess(data))
          resolve(data)
        })
        .catch(() => dispatch(requestFailure()))
    })
  }
}
