import * as indexActions from '@dashboard/store/actions/groups'
import * as departmentActions from '@dashboard/store/actions/groups/departments'
import * as createGroupActions from '@dashboard/store/actions/groups/create'
import * as updateGroupActions from '@dashboard/store/actions/groups/update'
import * as showGroupActions from '@dashboard/store/actions/groups/show'
import * as groupDiscDataActions from '@dashboard/store/actions/groups/discData'
import * as groupMatrixActions from '@dashboard/store/actions/groups/discData/matrix'
import * as groupCommunicationAdviceActions from '@dashboard/store/actions/groups/discData/communicationAdvice'
import * as destroyActions from '@dashboard/store/actions/groups/destroy'

import {
  LoadingStates,
  APIPagedResponse,
  KeyedStateHelper,
  ProfileContent,
} from '@dashboard/lib/types'

export interface State {
  getStates: KeyedStateHelper<LoadingStates>
  getResults: KeyedStateHelper<APIPagedResponse<indexActions.GroupsResponse>>
  departmentsState: LoadingStates
  departments: departmentActions.GroupDepartment[]
  createStates: KeyedStateHelper<LoadingStates>
  createResults: KeyedStateHelper<string>
  createErrors: KeyedStateHelper<string>
  updateStates: KeyedStateHelper<LoadingStates>
  showStates: KeyedStateHelper<LoadingStates>
  showResults: KeyedStateHelper<indexActions.GroupsResponse>
  discDataStates: KeyedStateHelper<LoadingStates>
  discDataResults: KeyedStateHelper<ProfileContent>
  matrixStates: KeyedStateHelper<LoadingStates>
  matrixResults: KeyedStateHelper<groupMatrixActions.MatrixResponse>
  adviceStates: KeyedStateHelper<LoadingStates>
  adviceResults: KeyedStateHelper<groupCommunicationAdviceActions.GroupCommunicationAdviceResponse>
  destroyStates: KeyedStateHelper<LoadingStates>
}

const INITIAL_STATE: State = {
  getStates: {},
  getResults: {},
  departmentsState: 'default',
  departments: [],
  createStates: {},
  createResults: {},
  createErrors: {},
  updateStates: {},
  showStates: {},
  showResults: {},
  discDataStates: {},
  discDataResults: {},
  matrixStates: {},
  matrixResults: {},
  adviceStates: {},
  adviceResults: {},
  destroyStates: {},
}

type Action =
  | indexActions.Action
  | departmentActions.Action
  | createGroupActions.Action
  | updateGroupActions.Action
  | showGroupActions.Action
  | groupDiscDataActions.Action
  | groupMatrixActions.Action
  | groupCommunicationAdviceActions.Action
  | destroyActions.Action

export default function groupsReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Index Actions
    case indexActions.REQUEST:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.key]: 'loading',
        },
      }
    case indexActions.REQUEST_SUCCESS:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.key]: 'success',
        },
        getResults: {
          ...state.getResults,
          [action.key]: action.data,
        },
      }
    case indexActions.REQUEST_FAILURE:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.key]: 'failure',
        },
      }

    // Department Actions
    case departmentActions.REQUEST:
      return {
        ...state,
        departmentsState: 'loading',
      }
    case departmentActions.REQUEST_SUCCESS:
      return {
        ...state,
        departmentsState: 'success',
        departments: action.data,
      }
    case departmentActions.REQUEST_FAILURE:
      return {
        ...state,
        departmentsState: 'failure',
      }

    // Create Group Actions
    case createGroupActions.REQUEST:
      return {
        ...state,
        createStates: {
          ...state.createStates,
          [action.key]: 'loading',
        },
      }
    case createGroupActions.REQUEST_SUCCESS:
      return {
        ...state,
        createStates: {
          ...state.createStates,
          [action.key]: 'success',
        },
        createResults: {
          ...state.createResults,
          [action.key]: action.data,
        },
        getStates: {},
      }
    case createGroupActions.REQUEST_FAILURE:
      return {
        ...state,
        createStates: {
          ...state.createStates,
          [action.key]: 'failure',
        },
        createErrors: {
          ...state.createErrors,
          [action.key]: action.error,
        },
      }

    // Update Group Actions
    case updateGroupActions.REQUEST:
      return {
        ...state,
        updateStates: {
          ...state.updateStates,
          [action.key]: 'loading',
        },
      }
    case updateGroupActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateStates: {
          ...state.updateStates,
          [action.key]: 'success',
        },
        getStates: {},
      }
    case updateGroupActions.REQUEST_FAILURE:
      return {
        ...state,
        updateStates: {
          ...state.updateStates,
          [action.key]: 'failure',
        },
        createErrors: {
          ...state.createErrors,
          [action.key]: action.error,
        },
      }

    // Show Actions
    case showGroupActions.REQUEST:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.key]: 'loading',
        },
      }
    case showGroupActions.REQUEST_SUCCESS:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.key]: 'success',
        },
        showResults: {
          ...state.showResults,
          [action.key]: action.data,
        },
      }
    case showGroupActions.REQUEST_FAILURE:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.key]: 'failure',
        },
      }

    // Disc Data Actions
    case groupDiscDataActions.REQUEST:
      return {
        ...state,
        discDataStates: {
          ...state.discDataStates,
          [action.key]: 'loading',
        },
      }
    case groupDiscDataActions.REQUEST_SUCCESS:
      return {
        ...state,
        discDataStates: {
          ...state.discDataStates,
          [action.key]: 'success',
        },
        discDataResults: {
          ...state.discDataResults,
          [action.key]: action.data,
        },
      }
    case groupDiscDataActions.REQUEST_FAILURE:
      return {
        ...state,
        discDataStates: {
          ...state.discDataStates,
          [action.key]: 'failure',
        },
      }

    // Matrix Actions
    case groupMatrixActions.REQUEST:
      return {
        ...state,
        matrixStates: {
          ...state.matrixStates,
          [action.key]: 'loading',
        },
      }
    case groupMatrixActions.REQUEST_SUCCESS:
      return {
        ...state,
        matrixStates: {
          ...state.matrixStates,
          [action.key]: 'success',
        },
        matrixResults: {
          ...state.matrixResults,
          [action.key]: action.data,
        },
      }
    case groupMatrixActions.REQUEST_FAILURE:
      return {
        ...state,
        matrixStates: {
          ...state.matrixStates,
          [action.key]: 'failure',
        },
      }

    // Communication Advice Actions
    case groupCommunicationAdviceActions.REQUEST:
      return {
        ...state,
        adviceStates: {
          ...state.adviceStates,
          [action.key]: 'loading',
        },
      }
    case groupCommunicationAdviceActions.REQUEST_SUCCESS:
      return {
        ...state,
        adviceStates: {
          ...state.adviceStates,
          [action.key]: 'success',
        },
        adviceResults: {
          ...state.adviceResults,
          [action.key]: action.data,
        },
      }
    case groupCommunicationAdviceActions.REQUEST_FAILURE:
      return {
        ...state,
        adviceStates: {
          ...state.adviceStates,
          [action.key]: 'failure',
        },
      }

    // Destroy Actions
    case destroyActions.REQUEST:
      return {
        ...state,
        destroyStates: {
          ...state.destroyStates,
          [action.key]: 'loading',
        },
      }
    case destroyActions.REQUEST_SUCCESS:
      return {
        ...state,
        destroyStates: {
          ...state.destroyStates,
          [action.key]: 'success',
        },
        getStates: {},
      }
    case destroyActions.REQUEST_FAILURE:
      return {
        ...state,
        destroyStates: {
          ...state.destroyStates,
          [action.key]: 'failure',
        },
      }

    default:
      return state
  }
}
