import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { ToastMessage } from '@dashboard/lib/types'

type CreateToastParams = Omit<ToastMessage, 'read'>

export const REQUEST = 'CREATE_TOAST_START'
const request = (toast: ToastMessage) =>
  ({
    type: REQUEST,
    toast,
  }) as const

export type Action = ReturnType<typeof request>

export function createToast(params: CreateToastParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>(resolve => {
      const toast: ToastMessage = {
        ...params,
        read: false,
      }

      dispatch(request(toast))
      resolve()
    })
  }
}
