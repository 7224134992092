import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface JobCandidateInfo {
  first_name: string
  enneagram_text_type: string | null
  myers_briggs_text_type: string | null
  big_fives: Record<string, number> | null
  life_value_phrases: Record<string, string>
  strength_phrases: Record<string, string>
  personality: {
    created_at: string
    degrees: number
    disc_type_analysis: unknown
    id: number
    intensity: number
    resource_id: string
    resource_type: string
    updated_at: string
    updated_by_id: string | null
  } | null
}

export const REQUEST = 'PUBLIC_PROFILE_SHOW_JOB_CANDIDATE_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PUBLIC_PROFILE_SHOW_JOB_CANDIDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: JobCandidateInfo
}
const requestSuccess = (
  key: string,
  data: JobCandidateInfo,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PUBLIC_PROFILE_SHOW_JOB_CANDIDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const getJobAssessmentProfile = (
  candidateId: string,
  team_id: string,
) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(candidateId))

      PublicApi()
        .get<APIResponse<JobCandidateInfo>>(
          `public/job_candidates/${candidateId}`,
          {
            params: { team_id },
          },
        )
        .then(({ data }) => {
          dispatch(requestSuccess(candidateId, data.data))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(candidateId))
          reject(
            `Error calling getJobAssessmentProfile (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
