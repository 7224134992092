export const REQUEST = 'GET_QR_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'GET_QR_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  data: string
}

export const REQUEST_FAILURE = 'GET_QR_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
