import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, AuthUserPrivacy } from '@dashboard/lib/types'
import md5 from 'md5'

export interface CreatePlaybookParams {
  template_id: string
  profile_ids: string[] | string
  job_ids?: string[]
  name?: string
  privacy?: AuthUserPrivacy
}

export const createPlaybookQueryKey = (params: CreatePlaybookParams) => {
  const { template_id, profile_ids, name, privacy, job_ids } = params

  const profileIdList: string[] = Array.isArray(profile_ids)
    ? profile_ids
    : [profile_ids]

  return md5(
    [
      template_id,
      ...profileIdList,
      name,
      privacy,
      (job_ids || []).join('-'),
    ].join('--'),
  )
}

export const REQUEST = 'PLAYBOOK_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: string
}
const requestSuccess = (key: string, data: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createPlaybook(params: CreatePlaybookParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      const key = createPlaybookQueryKey(params)
      dispatch(request(key))

      Api()
        .post<APIResponse<{ id: string }>>('/playbooks', params)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data.id))
          resolve(data.data.id)
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling createPlaybook (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
