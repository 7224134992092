import * as publicProfileContentActions from '@dashboard/store/actions/profile/public/content'
import * as analyzeAttributesActions from '@dashboard/store/actions/profile/analyzeAttributes'
import * as assessmentReminderActions from '@dashboard/store/actions/profile/assessmentReminder'
import * as getActions from '@dashboard/store/actions/profile'
import * as photoActions from '@dashboard/store/actions/profile/photo'
import * as profileActions from '@dashboard/store/actions/profile/show'
import * as publicProfileActions from '@dashboard/store/actions/profile/public'
import * as endorseActions from '@dashboard/store/actions/profile/endorse'
import * as deleteEndorsementActions from '@dashboard/store/actions/profile/deleteEndorsement'
import * as deleteUnownedActions from '@dashboard/store/actions/profile/deleteUnowned'
import * as updateUnownedActions from '@dashboard/store/actions/profile/updateUnowned'
import * as createEvaluationActions from '@dashboard/store/actions/profile/evaluations/create'
import * as ConnectApiCustomerActions from '@dashboard/store/actions/profile/connectApiCustomer'
import * as purchaseActions from '@dashboard/store/actions/profile/purchase'
import * as analysisActions from '@dashboard/store/actions/guess/analysis'
import * as loadGuessActions from '@dashboard/store/actions/guess/load'
import * as createGuessActions from '@dashboard/store/actions/guess/create'
import * as discAnalysisActions from '@dashboard/store/actions/discAnalysis'
import * as assessmentAccuracyActions from '@dashboard/store/actions/profile/assessmentAccuracy'
import * as submitNoteActions from '@dashboard/store/actions/profile/notes/submitNote'
import * as updateNoteActions from '@dashboard/store/actions/profile/notes/updateNote'
import * as taskActions from '@dashboard/store/actions/tasks'
import * as getGroupsActions from '@dashboard/store/actions/profile/groups'
import * as getGroupActions from '@dashboard/store/actions/profile/groups/show'
import * as createGroupActions from '@dashboard/store/actions/profile/groups/create'
import * as assignTag from '@dashboard/store/actions/profile/tags/assign'
import * as createTag from '@dashboard/store/actions/profile/tags/create'
import * as getTags from '@dashboard/store/actions/profile/tags/index'
import * as removeTag from '@dashboard/store/actions/profile/tags/remove'
import * as updateTagActions from '@dashboard/store/actions/profile/tags/update'
import * as destroyTagActions from '@dashboard/store/actions/profile/tags/destroy'
import * as exportCsvActions from '@dashboard/store/actions/profile/exportCsv'
import * as createAssessmentProfileActions from '@dashboard/store/actions/profile/public/createAssessment'
import * as createJobAssessmentProfileActions from '@dashboard/store/actions/profile/public/job_candidates/create'
import * as createLegacyJobAssessmentProfileActions from '@dashboard/store/actions/profile/public/job_candidates/create_legacy'
import * as showJobAssessmentProfileActions from '@dashboard/store/actions/profile/public/job_candidates/show'
import * as dataActions from '@dashboard/store/actions/profile/discData/data'
import * as relationshipActions from '@dashboard/store/actions/profile/discData/relationship'
import * as profileViewedActions from '@dashboard/store/actions/profile/viewed'
import * as candidatesActions from '@dashboard/store/actions/profile/candidates'
import * as getProfileEndorsementsActions from '@dashboard/store/actions/profile/endorsements/index'
import * as createProfileEndorsementActions from '@dashboard/store/actions/profile/endorsements/create'
import * as deleteProfileEndorsementActions from '@dashboard/store/actions/profile/endorsements/delete'
import * as candidateRelationshipActions from '@dashboard/store/actions/profile/candidates/relationship'
import * as getEmailSuggestionsActions from '@dashboard/store/actions/profile/email_suggestions'
import * as relationshipDataTagsActions from '@dashboard/store/actions/profile/discData/relationshipDataTags'
import * as profileOverviewActions from '@dashboard/store/actions/profile/discData/overview'
import * as globalApiProfileActions from '@dashboard/store/actions/profile/globalApi'
import * as updateUsageLimitsActions from '@dashboard/store/actions/profile/updateUsageLimits'

import {
  LoadingStates,
  Profile,
  ProfileOverview,
  APIPagedResponse,
  ProfileContent,
  Tag,
  KeyedStateHelper,
} from '@dashboard/lib/types'

export interface State {
  publicProfileContentStates: KeyedStateHelper<LoadingStates>
  publicProfileContentData: Record<string, ProfileContent | undefined>
  analysis: analysisActions.GuessAnalysisResponse | null
  analysisState: LoadingStates
  anaylyzeAttributesResults: Record<
    string,
    analyzeAttributesActions.AnalyzeAttributesResponse | undefined
  >
  anaylyzeAttributesStates: KeyedStateHelper<LoadingStates>
  assessmentReminderStates: Record<string, LoadingStates | 'exists'>
  createEvaluationStates: KeyedStateHelper<LoadingStates>
  createState: LoadingStates | 'exists'
  deleteState: LoadingStates
  deleteUnownedErrorMessages: KeyedStateHelper<string>
  updateUnownedState: LoadingStates
  endorseStates: Record<
    string,
    Record<string, LoadingStates | 'deleted' | undefined> | undefined
  >
  evaluations: Record<string, number>
  feedbackStates: KeyedStateHelper<LoadingStates>
  findOrCreateResult: { uuid: string } | null
  getEvaluationStates: KeyedStateHelper<LoadingStates>
  getResults: Record<string, APIPagedResponse<Profile> | undefined>
  getStates: KeyedStateHelper<LoadingStates>
  loadResults: Record<string, Profile | undefined>
  loadStates: KeyedStateHelper<LoadingStates>
  loadPublicResults: Record<string, Profile | undefined>
  loadPublicStates: KeyedStateHelper<LoadingStates>
  loadErrorCode: Record<string, number | undefined>
  relationshipResults: Record<string, ProfileContent | undefined>
  relationshipStates: KeyedStateHelper<LoadingStates>
  relationshipDataTagsResults: Record<string, ProfileContent | undefined>
  relationshipDataTagsStates: KeyedStateHelper<LoadingStates>
  reloadState: LoadingStates
  discAnalysesStates: KeyedStateHelper<LoadingStates>
  discAnalyses: Record<
    string,
    discAnalysisActions.DiscAnalysisResponse | undefined
  >
  relationshipKindStates: KeyedStateHelper<LoadingStates>
  connectApiCustomerState: LoadingStates
  photoStates: KeyedStateHelper<LoadingStates>
  assessmentAccuracyStates: KeyedStateHelper<LoadingStates>
  purchaseProfileStates: KeyedStateHelper<LoadingStates>
  purchaseErrorMessages: Record<string, string | undefined>
  noteStates: KeyedStateHelper<LoadingStates>
  inviteTasksState: LoadingStates
  groupsStates: KeyedStateHelper<LoadingStates>
  groupsResults: Record<
    string,
    APIPagedResponse<getGroupsActions.ProfileGroup> | undefined
  >
  groupStates: KeyedStateHelper<LoadingStates>
  groupResults: Record<string, getGroupsActions.ProfileGroup | undefined>
  createGroupStates: KeyedStateHelper<LoadingStates>
  dataStates: KeyedStateHelper<LoadingStates>
  data: Record<string, ProfileContent | undefined>
  tagsStates: KeyedStateHelper<LoadingStates>
  tagsResults: Record<string, APIPagedResponse<Tag> | undefined>
  tagCreateStates: LoadingStates
  tagAssignStates: Record<string, LoadingStates>
  tagRemoveStates: Record<string, LoadingStates>
  exportCsvStates: KeyedStateHelper<LoadingStates>
  createAssessmentProfileStates: KeyedStateHelper<LoadingStates>
  createAssessmentProfileResults: Record<string, unknown | undefined>
  createJobCandidateAssessmentProfileStates: Record<
    string,
    LoadingStates | undefined
  >
  createLegacyJobCandidateAssessmentProfileStates: KeyedStateHelper<LoadingStates>
  profileViewedState: LoadingStates
  candidatesStates: KeyedStateHelper<LoadingStates>
  candidatesResults: KeyedStateHelper<
    APIPagedResponse<candidatesActions.CandidatePeopleProfile>
  >
  createProfileEndorsementStates: KeyedStateHelper<LoadingStates>
  getProfileEndorsementsStates: KeyedStateHelper<LoadingStates>
  getProfileEndorsementsResults: KeyedStateHelper<APIPagedResponse<Profile>>
  deleteProfileEndorsementStates: KeyedStateHelper<LoadingStates>
  candidateRelationshipStates: KeyedStateHelper<LoadingStates>
  candidateRelationshipResults: KeyedStateHelper<ProfileContent>
  emailSuggestionsStates: KeyedStateHelper<LoadingStates>
  emailSuggestionsResults: KeyedStateHelper<
    getEmailSuggestionsActions.EmailSuggestionResponse[]
  >
  updateTagStates: KeyedStateHelper<LoadingStates>
  destroyTagStates: KeyedStateHelper<LoadingStates>
  jobCandidateProfileResults: KeyedStateHelper<showJobAssessmentProfileActions.JobCandidateInfo>
  jobCandidateProfileStates: KeyedStateHelper<LoadingStates>
  getProfileOverviewResults: KeyedStateHelper<ProfileOverview>
  getProfileOverviewStates: KeyedStateHelper<LoadingStates>
  createLegacyJobCandidateAssessmentProfileCandidateId: Record<string, string>
  getGlobalApiProfileStates: KeyedStateHelper<LoadingStates>
  getGlobalApiProfileResults: KeyedStateHelper<
    globalApiProfileActions.LinkedInProfile[]
  >
  updateUsageLimitsStates: LoadingStates
}

const INITIAL_STATE: State = {
  publicProfileContentStates: {},
  publicProfileContentData: {},
  analysis: null,
  analysisState: 'default',
  anaylyzeAttributesResults: {},
  anaylyzeAttributesStates: {},
  assessmentReminderStates: {},
  createEvaluationStates: {},
  createState: 'default',
  deleteState: 'default',
  deleteUnownedErrorMessages: {},
  updateUnownedState: 'default',
  endorseStates: {},
  evaluations: {},
  feedbackStates: {},
  findOrCreateResult: null,
  getEvaluationStates: {},
  getResults: {},
  getStates: {},
  loadResults: {},
  loadStates: {},
  loadErrorCode: {},
  loadPublicResults: {},
  loadPublicStates: {},
  relationshipResults: {},
  relationshipStates: {},
  relationshipDataTagsResults: {},
  relationshipDataTagsStates: {},
  reloadState: 'default',
  discAnalysesStates: {},
  discAnalyses: {},
  relationshipKindStates: {},
  connectApiCustomerState: 'default',
  photoStates: {},
  assessmentAccuracyStates: {},
  purchaseProfileStates: {},
  purchaseErrorMessages: {},
  noteStates: {},
  inviteTasksState: 'default',
  groupsStates: {},
  groupsResults: {},
  groupStates: {},
  groupResults: {},
  createGroupStates: {},
  dataStates: {},
  data: {},
  tagsStates: {},
  tagsResults: {},
  tagCreateStates: 'default',
  tagAssignStates: {},
  tagRemoveStates: {},
  exportCsvStates: {},
  createAssessmentProfileStates: {},
  createAssessmentProfileResults: {},
  createJobCandidateAssessmentProfileStates: {},
  profileViewedState: 'default',
  candidatesStates: {},
  candidatesResults: {},
  createProfileEndorsementStates: {},
  getProfileEndorsementsStates: {},
  getProfileEndorsementsResults: {},
  deleteProfileEndorsementStates: {},
  candidateRelationshipStates: {},
  candidateRelationshipResults: {},
  emailSuggestionsStates: {},
  emailSuggestionsResults: {},
  updateTagStates: {},
  destroyTagStates: {},
  jobCandidateProfileResults: {},
  jobCandidateProfileStates: {},
  createLegacyJobCandidateAssessmentProfileStates: {},
  getProfileOverviewStates: {},
  getProfileOverviewResults: {},
  createLegacyJobCandidateAssessmentProfileCandidateId: {},
  getGlobalApiProfileStates: {},
  getGlobalApiProfileResults: {},
  updateUsageLimitsStates: 'default',
}

type Action =
  | analysisActions.Action
  | analysisActions.Action
  | analyzeAttributesActions.Action
  | assessmentAccuracyActions.Action
  | assessmentReminderActions.Action
  | ConnectApiCustomerActions.Action
  | createEvaluationActions.Action
  | createGuessActions.Action
  | deleteEndorsementActions.Action
  | deleteUnownedActions.Action
  | discAnalysisActions.Action
  | endorseActions.Action
  | getActions.Action
  | photoActions.Action
  | loadGuessActions.Action
  | profileActions.Action
  | publicProfileActions.Action
  | publicProfileContentActions.Action
  | purchaseActions.Action
  | relationshipActions.Action
  | relationshipDataTagsActions.Action
  | submitNoteActions.Action
  | updateNoteActions.Action
  | taskActions.Action
  | getGroupsActions.Action
  | getGroupActions.Action
  | createGroupActions.Action
  | dataActions.Action
  | updateUnownedActions.Action
  | assignTag.Action
  | createTag.Action
  | getTags.Action
  | removeTag.Action
  | exportCsvActions.Action
  | createAssessmentProfileActions.Action
  | profileViewedActions.Action
  | createJobAssessmentProfileActions.Action
  | createLegacyJobAssessmentProfileActions.Action
  | showJobAssessmentProfileActions.Action
  | candidatesActions.Action
  | getProfileEndorsementsActions.Action
  | createProfileEndorsementActions.Action
  | deleteProfileEndorsementActions.Action
  | candidateRelationshipActions.Action
  | getEmailSuggestionsActions.Action
  | updateTagActions.Action
  | destroyTagActions.Action
  | profileOverviewActions.Action
  | globalApiProfileActions.Action
  | updateUsageLimitsActions.Action

export default function profileReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Get Public Profile Content
    case publicProfileContentActions.REQUEST:
      return {
        ...state,
        publicProfileContentStates: {
          ...state.publicProfileContentStates,
          [action.key]: 'loading',
        },
      }
    case publicProfileContentActions.REQUEST_SUCCESS:
      return {
        ...state,
        publicProfileContentStates: {
          ...state.publicProfileContentStates,
          [action.key]: 'success',
        },
        publicProfileContentData: {
          ...state.publicProfileContentData,
          [action.key]: action.data,
        },
      }
    case publicProfileContentActions.REQUEST_FAILURE:
      return {
        ...state,
        publicProfileContentStates: {
          ...state.publicProfileContentStates,
          [action.key]: 'failure',
        },
      }

    case profileActions.LOAD_START:
      return {
        ...state,
        loadStates: {
          ...state.loadStates,
          [action.uuid]: 'loading',
        },
      }

    case profileActions.LOAD_SUCCESS:
      return {
        ...state,
        loadStates: {
          ...state.loadStates,
          [action.uuid]: 'success',
        },
        loadResults: {
          ...state.loadResults,
          [action.uuid]: action.profile,
        },
      }

    case profileActions.LOAD_FAILURE:
      return {
        ...state,
        loadStates: {
          ...state.loadStates,
          [action.uuid]: 'failure',
        },
        loadErrorCode: {
          ...state.loadErrorCode,
          [action.uuid]: action.errorCode,
        },
      }

    // Public Profiles
    case publicProfileActions.REQUEST:
      return {
        ...state,
        loadPublicStates: {
          ...state.loadPublicStates,
          [action.uuid]: 'loading',
        },
      }

    case publicProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        loadPublicStates: {
          ...state.loadPublicStates,
          [action.uuid]: 'success',
        },
        loadPublicResults: {
          ...state.loadPublicResults,
          [action.uuid]: action.profile,
        },
      }

    case publicProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        loadPublicStates: {
          ...state.loadPublicStates,
          [action.uuid]: 'failure',
        },
      }

    case createGuessActions.REQUEST:
      return {
        ...state,
        createState: 'loading',
      }

    case createGuessActions.REQUEST_SUCCESS:
      return {
        ...state,
        createState: 'success',
        findOrCreateResult: action.data,
      }

    case createGuessActions.REQUEST_EXISTS:
      return {
        ...state,
        createState: 'exists',
      }

    case createGuessActions.REQUEST_FAILURE:
      return {
        ...state,
        createState: 'failure',
      }

    case discAnalysisActions.REQUEST:
      return {
        ...state,
        discAnalysesStates: {
          ...state.discAnalysesStates,
          [action.key]: 'loading',
        },
      }
    case discAnalysisActions.REQUEST_SUCCESS:
      return {
        ...state,
        discAnalysesStates: {
          ...state.discAnalysesStates,
          [action.key]: 'success',
        },
        discAnalyses: {
          ...state.discAnalyses,
          [action.key]: action.data,
        },
      }
    case discAnalysisActions.REQUEST_FAILURE:
      return {
        ...state,
        discAnalysesStates: {
          ...state.discAnalysesStates,
          [action.key]: 'failure',
        },
      }

    case loadGuessActions.REQUEST:
      return {
        ...state,
        loadStates: {
          ...state.loadStates,
          [action.uuid]: 'loading',
        },
      }

    case loadGuessActions.REQUEST_SUCCESS:
      return {
        ...state,
        loadStates: {
          ...state.loadStates,
          [action.uuid]: 'success',
        },
        loadResults: {
          ...state.loadResults,
          [action.uuid]: action.profile,
        },
      }

    case loadGuessActions.REQUEST_FAILURE:
      return {
        ...state,
        loadStates: {
          ...state.loadStates,
          [action.uuid]: 'failure',
        },
      }

    case getActions.REQUEST:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'loading',
        },
      }

    case getActions.REQUEST_SUCCESS:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'success',
        },
        getResults: {
          ...state.getResults,
          [action.query]: action.profiles,
        },
      }

    case getActions.REQUEST_FAILURE:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'failure',
        },
      }

    case deleteUnownedActions.REQUEST:
      return { ...state, deleteState: 'loading' }

    case deleteUnownedActions.REQUEST_SUCCESS:
      return { ...state, deleteState: 'success' }

    case deleteUnownedActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteState: 'failure',
        deleteUnownedErrorMessages: {
          ...state.deleteUnownedErrorMessages,
          [action.key]: action.error,
        },
      }

    case updateUnownedActions.REQUEST:
      return { ...state, updateUnownedState: 'loading' }

    case updateUnownedActions.REQUEST_SUCCESS:
      return { ...state, updateUnownedState: 'success' }

    case updateUnownedActions.REQUEST_FAILURE:
      return { ...state, updateUnownedState: 'failure' }

    case endorseActions.REQUEST:
      return {
        ...state,
        endorseStates: {
          ...state.endorseStates,
          [action.profileID]: {
            ...state.endorseStates[action.profileID],
            [action.dataID]: 'loading',
          },
        },
      }

    case endorseActions.REQUEST_SUCCESS:
      return {
        ...state,
        endorseStates: {
          ...state.endorseStates,
          [action.profileID]: {
            ...state.endorseStates[action.profileID],
            [action.dataID]: 'success',
          },
        },
      }

    case endorseActions.REQUEST_FAILURE:
      return {
        ...state,
        endorseStates: {
          ...state.endorseStates,
          [action.profileID]: {
            ...state.endorseStates[action.profileID],
            [action.dataID]: 'failure',
          },
        },
      }

    case deleteEndorsementActions.REQUEST:
      return {
        ...state,
        endorseStates: {
          ...state.endorseStates,
          [action.profileID]: {
            ...state.endorseStates[action.profileID],
            [action.dataID]: 'loading',
          },
        },
      }

    case deleteEndorsementActions.REQUEST_SUCCESS:
      return {
        ...state,
        endorseStates: {
          ...state.endorseStates,
          [action.profileID]: {
            ...state.endorseStates[action.profileID],
            [action.dataID]: 'deleted',
          },
        },
      }

    case deleteEndorsementActions.REQUEST_FAILURE:
      return {
        ...state,
        endorseStates: {
          ...state.endorseStates,
          [action.profileID]: {
            ...state.endorseStates[action.profileID],
            [action.dataID]: 'failure',
          },
        },
      }

    case createEvaluationActions.REQUEST:
      return {
        ...state,
        createEvaluationStates: {
          ...state.createEvaluationStates,
          [action.profileID]: 'loading',
        },
      }

    case createEvaluationActions.REQUEST_SUCCESS:
      return {
        ...state,
        createEvaluationStates: {
          ...state.createEvaluationStates,
          [action.profileID]: 'success',
        },
      }

    case createEvaluationActions.REQUEST_FAILURE:
      return {
        ...state,
        createEvaluationStates: {
          ...state.createEvaluationStates,
          [action.profileID]: 'failure',
        },
      }

    case relationshipActions.REQUEST:
      return {
        ...state,
        relationshipStates: {
          ...state.relationshipStates,
          [action.profileID]: 'loading',
        },
      }
    case relationshipActions.REQUEST_SUCCESS:
      return {
        ...state,
        relationshipStates: {
          ...state.relationshipStates,
          [action.profileID]: 'success',
        },
        relationshipResults: {
          ...state.relationshipResults,
          [action.profileID]: action.content,
        },
      }
    case relationshipActions.REQUEST_FAILURE:
      return {
        ...state,
        relationshipStates: {
          ...state.relationshipStates,
          [action.profileID]: 'failure',
        },
      }

    case relationshipDataTagsActions.REQUEST:
      return {
        ...state,
        relationshipDataTagsStates: {
          ...state.relationshipDataTagsStates,
          [action.profileID]: 'loading',
        },
      }
    case relationshipDataTagsActions.REQUEST_SUCCESS:
      return {
        ...state,
        relationshipDataTagsStates: {
          ...state.relationshipDataTagsStates,
          [action.profileID]: 'success',
        },
        relationshipDataTagsResults: {
          ...state.relationshipDataTagsResults,
          [action.profileID]: action.content,
        },
      }
    case relationshipDataTagsActions.REQUEST_FAILURE:
      return {
        ...state,
        relationshipDataTagsStates: {
          ...state.relationshipDataTagsStates,
          [action.profileID]: 'failure',
        },
      }

    case assessmentReminderActions.REQUEST:
      return {
        ...state,
        assessmentReminderStates: {
          ...state.assessmentReminderStates,
          [action.profile_id]: 'loading',
        },
      }
    case assessmentReminderActions.REQUEST_SUCCESS:
      return {
        ...state,
        assessmentReminderStates: {
          ...state.assessmentReminderStates,
          [action.profile_id]: 'success',
        },
      }
    case assessmentReminderActions.REQUEST_FAILURE:
      return {
        ...state,
        assessmentReminderStates: {
          ...state.assessmentReminderStates,
          [action.profile_id]: 'failure',
        },
      }

    case analysisActions.REQUEST:
      return { ...state, analysisState: 'loading', analysis: null }
    case analysisActions.REQUEST_SUCCESS:
      return { ...state, analysisState: 'success', analysis: action.analysis }
    case analysisActions.REQUEST_FAILURE:
      return { ...state, analysisState: 'failure', analysis: null }

    case analyzeAttributesActions.REQUEST:
      return {
        ...state,
        anaylyzeAttributesStates: {
          ...state.anaylyzeAttributesStates,
          [action.email]: 'loading',
        },
      }
    case analyzeAttributesActions.REQUEST_SUCCESS:
      return {
        ...state,
        anaylyzeAttributesStates: {
          ...state.anaylyzeAttributesStates,
          [action.email]: 'success',
        },
        anaylyzeAttributesResults: {
          ...state.anaylyzeAttributesResults,
          [action.email]: action.content,
        },
      }
    case analyzeAttributesActions.REQUEST_FAILURE:
      return {
        ...state,
        anaylyzeAttributesStates: {
          ...state.anaylyzeAttributesStates,
          [action.email]: 'failure',
        },
      }

    // Connect API Customer
    case ConnectApiCustomerActions.REQUEST:
      return { ...state, connectApiCustomerState: 'loading' }
    case ConnectApiCustomerActions.REQUEST_SUCCESS:
      return { ...state, connectApiCustomerState: 'success' }
    case ConnectApiCustomerActions.REQUEST_FAILURE:
      return { ...state, connectApiCustomerState: 'failure' }

    case photoActions.REQUEST:
      return {
        ...state,
        photoStates: {
          ...state.photoStates,
          [action.profileId]: 'loading',
        },
      }
    case photoActions.REQUEST_SUCCESS:
      return {
        ...state,
        photoStates: {
          ...state.photoStates,
          [action.profileId]: 'success',
        },
      }
    case photoActions.REQUEST_FAILURE:
      return {
        ...state,
        photoStates: {
          ...state.photoStates,
          [action.profileId]: 'failure',
        },
      }

    // Assessment Accuracy
    case assessmentAccuracyActions.REQUEST:
      return {
        ...state,
        assessmentAccuracyStates: {
          ...state.assessmentAccuracyStates,
          [action.scoreType]: 'loading',
        },
      }
    case assessmentAccuracyActions.REQUEST_SUCCESS:
      let newLoadResults = {
        ...state.loadResults,
        [action.profile.id]: {
          ...action.profile,
          accuracies: { ...action.scores },
        } as Profile,
      }

      if (action.profile.vanity_url) {
        newLoadResults = {
          ...newLoadResults,
          [action.profile.vanity_url]: {
            ...action.profile,
            accuracies: { ...action.scores },
          } as Profile,
        }
      }

      return {
        ...state,
        assessmentAccuracyStates: {
          ...state.assessmentAccuracyStates,
          [action.scoreType]: 'success',
        },
        loadResults: newLoadResults,
      }
    case assessmentAccuracyActions.REQUEST_FAILURE:
      return {
        ...state,
        assessmentAccuracyStates: {
          ...state.assessmentAccuracyStates,
          [action.scoreType]: 'failure',
        },
      }

    // Purchase profile
    case purchaseActions.REQUEST:
      return {
        ...state,
        purchaseProfileStates: {
          ...state.purchaseProfileStates,
          [action.key]: 'loading',
        },
      }
    case purchaseActions.REQUEST_SUCCESS:
      return {
        ...state,
        purchaseProfileStates: {
          ...state.purchaseProfileStates,
          [action.key]: 'success',
        },
        purchaseErrorMessages: {
          ...state.purchaseErrorMessages,
          [action.key]: undefined,
        },
      }
    case purchaseActions.REQUEST_FAILURE:
      return {
        ...state,
        purchaseProfileStates: {
          ...state.purchaseProfileStates,
          [action.key]: 'failure',
        },
        purchaseErrorMessages: {
          ...state.purchaseErrorMessages,
          [action.key]: action.errorMessage,
        },
      }

    // Submit Note
    case submitNoteActions.REQUEST:
      return {
        ...state,
        noteStates: {
          ...state.noteStates,
          [action.section]: 'loading',
        },
      }
    case submitNoteActions.REQUEST_SUCCESS:
      return {
        ...state,
        noteStates: {
          ...state.noteStates,
          [action.section]: 'success',
        },
      }
    case submitNoteActions.REQUEST_FAILURE:
      return {
        ...state,
        noteStates: {
          ...state.noteStates,
          [action.section]: 'failure',
        },
      }

    // Update Note
    case updateNoteActions.REQUEST:
      return {
        ...state,
        noteStates: {
          ...state.noteStates,
          [action.section]: 'loading',
        },
      }
    case updateNoteActions.REQUEST_SUCCESS:
      return {
        ...state,
        noteStates: {
          ...state.noteStates,
          [action.section]: 'success',
        },
      }
    case updateNoteActions.REQUEST_FAILURE:
      return {
        ...state,
        noteStates: {
          ...state.noteStates,
          [action.section]: 'failure',
        },
      }

    // Tasks
    case taskActions.REQUEST:
      return { ...state, inviteTasksState: 'loading' }

    case taskActions.REQUEST_SUCCESS:
      return { ...state, inviteTasksState: 'success' }

    case taskActions.REQUEST_FAILURE:
      return { ...state, inviteTasksState: 'failure' }

    // Groups index
    case getGroupsActions.REQUEST:
      return {
        ...state,
        groupsStates: {
          ...state.groupsStates,
          [action.key]: 'loading',
        },
      }

    case getGroupsActions.REQUEST_SUCCESS:
      return {
        ...state,
        groupsStates: {
          ...state.groupsStates,
          [action.key]: 'success',
        },
        groupsResults: {
          ...state.groupsResults,
          [action.key]: action.groups,
        },
      }

    case getGroupsActions.REQUEST_FAILURE:
      return {
        ...state,
        groupsStates: {
          ...state.groupsStates,
          [action.key]: 'success',
        },
      }

    // Groups show
    case getGroupActions.REQUEST:
      return {
        ...state,
        groupStates: {
          ...state.groupStates,
          [action.groupId]: 'loading',
        },
      }

    case getGroupActions.REQUEST_SUCCESS:
      return {
        ...state,
        groupStates: {
          ...state.groupStates,
          [action.groupId]: 'success',
        },
        groupResults: {
          ...state.groupResults,
          [action.groupId]: action.group,
        },
      }

    case getGroupActions.REQUEST_FAILURE:
      return {
        ...state,
        groupStates: {
          ...state.groupStates,
          [action.groupId]: 'failure',
        },
      }

    // Groups create
    case createGroupActions.REQUEST:
      return {
        ...state,
        createGroupStates: {
          ...state.createGroupStates,
          [action.key]: 'loading',
        },
      }

    case createGroupActions.REQUEST_SUCCESS:
      return {
        ...state,
        createGroupStates: {
          ...state.createGroupStates,
          [action.key]: 'success',
        },
      }

    case createGroupActions.REQUEST_FAILURE:
      return {
        ...state,
        createGroupStates: {
          ...state.createGroupStates,
          [action.key]: 'failure',
        },
      }

    case dataActions.REQUEST:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'loading',
        },
      }

    case dataActions.REQUEST_SUCCESS:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'success',
        },
        data: {
          ...state.data,
          [action.key]: action.data,
        },
      }

    case dataActions.REQUEST_FAILURE:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'failure',
        },
      }

    // Tags index
    case getTags.REQUEST:
      return {
        ...state,
        tagsStates: {
          ...state.tagsStates,
          [action.key]: 'loading',
        },
      }
    case getTags.REQUEST_SUCCESS:
      return {
        ...state,
        tagsStates: {
          ...state.tagsStates,
          [action.key]: 'success',
        },
        tagsResults: {
          ...state.tagsResults,
          [action.key]: action.data,
        },
      }
    case getTags.REQUEST_FAILURE:
      return {
        ...state,
        tagsStates: {
          ...state.tagsStates,
          [action.key]: 'failure',
        },
      }

    // Create Tag
    case createTag.REQUEST:
      return {
        ...state,
        tagCreateStates: 'loading',
      }
    case createTag.REQUEST_SUCCESS:
      return {
        ...state,
        tagCreateStates: 'success',
        tagsStates: {},
      }
    case createTag.REQUEST_FAILURE:
      return {
        ...state,
        tagCreateStates: 'failure',
      }

    // Assign Tag
    case assignTag.REQUEST:
      return {
        ...state,
        tagAssignStates: {
          ...state.tagAssignStates,
          [action.key]: 'loading',
        },
      }
    case assignTag.REQUEST_SUCCESS:
      return {
        ...state,
        tagAssignStates: {
          ...state.tagAssignStates,
          [action.key]: 'success',
        },
      }
    case assignTag.REQUEST_FAILURE:
      return {
        ...state,
        tagAssignStates: {
          ...state.tagAssignStates,
          [action.key]: 'failure',
        },
      }

    // Remove Tag
    case removeTag.REQUEST:
      return {
        ...state,
        tagRemoveStates: {
          ...state.tagRemoveStates,
          [action.key]: 'loading',
        },
      }
    case removeTag.REQUEST_SUCCESS:
      return {
        ...state,
        tagRemoveStates: {
          ...state.tagRemoveStates,
          [action.key]: 'success',
        },
      }
    case removeTag.REQUEST_FAILURE:
      return {
        ...state,
        tagRemoveStates: {
          ...state.tagRemoveStates,
          [action.key]: 'failure',
        },
      }

    // Export CSV
    case exportCsvActions.REQUEST:
      return {
        ...state,
        exportCsvStates: {
          ...state.exportCsvStates,
          [action.key]: 'loading',
        },
      }
    case exportCsvActions.REQUEST_SUCCESS:
      return {
        ...state,
        exportCsvStates: {
          ...state.exportCsvStates,
          [action.key]: 'success',
        },
      }
    case exportCsvActions.REQUEST_FAILURE:
      return {
        ...state,
        exportCsvStates: {
          ...state.exportCsvStates,
          [action.key]: 'failure',
        },
      }

    // Create assessment profile
    case createAssessmentProfileActions.REQUEST:
      return {
        ...state,
        createAssessmentProfileStates: {
          ...state.createAssessmentProfileStates,
          [action.key]: 'loading',
        },
      }
    case createAssessmentProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        createAssessmentProfileStates: {
          ...state.createAssessmentProfileStates,
          [action.key]: 'success',
        },
        createAssessmentProfileResults: {
          ...state.createAssessmentProfileResults,
          [action.key]: action.data,
        },
      }
    case createAssessmentProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        createAssessmentProfileStates: {
          ...state.createAssessmentProfileStates,
          [action.key]: 'failure',
        },
      }

    // Create job assessment profile
    case createJobAssessmentProfileActions.REQUEST:
      return {
        ...state,
        createJobCandidateAssessmentProfileStates: {
          ...state.createJobCandidateAssessmentProfileStates,
          [action.key]: 'loading',
        },
      }
    case createJobAssessmentProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        createJobCandidateAssessmentProfileStates: {
          ...state.createJobCandidateAssessmentProfileStates,
          [action.key]: 'success',
        },
      }
    case createJobAssessmentProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        createJobCandidateAssessmentProfileStates: {
          ...state.createJobCandidateAssessmentProfileStates,
          [action.key]: 'failure',
        },
      }

    // Create legacy job assessment profile
    case createLegacyJobAssessmentProfileActions.REQUEST:
      return {
        ...state,
        createLegacyJobCandidateAssessmentProfileStates: {
          ...state.createLegacyJobCandidateAssessmentProfileStates,
          [action.key]: 'loading',
        },
      }
    case createLegacyJobAssessmentProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        createLegacyJobCandidateAssessmentProfileStates: {
          ...state.createLegacyJobCandidateAssessmentProfileStates,
          [action.key]: 'success',
        },
        createLegacyJobCandidateAssessmentProfileCandidateId: {
          ...state.createLegacyJobCandidateAssessmentProfileCandidateId,
          id: action.data.id,
        },
      }
    case createLegacyJobAssessmentProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        createLegacyJobCandidateAssessmentProfileStates: {
          ...state.createLegacyJobCandidateAssessmentProfileStates,
          [action.key]: 'failure',
        },
      }

    // Create assessment profile
    case profileViewedActions.REQUEST:
      return {
        ...state,
        profileViewedState: 'loading',
      }
    case profileViewedActions.REQUEST_SUCCESS:
      return {
        ...state,
        profileViewedState: 'success',
      }
    case profileViewedActions.REQUEST_FAILURE:
      return {
        ...state,
        profileViewedState: 'failure',
      }

    // Get Candidates
    case candidatesActions.REQUEST:
      return {
        ...state,
        candidatesStates: {
          ...state.candidatesStates,
          [action.key]: 'loading',
        },
      }
    case candidatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        candidatesStates: {
          ...state.candidatesStates,
          [action.key]: 'success',
        },
        candidatesResults: {
          ...state.candidatesResults,
          [action.key]: action.data,
        },
      }
    case candidatesActions.REQUEST_FAILURE:
      return {
        ...state,
        candidatesStates: {
          ...state.candidatesStates,
          [action.key]: 'failure',
        },
      }

    // Create Profile Endorsement
    case createProfileEndorsementActions.REQUEST:
      return {
        ...state,
        createProfileEndorsementStates: {
          ...state.createProfileEndorsementStates,
          [action.key]: 'loading',
        },
      }
    case createProfileEndorsementActions.REQUEST_SUCCESS:
      return {
        ...state,
        createProfileEndorsementStates: {
          ...state.createProfileEndorsementStates,
          [action.key]: 'success',
        },
      }
    case createProfileEndorsementActions.REQUEST_FAILURE:
      return {
        ...state,
        createProfileEndorsementStates: {
          ...state.createProfileEndorsementStates,
          [action.key]: 'failure',
        },
      }

    // Get Profile Endorsement
    case getProfileEndorsementsActions.REQUEST:
      return {
        ...state,
        getProfileEndorsementsStates: {
          ...state.getProfileEndorsementsStates,
          [action.key]: 'loading',
        },
      }
    case getProfileEndorsementsActions.REQUEST_SUCCESS:
      return {
        ...state,
        getProfileEndorsementsStates: {
          ...state.getProfileEndorsementsStates,
          [action.key]: 'success',
        },
        getProfileEndorsementsResults: {
          ...state.getProfileEndorsementsResults,
          [action.key]: action.profiles,
        },
      }
    case getProfileEndorsementsActions.REQUEST_FAILURE:
      return {
        ...state,
        getProfileEndorsementsStates: {
          ...state.getProfileEndorsementsStates,
          [action.key]: 'failure',
        },
      }

    // Delete Profile Endorsement
    case deleteProfileEndorsementActions.REQUEST:
      return {
        ...state,
        deleteProfileEndorsementStates: {
          ...state.deleteProfileEndorsementStates,
          [action.key]: 'loading',
        },
      }
    case deleteProfileEndorsementActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteProfileEndorsementStates: {
          ...state.deleteProfileEndorsementStates,
          [action.key]: 'success',
        },
      }
    case deleteProfileEndorsementActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteProfileEndorsementStates: {
          ...state.deleteProfileEndorsementStates,
          [action.key]: 'failure',
        },
      }

    // Candidate Relationship
    case candidateRelationshipActions.REQUEST:
      return {
        ...state,
        candidateRelationshipStates: {
          ...state.candidateRelationshipStates,
          [action.key]: 'loading',
        },
      }
    case candidateRelationshipActions.REQUEST_SUCCESS:
      return {
        ...state,
        candidateRelationshipStates: {
          ...state.candidateRelationshipStates,
          [action.key]: 'success',
        },
        candidateRelationshipResults: {
          ...state.candidateRelationshipResults,
          [action.key]: action.data,
        },
      }
    case candidateRelationshipActions.REQUEST_FAILURE:
      return {
        ...state,
        candidateRelationshipStates: {
          ...state.candidateRelationshipStates,
          [action.key]: 'failure',
        },
      }

    // Get Email Suggestions
    case getEmailSuggestionsActions.REQUEST:
      return {
        ...state,
        emailSuggestionsStates: {
          ...state.emailSuggestionsStates,
          [action.key]: 'loading',
        },
      }

    case getEmailSuggestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        emailSuggestionsStates: {
          ...state.emailSuggestionsStates,
          [action.key]: 'success',
        },
        emailSuggestionsResults: {
          ...state.emailSuggestionsResults,
          [action.key]: action.data,
        },
      }

    case getEmailSuggestionsActions.REQUEST_FAILURE:
      return {
        ...state,
        emailSuggestionsStates: {
          ...state.emailSuggestionsStates,
          [action.key]: 'failure',
        },
      }

    // Update profile tag name
    case updateTagActions.REQUEST:
      return {
        ...state,
        updateTagStates: {
          ...state.updateTagStates,
          [action.key]: 'loading',
        },
      }

    case updateTagActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateTagStates: {
          ...state.updateTagStates,
          [action.key]: 'success',
        },
      }

    case updateTagActions.REQUEST_FAILURE:
      return {
        ...state,
        updateTagStates: {
          ...state.updateTagStates,
          [action.key]: 'failure',
        },
      }

    // Destroy profile tag name
    case destroyTagActions.REQUEST:
      return {
        ...state,
        destroyTagStates: {
          ...state.destroyTagStates,
          [action.key]: 'loading',
        },
      }

    case destroyTagActions.REQUEST_SUCCESS:
      return {
        ...state,
        destroyTagStates: {
          ...state.destroyTagStates,
          [action.key]: 'success',
        },
      }

    case destroyTagActions.REQUEST_FAILURE:
      return {
        ...state,
        destroyTagStates: {
          ...state.destroyTagStates,
          [action.key]: 'failure',
        },
      }

    // Load job candidate profile
    case showJobAssessmentProfileActions.REQUEST:
      return {
        ...state,
        jobCandidateProfileStates: {
          ...state.jobCandidateProfileStates,
          [action.key]: 'loading',
        },
      }

    case showJobAssessmentProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        jobCandidateProfileStates: {
          ...state.jobCandidateProfileStates,
          [action.key]: 'success',
        },
        jobCandidateProfileResults: {
          ...state.jobCandidateProfileResults,
          [action.key]: action.data,
        },
      }

    case showJobAssessmentProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        jobCandidateProfileStates: {
          ...state.jobCandidateProfileStates,
          [action.key]: 'failure',
        },
      }

    // Load profile overview
    case profileOverviewActions.REQUEST:
      return {
        ...state,
        getProfileOverviewStates: {
          ...state.getProfileOverviewStates,
          [action.key]: 'loading',
        },
      }

    case profileOverviewActions.REQUEST_SUCCESS:
      return {
        ...state,
        getProfileOverviewStates: {
          ...state.getProfileOverviewStates,
          [action.key]: 'success',
        },
        getProfileOverviewResults: {
          ...state.getProfileOverviewResults,
          [action.key]: action.data,
        },
      }

    case profileOverviewActions.REQUEST_FAILURE:
      return {
        ...state,
        getProfileOverviewStates: {
          ...state.getProfileOverviewStates,
          [action.key]: 'failure',
        },
      }

    case globalApiProfileActions.REQUEST:
      return {
        ...state,
        getGlobalApiProfileStates: {
          ...state.getGlobalApiProfileStates,
          [action.query]: 'loading',
        },
      }

    case globalApiProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        getGlobalApiProfileStates: {
          ...state.getGlobalApiProfileStates,
          [action.query]: 'success',
        },
        getGlobalApiProfileResults: {
          ...state.getGlobalApiProfileResults,
          [action.query]: action.profiles,
        },
      }

    case globalApiProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        getGlobalApiProfileStates: {
          ...state.getGlobalApiProfileStates,
          [action.query]: 'failure',
        },
      }

    case updateUsageLimitsActions.REQUEST:
      return {
        ...state,
        updateUsageLimitsStates: 'loading',
      }

    case updateUsageLimitsActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateUsageLimitsStates: 'success',
      }

    case updateUsageLimitsActions.REQUEST_FAILURE:
      return {
        ...state,
        updateUsageLimitsStates: 'failure',
      }

    default:
      return state
  }
}
