import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  APIPagedResponse,
  CandidateProfile,
  PagedOptions,
} from '@dashboard/lib/types'

export function jobArchivedProfilesQuery(
  jobId: string,
  { page, per_page }: PagedOptions,
) {
  return `${jobId}-${page}-${per_page}`
}

export const REQUEST = 'LOAD_ARCHIVED_JOB_PROFILES_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'LOAD_ARCHIVED_JOB_PROFILES_SUCCESS'
const requestSuccess = (
  key: string,
  data: APIPagedResponse<CandidateProfile>,
) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'LOAD_ARCHIVED_JOB_PROFILES_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function archivedCandidates(jobId: string, params: PagedOptions = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<CandidateProfile>>(
      (resolve, reject) => {
        const key = jobArchivedProfilesQuery(jobId, params)
        dispatch(request(key))

        Api()
          .get<APIPagedResponse<CandidateProfile>>(
            `/jobs/${jobId}/profiles/archived`,
            {
              params,
            },
          )
          .then(({ data }) => {
            dispatch(requestSuccess(key, data))

            resolve(data)
          })
          .catch(error => {
            dispatch(requestFailure(key, error))

            reject(error.response?.data)
          })
      },
    )
  }
}
