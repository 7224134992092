export const REQUEST = 'INVITE_TO_COMPANY_START'
interface RequestAction {
  type: typeof REQUEST
  email: string
}

export const REQUEST_SUCCESS = 'INVITE_TO_COMPANY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  email: string
}

export const REQUEST_FAILURE = 'INVITE_TO_COMPANY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  email: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
