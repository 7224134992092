import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'REMIND_INDIVIDUAL_START'
interface RequestAction {
  type: typeof REQUEST
  token: string
}
const request = (token: string): RequestAction => ({
  type: REQUEST,
  token,
})

export const REQUEST_SUCCESS = 'REMIND_INDIVIDUAL_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  token: string
}
const requestSuccess = (token: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  token,
})

export const REQUEST_FAILURE = 'REMIND_INDIVIDUAL_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  token: string
}
const requestFailure = (
  error: unknown,
  token: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  token,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function remindIndividual(id: string, token: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(token))

      Api()
        .post(`/teams/${id}/invites/${token}/remind`)
        .then(() => {
          dispatch(requestSuccess(token))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err, token))
          reject(
            `Error calling remindIndividualTeamUsers (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
