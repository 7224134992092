import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import md5 from 'md5'

import { APIResponse } from '@dashboard/lib/types'

export type CreateJobParams = {
  name: string
  company_name: string | null
  description?: string
  profile_id?: string
}

export const jobCreateQueryKey = (params: CreateJobParams): string => {
  const { name, company_name, description, profile_id } = params

  return md5([name, company_name, description, profile_id].join('-'))
}

export const REQUEST = 'JOB_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'JOB_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: string
  data: string
}
const requestSuccess = (query: string, data: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
  data,
})

export const REQUEST_FAILURE = 'JOB_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: string
  error: string
}
const requestFailure = (
  query: string,
  error: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createJob(params: CreateJobParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      const query = jobCreateQueryKey(params)
      dispatch(request(query))

      Api()
        .post<APIResponse<{ id: string }>>('/jobs', params)
        .then(({ data }) => {
          dispatch(requestSuccess(query, data.data.id))
          resolve(data.data.id)
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''

          if (err.response?.status === 500) {
            dispatch(
              requestFailure(query, 'There was an error creating this job'),
            )
          } else {
            dispatch(requestFailure(query, errorMessage))
          }
          reject(
            `Error calling createJob (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
