import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { AxiosError } from 'axios'
import { ProfileType } from '.'

type Filter = 'predicted' | 'verified'

export function exportCsvColumnFields() {
  return {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    company: 'Company',
    job_title: 'Job title',
    disc_type: 'DISC type',
    disc_archetype: 'Archetype',
    enneagram: 'Enneagram type',
    myers_briggs: '16-Personality type',
    traits: 'Trait Comparison',
    big_five: 'Big Five',
    // strengths: 'Strengths',
    // life_values: 'Values',
    // behavior_phrase: 'What comes naturally',
    // motivation_phrase: 'Energizers',
    // drainer_quality: 'Drainers',
  } as const
}

const CSV_FIELDS = exportCsvColumnFields()
export type CsvColumnProperty = keyof typeof CSV_FIELDS

export interface ExportProfileCsvOptions {
  export_fields: CsvColumnProperty[]
  filter?: Array<Filter>
  query?: string
  trait_key?: number
  disc_type?: string
  tags?: string
  profile_types?: ProfileType[]
}

interface ExportProfileCsvParams {
  export_fields: string
  filter?: Array<Filter>
  query?: string
  trait_key?: number
  disc_type?: string
  tags?: string
  profile_types?: string
}

export function exportCsvQueryKey(options: ExportProfileCsvOptions) {
  const { filter, query, trait_key, disc_type, tags, export_fields } = options
  return [
    export_fields.join(','),
    filter || '',
    query || '',
    trait_key || '',
    disc_type || '',
    tags || '',
  ].join('-')
}

export const REQUEST = 'PROFILE_EXPORT_CSV_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PROFILE_EXPORT_CSV_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'PROFILE_EXPORT_CSV_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function exportCsv(attributes: ExportProfileCsvOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = exportCsvQueryKey(attributes)
      const params: ExportProfileCsvParams = {
        ...attributes,
        export_fields: attributes.export_fields.join(','),
        profile_types: attributes.profile_types?.join(','),
      }

      dispatch(request(key))

      Api()
        .get('/profiles/export', { params })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(key))
          reject(
            `Error calling exportCsv (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
