import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { AxiosError } from 'axios'

export const REQUEST = 'DELETE_UNOWNED_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'DELETE_UNOWNED_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'DELETE_UNOWNED_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function deleteUnowned(uuid: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .delete(`/unowned_profiles/${uuid}`)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch((err: AxiosError<{ message: string; status: number }>) => {
          dispatch(requestFailure(uuid, err.response?.data.message || ''))
          reject(
            `Error calling deleteUnownedProfile (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
