import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

interface CreateTeamUnitParams {
  name: string
}

export const REQUEST = 'TEAM_UNIT_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'TEAM_UNIT_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'TEAM_UNIT_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createTeamUnit(teamId: string, params: CreateTeamUnitParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .post(`/teams/${teamId}/team_units`, params)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling createTeamUnit (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
