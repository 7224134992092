import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface CareerAlignmentScore {
  id: string
  score: number
  created_at: string
  profile_circumplex: {
    degrees: number
    intensity: number
  }
}

interface CareerAlignmentAnswer {
  score: number
  degrees: number
}

interface LatestCareerAlignment {
  id: string
  overview: string
  recommendations_by_type: {
    current_energizers: string[]
    potential_engergizers: string[]
    stressors: string[]
  }
  answers: CareerAlignmentAnswer[]
  profile_circumplex: {
    degrees: number
    intensity: number
  }
  degrees: number
  fit_score: number
  fit_score_explanation: string
  energy: {
    energizing: number
    draining: number
    neutral: number
  }
}

export interface CareerAlignmentResponse {
  alignment_scores: CareerAlignmentScore[]
  latest_alignment: LatestCareerAlignment
}

export const REQUEST = 'CAREER_ALIGNMENT_INDEX_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'CAREER_ALIGNMENT_INDEX_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  alignments: CareerAlignmentResponse
}
const requestSuccess = (
  alignments: CareerAlignmentResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  alignments,
})

export const REQUEST_FAILURE = 'CAREER_ALIGNMENT_INDEX_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const get = () => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request())

      Api()
        .get<APIResponse<CareerAlignmentResponse>>('career_alignments')
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling assessments.career_alignment.get (Status: ${err
              .response?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
