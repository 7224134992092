import { Profile } from '@dashboard/lib/types'

export interface EnrichedProfiles {
  data: Profile[]
  total_count: number
  total_pages: number
}

export const REQUEST = 'GET_ENRICHED_PROFILES_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}

export const REQUEST_SUCCESS = 'GET_ENRICHED_PROFILES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: EnrichedProfiles
  query: string
}

export const REQUEST_FAILURE = 'GET_ENRICHED_PROFILES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  query: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
