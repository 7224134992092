import classNamesBind from 'classnames/bind'

import ColorIcon from '@dashboard/components/ColorIcon'

import React, { FC } from 'react'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)
interface OwnProps {
  show: boolean
  green?: boolean
  square?: boolean
  text?: string
}

const SuccessCheck: FC<OwnProps> = ({ text, square, show }: OwnProps) => {
  // Functions

  return (
    <div className={classNames('container', { show })}>
      <div className={classNames('back', { square })}>
        <div className={classNames('icon')}>
          <ColorIcon icon="check" color="#fff" />
        </div>
      </div>

      <div className={classNames('successMessage', { square })}>
        {text ? text : 'Success!'}
      </div>
    </div>
  )
}

export default SuccessCheck
