import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIPagedResponse, JobResponse } from '@dashboard/lib/types'
import md5 from 'md5'

export const REQUEST = 'JOB_GET_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'JOB_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: string
  data: APIPagedResponse<JobResponse>
}
const requestSuccess = (
  query: string,
  data: APIPagedResponse<JobResponse>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
  data,
})

export const REQUEST_FAILURE = 'JOB_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: string
  error: unknown
}
const requestFailure = (
  query: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface GetJobsOptions {
  page: number
  per_page?: number
  query?: string
  sort?: string
  direction?: 'asc' | 'desc'
  partition?: number
  /** Comma separated list of permissions */
  active?: boolean
  profile_ids?: true | null
}

export const jobsQuery = ({
  page,
  per_page,
  query,
  sort,
  direction,
  partition,
  active,
  profile_ids,
}: GetJobsOptions) => {
  return md5(
    [page, per_page, query, sort, direction, partition, active, profile_ids]
      .map(i => i || '')
      .join('-'),
  )
}

export function getJobs(params: GetJobsOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<JobResponse>>((resolve, reject) => {
      const queryString = jobsQuery(params)
      dispatch(request(queryString))

      Api()
        .get<APIPagedResponse<JobResponse>>('/jobs', {
          params,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(queryString, data))
          resolve(data)
        })
        .catch(error => {
          dispatch(requestFailure(queryString, error))
          reject(error.response?.data)
        })
    })
  }
}
