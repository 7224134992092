import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import type { TaskName, TaskOptions } from '@dashboard/lib/types'

export const REQUEST = 'INVITE_TASKS_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'INVITE_TASKS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'INVITE_TASKS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function runTasks(taskNames: TaskName[], options: TaskOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      const tasks = taskNames.map(task => ({ task, options }))

      Api()
        .post('/tasks', { tasks })
        .then(() => {
          dispatch(requestSuccess())

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling runTasks (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
