import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi, Api } from '@dashboard/lib/api'

import { APIResponse, ProfileContent, Profile } from '@dashboard/lib/types'
import { localAuthToken } from '@dashboard/lib/user'

interface PlaybookCommunicationAdvice {
  data: ProfileContent
  profiles: Profile[]
}

export type PlaybookCommunicationAdviceResponse = Record<
  string,
  PlaybookCommunicationAdvice | undefined
>

export function communicationAdviceKey(playbookId: string, scenario: string) {
  return `${playbookId}-${scenario}`
}

export const REQUEST = 'PLAYBOOK_COMMUNICATION_ADVICE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_COMMUNICATION_ADVICE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: PlaybookCommunicationAdviceResponse
}
const requestSuccess = (
  key: string,
  data: PlaybookCommunicationAdviceResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_COMMUNICATION_ADVICE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadPlaybookCommunicationAdvice(
  playbookId: string,
  scenario: string,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PlaybookCommunicationAdviceResponse>(
      (resolve, reject) => {
        const key = communicationAdviceKey(playbookId, scenario)
        dispatch(request(key))

        const api = localAuthToken() ? Api() : PublicApi()

        api
          .post<APIResponse<PlaybookCommunicationAdviceResponse>>(
            `/playbooks/${playbookId}/communication_advice`,
            {
              scenario,
            },
          )
          .then(({ data }) => {
            dispatch(requestSuccess(key, data.data))

            resolve(data.data)
          })
          .catch(err => {
            dispatch(requestFailure(key, err))
            reject(
              `Error calling loadPlaybookCommunicationAdvice (Status: ${err
                .response?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      },
    )
  }
}
