import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { deleteCookie, getCookie } from '@dashboard/lib/browser'
import * as ls from 'local-storage'
import { appName, sessionToken } from '@dashboard/lib/user'
import {
  APIResponse,
  ParamDiscList,
  ParamDiscType,
  SegmentTypes,
} from '@dashboard/lib/types'
import { AxiosError } from 'axios'
import { newUserRegistered } from '@dashboard/lib/analytics'

export interface SignupParams {
  email: string
  password: string
  passwordConfirm?: string
  firstName: string
  lastName: string
  gender?: string
  companyName?: string
  companyDomain?: string
  source?: string
  companyInviteToken?: string
  gToken?: string
  profileId?: string
  actions?: string
  inviteToken?: string
  checkout_token?: string
  current_segment?: SegmentTypes
  team_id?: string
  team_unit_id?: string
  captchaResponse?: string | null
  discType?: ParamDiscType | null
  jobTitle?: string
  partner_name?: string
  privacyChecked?: boolean
}

const paramDiscTypes: ParamDiscList[] = [
  {
    param: 'dc',
    type: 'Dc',
    degrees: 158,
  },
  {
    param: 'd',
    type: 'D',
    degrees: 135,
  },
  {
    param: 'di',
    type: 'Di',
    degrees: 113,
  },
  {
    param: 'd_i',
    type: 'DI',
    degrees: 91,
  },
  {
    param: 'id',
    type: 'Id',
    degrees: 68,
  },
  {
    param: 'i',
    type: 'I',
    degrees: 46,
  },
  {
    param: 'is',
    type: 'Is',
    degrees: 23,
  },
  {
    param: 'i_s',
    type: 'IS',
    degrees: 1,
  },
  {
    param: 'si',
    type: 'Si',
    degrees: 338,
  },
  {
    param: 's',
    type: 'S',
    degrees: 315,
  },
  {
    param: 'sc',
    type: 'Sc',
    degrees: 293,
  },
  {
    param: 's_c',
    type: 'SC',
    degrees: 270,
  },
  {
    param: 'cs',
    type: 'Cs',
    degrees: 248,
  },
  {
    param: 'c',
    type: 'C',
    degrees: 225,
  },
  {
    param: 'cd',
    type: 'Cd',
    degrees: 203,
  },
  {
    param: 'c_d',
    type: 'CD',
    degrees: 180,
  },
]

const getDegreesFromParam = (paramDiscType: ParamDiscType) => {
  const paramDisc = paramDiscTypes.find(
    paramDisc => paramDisc.param === paramDiscType,
  )
  return paramDisc?.degrees.toString()
}

export const REQUEST = 'SIGNUP_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'SIGNUP_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  token: string
}
const requestSuccess = (token: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  token,
})

export const REQUEST_FAILURE = 'SIGNUP_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error?: {
    message?: string
    parameter?: string
  }
}
const requestFailure = (error?: {
  message?: string
  parameter?: string
}): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function signupAttributes(attributes: SignupParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(request())
      interface EndpointParams {
        user: {
          email: string
          password: string
          first_name: string
          last_name: string
          gender?: string
          session_app: string
          session_token: string
          job_title?: string
        }
        company_name?: string
        company_domain?: string
        company_source?: string
        gtoken?: string
        profile_id?: string
        invite_token?: string
        company_invite_token?: string
        actions?: string
        enneagram?: string
        myers_briggs?: string
        slack_team_id?: string
        api_customer_id?: string
        facebook_id?: string
        checkout_token?: string
        personality?: { degrees: string; intensity: string }
        current_segment?: SegmentTypes
        team_id?: string
        team_unit_id?: string
        captcha_response?: string
        partner_name?: string
      }

      const params: EndpointParams = {
        user: {
          email: attributes.email,
          password: attributes.password,
          first_name: attributes.firstName,
          last_name: attributes.lastName,
          job_title: attributes.jobTitle,
          session_app: appName(),
          session_token: sessionToken(),
        },
        company_name: attributes.companyName,

        company_domain: attributes.companyDomain,
        company_source: attributes.source,
      }

      if (attributes.gToken) {
        params.gtoken = attributes.gToken
      }

      if (attributes.gender) {
        params.user.gender = attributes.gender
      }

      if (attributes.jobTitle) params.user.job_title = attributes.jobTitle

      if (attributes.profileId) {
        params.profile_id = attributes.profileId
      }

      if (attributes.inviteToken) {
        params.invite_token = attributes.inviteToken
      }

      if (attributes.companyInviteToken) {
        params.company_invite_token = attributes.companyInviteToken
      }

      if (attributes.actions) {
        params.actions = attributes.actions
      }

      if (attributes.checkout_token) {
        params.checkout_token = attributes.checkout_token
      }

      if (attributes.current_segment) {
        params.current_segment = attributes.current_segment
      }

      if (attributes.captchaResponse) {
        params.captcha_response = attributes.captchaResponse
      }

      if (attributes.team_id) params.team_id = attributes.team_id

      if (attributes.team_unit_id) params.team_unit_id = attributes.team_unit_id

      if (attributes.partner_name) params.partner_name = attributes.partner_name

      const slackTeamId = ls.get<string | void>('slackTeamId')
      const apiCustomerId = ls.get<string | void>('apiCustomerId')
      const facebookID = ls.get<string | void>('facebookID')
      const enneagram = getCookie('enneagram')
      const myersBriggs = getCookie('myers_briggs')
      const degrees =
        getDegreesFromParam(attributes.discType as ParamDiscType) ||
        getCookie('disc_degrees')
      const intensity = getCookie('disc_intensity') || '75'

      if (enneagram) params.enneagram = enneagram

      if (myersBriggs) params.myers_briggs = myersBriggs

      if (slackTeamId) {
        params.slack_team_id = slackTeamId
      }

      if (apiCustomerId) {
        params.api_customer_id = apiCustomerId
      }

      if (facebookID) {
        params.facebook_id = facebookID
      }

      if (degrees && degrees.length > 0 && intensity && intensity.length > 0) {
        params.personality = { degrees, intensity }
      }
      Api()
        .post<APIResponse<{ token: string }>>('/registrations', params)
        .then(({ data }) => {
          ls.remove('publicAssessmentUUID')
          ls.remove('slackTeamId')
          ls.remove('apiCustomerId')
          deleteCookie('public_assessment_uuid')
          deleteCookie('enneagram')
          deleteCookie('myers_briggs')
          deleteCookie('disc_degrees')
          deleteCookie('disc_intensity')
          dispatch(requestSuccess(data.data.token))
          if (data.data.token) {
            // hubspot
            newUserRegistered(
              attributes.email,
              attributes.firstName,
              attributes.lastName,
              attributes.companyName,
              attributes.partner_name,
            )
          }
          resolve(data.data.token)
        })
        .catch(
          (
            err: AxiosError<{
              message?: string
              parameter?: string
              error?: string
            }>,
          ) => {
            const formattedError = err.response?.data.message
              ? err.response?.data
              : { message: err.response?.data.error }

            dispatch(requestFailure(formattedError))
            reject(
              `Error calling registerUser (Status: ${
                err.response?.status
              }): ${JSON.stringify(err.response?.data)}`,
            )
          },
        )
    })
  }
}
