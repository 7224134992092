export const REQUEST = 'INVITE_PEER_ASSESSMENT_START'
type RequestAction = { type: typeof REQUEST; profile_id: string }

export const REQUEST_SUCCESS = 'INVITE_PEER_ASSESSMENT_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  profile_id: string
}

export const REQUEST_FAILURE = 'INVITE_PEER_ASSESSMENT_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  profile_id: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
