import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'TEAM_USER_DESTROY_START'
interface RequestAction {
  type: typeof REQUEST
  id: string
  userID: string
}
const request = (id: string, userID: string): RequestAction => ({
  type: REQUEST,
  id,
  userID,
})

export const REQUEST_SUCCESS = 'TEAM_USER_DESTROY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  id: string
  userID: string
  email: string
}
const requestSuccess = (
  id: string,
  userID: string,
  email: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  id,
  userID,
  email,
})

export const REQUEST_FAILURE = 'TEAM_USER_DESTROY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  id: string
  userID: string
  error: unknown
}
const requestFailure = (
  id: string,
  userID: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  id,
  userID,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function destroyTeamUser(id: string, userID: string, email: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(id, userID))

      Api()
        .delete(`/teams/${id}/members/${userID}`)
        .then(() => {
          dispatch(requestSuccess(id, userID, email))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(id, userID, err))
          reject(
            `Error calling destroyTeamUser (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
