import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export function createImageQueryKey(
  url: string,
  width: number,
  height: number,
) {
  return `${url}-${width}-${height}`
}

export const REQUEST = 'IMAGE_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'IMAGE_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  imageURL: string
}
const requestSuccess = (
  key: string,
  imageURL: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  imageURL,
})

export const REQUEST_FAILURE = 'IMAGE_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createImageRequest(url: string, width: number, height: number) {
  return PublicApi().post<APIResponse<{ image_url: string }>>(
    '/public/images',
    {
      url,
      width,
      height,
    },
  )
}

/* eslint-disable */
function createImage(url: string, width: number, height: number) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      const key = createImageQueryKey(url, width, height)

      dispatch(request(key))

      createImageRequest(url, width, height)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data.image_url))

          resolve(data.data.image_url)
        })
        .catch(err => {
          dispatch(requestFailure(key))
        })
    })
  }
}
/* eslint-disable */
