import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { AuthedHeaders, Api, PublicApi } from '@dashboard/lib/api'
import { APIResponse, PlaybookResponse } from '@dashboard/lib/types'

export const REQUEST = 'PLAYBOOKS_SHOW_START'
interface RequestAction {
  type: typeof REQUEST
  playbookId: string
}
const request = (playbookId: string): RequestAction => ({
  type: REQUEST,
  playbookId,
})

export const REQUEST_SUCCESS = 'PLAYBOOKS_SHOW_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  playbookId: string
  data: PlaybookResponse
}
const requestSuccess = (
  playbookId: string,
  data: PlaybookResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  playbookId,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOKS_SHOW_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  playbookId: string
  error: unknown
}
const requestFailure = (
  playbookId: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  playbookId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadPlaybookRequest(
  playbookId: string,
  headers?: AuthedHeaders,
  isPublic?: boolean,
) {
  const url = `/playbooks/${playbookId}`
  const api = isPublic ? PublicApi() : Api(headers)

  return api.get<APIResponse<PlaybookResponse>>(url)
}

export function loadPlaybook(
  playbookId: string,
  headers?: AuthedHeaders,
  isPublic?: boolean,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PlaybookResponse>((resolve, reject) => {
      dispatch(request(playbookId))

      loadPlaybookRequest(playbookId, headers, isPublic)
        .then(({ data }) => {
          dispatch(requestSuccess(playbookId, data.data))

          resolve(data.data)
        })
        .catch(error => {
          dispatch(requestFailure(playbookId, error))

          reject(error.response?.data)
        })
    })
  }
}
