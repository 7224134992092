export const REQUEST = 'PROFILE_ENDORSE_START'
interface RequestAction {
  type: typeof REQUEST
  profileID: string
  dataID: string
}

export const REQUEST_SUCCESS = 'PROFILE_ENDORSE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profileID: string
  dataID: string
}

export const REQUEST_FAILURE = 'PROFILE_ENDORSE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  profileID: string
  dataID: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
