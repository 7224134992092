import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { Products } from '@dashboard/lib/types'

export const createProductQuery = (
  profile_id: string,
  product: Products,
  quantity?: number,
) => {
  return `${product}-${profile_id}-${quantity}`
}

export const REQUEST = 'CREATE_PRODUCT_SUBSCRIPTION_START'
type RequestAction = { type: typeof REQUEST; query: string }
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'CREATE_PRODUCT_SUBSCRIPTION_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  query: string
}
const requestSuccess = (query: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
})

export const REQUEST_FAILURE = 'CREATE_PRODUCT_SUBSCRIPTION_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  query: string
  error: { response: { data: { message: string } } }
}
const requestFailure = (
  query: string,
  error: { response: { data: { message: string } } },
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface CreateProductParams {
  checkout_token: string | null
  plan_id: string
  profile_id: string
  product: Products
  quantity?: number
}

export function createProduct(params: CreateProductParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { product, profile_id, quantity } = params
      const query = createProductQuery(profile_id, product, quantity)

      dispatch(request(query))

      let url = '/subscriptions'
      if (product == 'sales') url += '/sales'
      if (product == 'leadership') url += '/leadership'
      if (product == 'hiring') url += '/hiring'

      Api()
        .post(url, params)
        .then(() => {
          dispatch(requestSuccess(query))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(query, err))
          reject(
            `Error calling createProduct (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
