import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export type CustomerStatsResponse = {
  assessment: { allowance: number; usage: number }
  general: { allowance: number; usage: number }
}

export const REQUEST = 'API_CUSTOMER_GET_STATS_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'API_CUSTOMER_GET_STATS_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  data: CustomerStatsResponse
}
const requestSuccess = (data: CustomerStatsResponse): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'API_CUSTOMER_GET_STATS_FAILURE'
type RequestFailureAction = { type: typeof REQUEST_FAILURE }
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getStats(id: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request())

      Api()
        .get(`api_customers/${id}/stats`)
        .then(({ data }) => {
          dispatch(requestSuccess(data))
          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling getStats (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
