import { Personality } from '@dashboard/lib/types'

export const REQUEST = 'SUBMIT_PEER_ASSESSMENT_START'
interface RequestAction {
  id: string
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'SUBMIT_PEER_ASSESSMENT_SUCCESS'
interface RequestSuccessAction {
  id: string
  data: Personality
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'SUBMIT_PEER_ASSESSMENT_FAILURE'
interface RequestFailureAction {
  id: string
  error: unknown
  type: typeof REQUEST_FAILURE
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
