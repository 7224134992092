import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'

import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'
import { GroupsResponse } from '.'

export const REQUEST = 'GROUPS_SHOW_GET_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'GROUPS_SHOW_GET_SUCCESS'
const requestSuccess = (key: string, data: GroupsResponse) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'GROUPS_SHOW_GET_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function getGroup(groupId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<GroupsResponse>((resolve, reject) => {
      dispatch(request(groupId))

      Api()
        .get<APIResponse<GroupsResponse>>(`/groups/${groupId}`)
        .then(({ data }) => {
          dispatch(requestSuccess(groupId, data.data))
          resolve(data.data)
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(groupId, err))
          reject(
            `Error calling getGroup (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
