import * as getJobTitlePersonalityActions from '@dashboard/store/actions/job_titles/public'
import { LoadingStates } from '@dashboard/lib/types'

export interface State {
  jobTitlePersonalityStates: Record<string, LoadingStates | undefined>
  jobTitlePersonalityResults: Record<
    string,
    getJobTitlePersonalityActions.JobTitlePersonalityResponse | undefined
  >
}

const INITIAL_STATE: State = {
  jobTitlePersonalityStates: {},
  jobTitlePersonalityResults: {},
}

type Action = getJobTitlePersonalityActions.Action

export default function jobTitleReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Get Public Relationship Data
    case getJobTitlePersonalityActions.REQUEST:
      return {
        ...state,
        jobTitlePersonalityStates: {
          ...state.jobTitlePersonalityStates,
          [action.jobTitle]: 'loading',
        },
      }
    case getJobTitlePersonalityActions.REQUEST_SUCCESS:
      return {
        ...state,
        jobTitlePersonalityStates: {
          ...state.jobTitlePersonalityStates,
          [action.jobTitle]: 'success',
        },
        jobTitlePersonalityResults: {
          ...state.jobTitlePersonalityResults,
          [action.jobTitle]: action.data,
        },
      }
    case getJobTitlePersonalityActions.REQUEST_FAILURE:
      return {
        ...state,
        jobTitlePersonalityStates: {
          ...state.jobTitlePersonalityStates,
          [action.jobTitle]: 'failure',
        },
      }

    default:
      return state
  }
}
