import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'

import { APIResponse, AssessmentQuestion } from '@dashboard/lib/types'
import { SUPPORTED_LANGUAGES } from '@dashboard/lib/locale'
import { tsErrorHelper } from '@dashboard/lib/playbooks'

export type SupportedLanguages = (typeof SUPPORTED_LANGUAGES)[number]

export function publicAssessmentQuestionsKey(
  locale: SupportedLanguages = 'en',
) {
  switch (locale) {
    case 'zh-CN':
      return 'zh'
    case 'en':
      return 'en'
    default:
      tsErrorHelper(locale)
      return locale
  }
}

export const REQUEST = 'ASSESSMENT_PUBLIC_QUESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'ASSESSMENT_PUBLIC_QUESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  questions: AssessmentQuestion[]
}
const requestSuccess = (
  key: string,
  questions: AssessmentQuestion[],
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  questions,
})

export const REQUEST_FAILURE = 'ASSESSMENT_PUBLIC_QUESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const questions = (locale: SupportedLanguages = 'en') => {
  const language = publicAssessmentQuestionsKey(locale)
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request(language))

      const config = language === 'en' ? undefined : { params: { language } }

      PublicApi()
        .get<APIResponse<AssessmentQuestion[]>>(
          'public/assessments/questions',
          config,
        )
        .then(({ data }) => {
          dispatch(requestSuccess(language, data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(language))
          reject(
            `Error calling assessments.public.questions (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
