import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface CreateApiCustomerTokenResponse {
  token: string
  id: string
}

export const REQUEST = 'API_CUSTOMER_CREATE_TOKEN_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'API_CUSTOMER_CREATE_TOKEN_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  id: string
  token: string
}
const requestSuccess = (id: string, token: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  id,
  token,
})

export const REQUEST_FAILURE = 'API_CUSTOMER_CREATE_TOKEN_FAILURE'
type RequestFailureAction = { type: typeof REQUEST_FAILURE }
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createToken() {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<CreateApiCustomerTokenResponse>((resolve, reject) => {
      dispatch(request())

      Api()
        .post<APIResponse<CreateApiCustomerTokenResponse>>('/api_customers')
        .then(({ data }) => {
          dispatch(requestSuccess(data.data.id, data.data.token))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling createToken (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
