import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { InviteResponse } from '@dashboard/lib/types'

export const REQUEST = 'TEAM_MEMBER_INVITE'

interface RequestAction {
  type: typeof REQUEST
  id: string
}

export interface LoadTeamInvitesParams {
  page: number
  query?: string
  sort?: 'name' | 'email'
  direction?: 'asc' | 'desc'
}

const request = (id: string): RequestAction => ({
  type: REQUEST,
  id,
})

export const REQUEST_SUCCESS = 'TEAM_MEMBER_INVITE_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  id: string
  data: InviteResponse
}

const requestSuccess = (
  id: string,
  data: InviteResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  id,
  data,
})

export const REQUEST_FAILURE = 'TEAM_MEMBER_INVITE_LOAD_FAILURE'

interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  id: string
  error: unknown
}

export function teamQuery(
  teamId: string,
  { page, query, direction }: LoadTeamInvitesParams,
) {
  return `${teamId}-${page}-${query}-${direction}`
}

const requestFailure = (id: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  id,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadInvites(teamId: string, params: LoadTeamInvitesParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<InviteResponse>((resolve, reject) => {
      const key = teamQuery(teamId, params)
      dispatch(request(key))

      Api()
        .get<InviteResponse>(`/teams/${teamId}/invites`, {
          params,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))
          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling loadInvites (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
