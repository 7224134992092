export const REQUEST = 'DELETE_REFERRAL_START'
interface RequestAction {
  type: typeof REQUEST
  referralID: string
}

export const REQUEST_SUCCESS = 'DELETE_REFERRAL_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  referralID: string
}

export const REQUEST_FAILURE = 'DELETE_REFERRAL_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  referralID: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
