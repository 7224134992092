import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, ProfileContent, Profile } from '@dashboard/lib/types'

interface GroupCommunicationAdvice {
  data: ProfileContent
  profiles: Profile[]
}

export type GroupCommunicationAdviceResponse = Record<
  string,
  GroupCommunicationAdvice | undefined
>

export function groupCommunicationAdviceKey(groupId: string, scenario: string) {
  return `${groupId}-${scenario}`
}

export const REQUEST = 'GROUPS_COMMUNICATION_ADVICE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'GROUPS_COMMUNICATION_ADVICE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: GroupCommunicationAdviceResponse
}
const requestSuccess = (
  key: string,
  data: GroupCommunicationAdviceResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'GROUPS_COMMUNICATION_ADVICE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadMyTeamsGroupCommunicationAdvice(
  groupId: string,
  scenario: string,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<GroupCommunicationAdviceResponse>((resolve, reject) => {
      const key = groupCommunicationAdviceKey(groupId, scenario)
      dispatch(request(key))

      Api()
        .post<APIResponse<GroupCommunicationAdviceResponse>>(
          `/groups/${groupId}/communication_advice`,
          {
            scenario,
          },
        )
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling loadMyTeamsGroupCommunicationAdvice (Status: ${err
              .response?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
