import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export interface UpdateCustomEmailParams {
  mailerId: string
  teamId: string
  subject: string
  body: string
  kind: 'team_invite' | 'candidate_invite'
  logo?: File | null
  deleteLogo?: boolean
}

export const REQUEST = 'TEAM_CUSTOM_EMAIL_UPDATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_CUSTOM_EMAIL_UPDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'TEAM_CUSTOM_EMAIL_UPDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

interface UpdateCustomEmailSubmitParams {
  subject: string
  body: string
  kind: 'team_invite' | 'candidate_invite'
  logo?: File | null
}

export function updateEmail(createParams: UpdateCustomEmailParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { subject, body, teamId, mailerId, logo, deleteLogo, kind } =
        createParams
      dispatch(request(mailerId))

      let payload: FormData | UpdateCustomEmailSubmitParams | null = null
      if (logo) {
        payload = new FormData()
        payload.append('subject', subject)
        payload.append('body', body)
        payload.append('logo', logo)
        payload.append('kind', kind)
      } else if (deleteLogo) {
        payload = {
          subject,
          body,
          logo: null,
          kind,
        }
      } else {
        payload = {
          subject,
          body,
          kind,
        }
      }

      Api()
        .put(`/teams/${teamId}/mailers/${mailerId}`, payload)
        .then(() => {
          dispatch(requestSuccess(mailerId))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(mailerId, err))
          reject(
            `Error calling updateEmail (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
