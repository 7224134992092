import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { TeamStats, PagedOptions, APIResponse } from '@dashboard/lib/types'

export interface LoadTeamStatsOptions extends PagedOptions {
  teamId: string
  range?: string
  profile_id?: string
  query?: string
  sort?: string
}

export interface NextLoadTeamStatsOptions extends PagedOptions {
  teamId: string
  startDate?: string | null
  endDate?: string | null
  query?: {
    id: string
    type: string
  } | null
  sort?: string
}

export const getTeamStatsQueryKey = (options: LoadTeamStatsOptions) => {
  return JSON.stringify(options)
}

export const REQUEST = 'TEAM_STATS_LOAD_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_STATS_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: TeamStats
}
const requestSuccess = (
  key: string,
  data: TeamStats,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'TEAM_STATS_LOAD_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

interface StatsRouteParams extends PagedOptions {
  range?: string
  profile_id?: string
  query?: string
  sort?: string
}

export function loadTeamStats(statParams: LoadTeamStatsOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<TeamStats>((resolve, reject) => {
      const { teamId, range, profile_id, page, per_page, query, sort } =
        statParams
      const key = getTeamStatsQueryKey(statParams)
      dispatch(request(key))

      const params: StatsRouteParams = {}

      if (range) params.range = range
      if (profile_id) params.profile_id = profile_id
      if (query) params.query = query
      if (page) params.page = page
      if (per_page) params.per_page = per_page
      if (sort) params.sort = sort

      Api()
        .get<APIResponse<TeamStats>>(`/teams/${teamId}/stats`, { params })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling loadTeamStats (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
