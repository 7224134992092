import { APIPagedResponse, PlaybookResponse } from '@dashboard/lib/types'

export const REQUEST = 'MEETINGS_GET_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'MEETINGS_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<PlaybookResponse>
}

export const REQUEST_FAILURE = 'MEETINGS_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
