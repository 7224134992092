import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, ExpectationSurveyAnswer } from '@dashboard/lib/types'

export const REQUEST = 'SURVEY_CREATE_PUBLIC_START'
interface RequestAction {
  type: typeof REQUEST
  jobId: string
}
const request = (jobId: string): RequestAction => ({
  type: REQUEST,
  jobId,
})

export const REQUEST_SUCCESS = 'SURVEY_CREATE_PUBLIC_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  jobId: string
}
const requestSuccess = (jobId: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  jobId,
})

export const REQUEST_FAILURE = 'SURVEY_CREATE_PUBLIC_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  jobId: string
  error: string
}
const requestFailure = (
  jobId: string,
  error: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  jobId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface createParams {
  job_id: string
  answers: ExpectationSurveyAnswer[]
  first_name: string
  last_name: string
  email: string
  job_title: string
}

export function createPublicSurvey(params: createParams) {
  return async (dispatch: ThunkDispatch<object, object, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const id = params.job_id
      dispatch(request(id))

      Api()
        .post<APIResponse<{ id: string }>>('/public/job_surveys', params)
        .then(() => {
          dispatch(requestSuccess(id))
          resolve()
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(id, errorMessage))
          reject(
            `Error calling createPublicSurvey (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
