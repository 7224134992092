import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  PlaybookTemplateObject,
  PlaybookTemplateType,
  APIPagedResponse,
} from '@dashboard/lib/types'

export interface TemplateOptions {
  type?: PlaybookTemplateType
  page?: number
  per_page?: number
}

export function templatesQueryKey({
  type,
  per_page,
  page,
}: TemplateOptions = {}) {
  return `${type}-${per_page}-${page}`
}

export const REQUEST = 'PLAYBOOK_TEMPLATES_LOAD_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_TEMPLATES_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<PlaybookTemplateObject>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<PlaybookTemplateObject>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_TEMPLATES_LOAD_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getTemplates(params: TemplateOptions = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<PlaybookTemplateObject>>(
      (resolve, reject) => {
        const key = templatesQueryKey(params)
        dispatch(request(key))

        Api()
          .get<APIPagedResponse<PlaybookTemplateObject>>(
            '/playbook_templates',
            {
              params,
            },
          )
          .then(({ data }) => {
            dispatch(requestSuccess(key, data))
            resolve(data)
          })
          .catch(err => {
            dispatch(requestFailure(key, err))
            reject(
              `Error calling getTemplates (Status: ${err.response
                ?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      },
    )
  }
}
