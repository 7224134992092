import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export interface UpdateProductParams {
  auto_assign: boolean
}

export const REQUEST = 'UPDATE_PRODUCT_START'
type RequestAction = { type: typeof REQUEST; key: string }
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'UPDATE_PRODUCT_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  key: string
  error: { response: { data: { message: string } } }
}
const requestFailure = (
  key: string,
  error: {
    response: { data: { message: string } }
  },
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateProduct(productId: string, params: UpdateProductParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(productId))

      Api()
        .put(`products/${productId}`, params)
        .then(() => {
          dispatch(requestSuccess(productId))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(productId, err))
          reject(
            `Error calling updateProduct (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
