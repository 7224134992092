import { DiscType } from '@dashboard/lib/types'

export interface TopThreeTraitsArchetype {
  archetype: string
  disc_type: DiscType
  qualities: string[]
}

export const REQUEST = 'DIDSC_DATA_ARCHETYPES_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'DIDSC_DATA_ARCHETYPES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: TopThreeTraitsArchetype[]
}

export const REQUEST_FAILURE = 'DIDSC_DATA_ARCHETYPES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
