import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, Datum } from '@dashboard/lib/types'

export type MatrixResponse = Record<string, Record<string, Datum[]>>

export const REQUEST = 'GROUPS_MATRIX_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'GROUPS_MATRIX_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: MatrixResponse
}
const requestSuccess = (
  key: string,
  data: MatrixResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'GROUPS_MATRIX_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function fetchGroupsMatrix(groupId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<MatrixResponse>((resolve, reject) => {
      dispatch(request(groupId))

      Api()
        .get<APIResponse<MatrixResponse>>(`/groups/${groupId}/matrix`)
        .then(({ data }) => {
          dispatch(requestSuccess(groupId, data.data))

          resolve(data.data)
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(groupId, errorMessage))
          reject(
            `Error calling fetchGroupsMatrix (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
