import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import md5 from 'md5'

import { Api } from '@dashboard/lib/api'

export type JobAccess = 'grant' | 'revoke'

export interface JobProfileAccess {
  profileId: string
  access: JobAccess
}

export function updateJobAccessKey(
  jobId: string,
  accessObjects: JobProfileAccess[],
) {
  return md5(`${jobId}-${accessObjects.map(a => `${a.profileId}-${a.access}`)}`)
}

export const REQUEST = 'JOB_ACCESS_UPDATE_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'JOB_ACCESS_UPDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'JOB_ACCESS_UPDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateJobAccess(
  jobId: string,
  accessObjects: JobProfileAccess[],
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = updateJobAccessKey(jobId, accessObjects)
      dispatch(request(key))

      const profiles = accessObjects.reduce<Record<string, JobAccess>>(
        (obj, curr) => {
          return {
            ...obj,
            [curr.profileId]: curr.access,
          }
        },
        {},
      )

      Api()
        .put(`/jobs/${jobId}/access`, { profiles })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err.response?.data))
          reject(
            `Error calling updateJobAccess (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
