import { APIPagedResponse, Profile } from '@dashboard/lib/types'

export interface ProfileGroup {
  id: string
  name: string
  profiles: Profile[]
}

export const REQUEST = 'PROFILE_GET_GROUPS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'PROFILE_GET_GROUPS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  groups: APIPagedResponse<ProfileGroup>
}

export const REQUEST_FAILURE = 'PROFILE_GET_GROUPS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
