export const REQUEST = 'TEAMS_DESTROY_START'
interface RequestAction {
  type: typeof REQUEST
  id: string
}

export const REQUEST_SUCCESS = 'TEAMS_DESTROY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  id: string
}

export const REQUEST_FAILURE = 'TEAMS_DESTROY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  id: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
