import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { AxiosError } from 'axios'

export const REQUEST = 'PASSWORD_EMAIL_LOAD_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PASSWORD_EMAIL_LOAD_SUCCESS'
type RequestSuccessAction = { type: typeof REQUEST_SUCCESS }
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'PASSWORD_EMAIL_LOAD_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: string | undefined
}
const requestFailure = (error: string | undefined): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function sendEmail(email: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .get('/passwords/new', { params: { email } })
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch((err: AxiosError<string>) => {
          dispatch(requestFailure(err.response?.data))
          reject(
            `Error calling sendEmail (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
