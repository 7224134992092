import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'TEAM_USERS_EXPORT_START'
const request = () =>
  ({
    type: REQUEST,
  }) as const

export const REQUEST_SUCCESS = 'TEAM_USERS_EXPORT_SUCCESS'
const requestSuccess = () =>
  ({
    type: REQUEST_SUCCESS,
  }) as const

export const REQUEST_FAILURE = 'TEAM_USERS_EXPORT_FAILURE'
const requestFailure = (error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function exportTeamUsers(teamId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .get(`/teams/${teamId}/members/export`)
        .then(() => {
          dispatch(requestSuccess())

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling exportTeamUsers (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
