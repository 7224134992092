export const REQUEST = 'ASSESSMENT_REMINDER_START'
interface RequestAction {
  type: typeof REQUEST
  profile_id: string
}

export const REQUEST_SUCCESS = 'ASSESSMENT_REMINDER_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profile_id: string
}

export const REQUEST_FAILURE = 'ASSESSMENT_REMINDER_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  profile_id: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
