import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, TeamUnit } from '@dashboard/lib/types'

export function teamUnitShowKey(teamId: string, unitId: string) {
  return `${teamId}-${unitId}`
}

export const REQUEST = 'TEAM_UNIT_SHOW_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_UNIT_SHOW_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: TeamUnit
}
const requestSuccess = (key: string, data: TeamUnit): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'TEAM_UNIT_SHOW_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadTeamUnit(teamId: string, unitId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<TeamUnit>((resolve, reject) => {
      const key = teamUnitShowKey(teamId, unitId)
      dispatch(request(key))

      Api()
        .get<APIResponse<TeamUnit>>(`/teams/${teamId}/team_units/${unitId}`)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling loadTeamUnit (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
