import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, Profile } from '@dashboard/lib/types'
import { AxiosError } from 'axios'
import { CreatePlaybookParams, createPlaybookQueryKey } from '../create'

export const REQUEST = 'LEADERSHIP_PLAYBOOK_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'LEADERSHIP_PLAYBOOK_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: string
  data: string
}
const requestSuccess = (query: string, data: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
  data,
})

export const REQUEST_FAILURE = 'LEADERSHIP_PLAYBOOK_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: string
  error: string
  profiles: Profile[] | undefined
}
const requestFailure = (
  query: string,
  error: string,
  profiles: Profile[] | undefined,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
  profiles,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createLeadershipPlaybook(params: CreatePlaybookParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      const query = createPlaybookQueryKey(params)
      dispatch(request(query))

      const sendParams: CreatePlaybookParams = {
        template_id: params.template_id,
        profile_ids: params.profile_ids,
        job_ids: params.job_ids,
      }

      if (params.name && params.name.length > 0) sendParams.name = params.name
      if (params.privacy && params.privacy.length > 0)
        sendParams.privacy = params.privacy

      Api()
        .post<APIResponse<{ id: string }>>('/playbooks/leadership', sendParams)
        .then(({ data }) => {
          dispatch(requestSuccess(query, data.data.id))
          resolve(data.data.id)
        })
        .catch((err: AxiosError<{ data?: Profile[]; message: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          const errorProfiles = err.response?.data?.data
          dispatch(requestFailure(query, errorMessage, errorProfiles))
          reject(
            `Error calling createLeadershipPlaybook (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
