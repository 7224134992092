import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import {
  APIPagedResponse,
  MemberInvite,
  TeamUser,
  TeamUserMinimal,
} from '@dashboard/lib/types'
import md5 from 'md5'

export type TeamUserRoleFilter =
  | 'none'
  | 'member_manager'
  | 'sales'
  | 'leadership'
  | 'hiring'
  | 'pending'

export interface LoadTeamUsersParams {
  page: number
  query?: string
  per_page?: number
  sort?: 'name' | 'email'
  direction?: 'asc' | 'desc'
  /** Filters out team members that belong to team units (groups). */
  without_team_unit?: true
  team_invites_only?: true
  job_id?: string
  role_filters?: TeamUserRoleFilter[]
}

export interface TeamUsersResponse
  extends APIPagedResponse<TeamUser | MemberInvite | TeamUserMinimal> {
  meta: {
    total_members: number
    total_pending: number
  }
}

export function teamUsersKey(teamId: string, params: LoadTeamUsersParams) {
  return md5(
    `${teamId}-${params?.query}-${params?.page}-${params?.per_page}-${
      params.sort
    }-${params.direction}-${params.without_team_unit}-${
      params.team_invites_only
    }-${params.role_filters?.join(',')}`,
  )
}

export const REQUEST = 'TEAM_USERS_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_USERS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: TeamUsersResponse
}
const requestSuccess = (
  key: string,
  data: TeamUsersResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'TEAM_USERS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadTeamUsers(teamId: string, options: LoadTeamUsersParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<TeamUsersResponse>((resolve, reject) => {
      const params = {
        ...options,
        role_filters: options.role_filters?.join(','),
      }
      const key = teamUsersKey(teamId, options)
      dispatch(request(key))

      Api()
        .get<TeamUsersResponse>(`/teams/${teamId}/members`, { params })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling loadTeamUsers (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
