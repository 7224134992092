import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'

import { AssessmentResponses, Personality } from '@dashboard/lib/types'

export const REQUEST = 'SUBMIT_PUBLIC_ASSESSMENT_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'SUBMIT_PUBLIC_ASSESSMENT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  personality: Personality
}
const requestSuccess = (personality: Personality): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  personality,
})

export const REQUEST_FAILURE = 'SUBMIT_PUBLIC_ASSESSMENT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const create = (responses: AssessmentResponses) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request())

      PublicApi()
        .post('public/assessments', {
          responses: responses,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling assessments.public.create (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
