import * as notificationActions from '@dashboard/store/actions/notification'
import { LoadingStates } from '@dashboard/lib/types'

export interface State {
  text: React.ReactNode | null
  showCredits: boolean
  loadingState: LoadingStates
}

const INITIAL_STATE: State = {
  text: null,
  showCredits: false,
  loadingState: 'default',
}

export default function notificationReducer(
  state: State = INITIAL_STATE,
  action: notificationActions.Action,
): State {
  switch (action.type) {
    case notificationActions.LOADING:
      return { ...state, loadingState: 'loading' }
    case notificationActions.SUCCESS:
      return {
        ...state,
        text: action.text,
        showCredits: action.showCredits,
        loadingState: 'success',
      }
    case notificationActions.CLEAR:
      return {
        text: null,
        showCredits: false,
        loadingState: 'default',
      }

    default:
      return state
  }
}
