import nextCookie from 'next-cookies'
import Router from 'next/router'

import { AuthedHeaders } from '@dashboard/lib/api'
import {
  CRYSTAL_AUTH_TOKEN,
  CRYSTAL_SESSION_TOKEN,
} from '@dashboard/lib/constants'
import { appName } from './user'
import { NextPageContext } from 'next'

export function getAuthedHeaders(
  ctx: NextPageContext,
): AuthedHeaders | undefined {
  const { req } = ctx
  const {
    [CRYSTAL_AUTH_TOKEN]: authToken,
    [CRYSTAL_SESSION_TOKEN]: sessionToken,
  } = nextCookie(ctx)

  if (!authToken || !sessionToken) return undefined

  return {
    authToken,
    sessionToken,
    appName: appName({ ua: req }),
  }
}

export function redirect(
  ctx: NextPageContext,
  href: string,
  as?: string,
  retainQueryParams = true,
  isPermanentRedirect = false,
) {
  let params = ''

  if (retainQueryParams) {
    const path = ctx.asPath || ''
    const match = path.match(/\?(.*)/)
    params = match && match[1] ? `?${match[1]}` : ''
  }

  if (ctx.res) {
    ctx.res.writeHead(isPermanentRedirect ? 301 : 302, {
      Location: `${as || href}${params}`,
    })
    ctx.res.end()
    return true
  } else {
    if (as) Router.push(href, `${as}${params}`)
    else Router.push(`${href}${params}`)
    return true
  }
}
