import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api, AuthedHeaders } from '@dashboard/lib/api'
import { APIResponse, ProfileContent } from '@dashboard/lib/types'

export interface PlaybookRelationshipResponse {
  relationship: ProfileContent
  resources_content: Record<string, ProfileContent | undefined>
}

export const REQUEST = 'PLAYBOOK_DISC_DATA_RELATIONSHIPS_START'
interface RequestAction {
  type: typeof REQUEST
  playbookId: string
}
const request = (playbookId: string): RequestAction => ({
  type: REQUEST,
  playbookId,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_DISC_DATA_RELATIONSHIPS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  playbookId: string
  data: PlaybookRelationshipResponse
}
const requestSuccess = (
  playbookId: string,
  data: PlaybookRelationshipResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  playbookId,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_DISC_DATA_RELATIONSHIPS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  playbookId: string
  error: unknown
}
const requestFailure = (
  playbookId: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  playbookId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function fetchPlaybookRelationshipData(
  playbookId: string,
  headers?: AuthedHeaders,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<PlaybookRelationshipResponse>((resolve, reject) => {
      dispatch(request(playbookId))

      Api(headers)
        .get<APIResponse<PlaybookRelationshipResponse>>(
          `/playbooks/${playbookId}/relationship`,
        )
        .then(({ data }) => {
          dispatch(requestSuccess(playbookId, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(playbookId, err))
          reject(
            `Error calling fetchPlaybookRelationshipData (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
