import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIPagedResponse, TeamUser } from '@dashboard/lib/types'
import md5 from 'md5'

export interface LoadTeamUnitMembersParams {
  page: number
  query?: string
  per_page?: number
  sort?: 'name' | 'email'
  direction?: 'asc' | 'desc'
}

export function teamUnitMembersKey(
  unitId: string,
  params: LoadTeamUnitMembersParams,
) {
  return md5(
    `${unitId}-${params?.query}-${params?.page}-${params?.per_page}-${params.sort}-${params.direction}`,
  )
}

export const REQUEST = 'TEAM_UNIT_MEMBERS_INDEX_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'TEAM_UNIT_MEMBERS_INDEX_SUCCESS'
const requestSuccess = (key: string, data: APIPagedResponse<TeamUser>) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'TEAM_UNIT_MEMBERS_INDEX_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function loadTeamUnitMembers(
  unitId: string,
  params: LoadTeamUnitMembersParams,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<TeamUser>>((resolve, reject) => {
      const key = teamUnitMembersKey(unitId, params)
      dispatch(request(key))

      Api()
        .get<APIPagedResponse<TeamUser>>(`/team_units/${unitId}/members`, {
          params,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling loadTeamUnitMembers (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
