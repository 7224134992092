export const REQUEST = 'DISC_DATA_PITCHES_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'DISC_DATA_PITCHES_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'DISC_DATA_PITCHES_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
