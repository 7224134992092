import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface PaymentSource {
  brand: string
  zipcode: string | null
  country: string
  exp_month: number
  exp_year: number
  last_four_digits: string
}

export const REQUEST = 'TEAM_GET_PAYMENT_SOURCE_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'TEAM_GET_PAYMENT_SOURCE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  paymentSources: PaymentSource[]
}
const requestSuccess = (
  paymentSources: PaymentSource[],
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  paymentSources,
})

export const REQUEST_FAILURE = 'TEAM_GET_PAYMENT_SOURCE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getPaymentSources(teamId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request())

      Api()
        .get<APIResponse<PaymentSource[]>>(`/teams/${teamId}/payment_sources`)
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling getPaymentSources (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
