import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIPagedResponse, Tag } from '@dashboard/lib/types'

export type ProfileTagParams = { page?: number; per_page?: number }

export function profileTagsKey(options: ProfileTagParams = {}) {
  return `${options.page || 'default'}-${options.per_page || 'default'}`
}

export const REQUEST = 'PROFILE_TAGS_INDEX_START'
type requestAction = { type: typeof REQUEST; key: string }
const request = (key: string): requestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PROFILE_TAGS_INDEX_SUCCESS'
type requestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<Tag>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<Tag>,
): requestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PROFILE_TAGS_INDEX_FAILURE'
type requestFailureAction = { type: typeof REQUEST_FAILURE; key: string }
const requestFailure = (key: string): requestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = requestAction | requestSuccessAction | requestFailureAction

export function getTags(params: ProfileTagParams = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      const key = profileTagsKey(params)

      dispatch(request(key))

      Api()
        .get<APIPagedResponse<Tag>>('/tags', { params })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))
          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(key))
          reject(
            `Error calling getTags (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
