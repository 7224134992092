import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import discData, { State as DiscDataState } from './reducers/discData'
import url, { State as URLState } from './reducers/url'
import profile, { State as ProfileState } from './reducers/profile'
import celebrities, { State as CelebrityState } from './reducers/celebrities'
import job_titles, { State as JobTitlesState } from './reducers/job_titles'
import analysis, { State as AnalysisState } from './reducers/analysis'
import user, { State as UserState } from './reducers/user'
import assessments, { State as AssessmentsState } from './reducers/assessments'
import company, { State as CompanyState } from './reducers/company'
import partner, { State as PartnerState } from './reducers/partner'
import image, { State as ImageState } from './reducers/image'
import invite, { State as InviteState } from './reducers/invite'
import team, { State as TeamState } from './reducers/team'
import group, { State as GroupState } from './reducers/group'
import playbook, { State as PlaybookState } from './reducers/playbook'
import jobs, { State as JobsState } from './reducers/jobs'
import coach, { State as CoachState } from './reducers/coach'
import groups, { State as GroupsState } from './reducers/groups'
import enrichedProfiles, {
  State as EnrichedProfilesState,
} from './reducers/enrichedProfiles'
import adaptiveMessaging, {
  State as AdaptiveMessagingState,
} from './reducers/adaptiveMessaging'

import notification, {
  State as NotificationState,
} from './reducers/notification'

import thunk from 'redux-thunk'
import { USE_DEVTOOLS_EXTENSION } from '@dashboard/lib/constants'

export interface RootState {
  discData: DiscDataState
  url: URLState
  profile: ProfileState
  celebrities: CelebrityState
  job_titles: JobTitlesState
  user: UserState
  assessments: AssessmentsState
  company: CompanyState
  partner: PartnerState
  image: ImageState
  invite: InviteState
  team: TeamState
  group: GroupState
  notification: NotificationState
  playbook: PlaybookState
  jobs: JobsState
  analysis: AnalysisState
  coach: CoachState
  groups: GroupsState
  adaptiveMessaging: AdaptiveMessagingState
  enrichedProfiles: EnrichedProfilesState
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    USE_DEVTOOLS_EXTENSION &&
    (
      window as unknown as Window & {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R
      }
    ).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export function initializeStore(initialState?: RootState) {
  return createStore(
    combineReducers<RootState>({
      discData,
      url,
      profile,
      celebrities,
      job_titles,
      user,
      assessments,
      company,
      partner,
      image,
      invite,
      team,
      group,
      notification,
      playbook,
      jobs,
      analysis,
      coach,
      groups,
      adaptiveMessaging,
      enrichedProfiles,
    }),
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  )
}

export type AppStore = ReturnType<typeof initializeStore>
