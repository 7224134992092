import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { FullAuthUser, APIResponse } from '@dashboard/lib/types'

export interface UpdateUserParams {
  first_name?: string
  last_name?: string
  gender?: string | null
  job_title?: string | null
  role?: string | null
  linkedin_url?: string
  photo_url?: string
  vanity_url?: string | null
  password?: string
  two_factor_enabled?: boolean
  company_website?: string
}

export interface UserTrackingProperties {
  country?: string
}

interface EndpointParams {
  user: UpdateUserParams
  tracking_properties?: UserTrackingProperties
}

export const REQUEST = 'UPDATE_USER_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'UPDATE_USER_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'UPDATE_USER_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateUser(
  user: UpdateUserParams,
  tracking_properties?: UserTrackingProperties,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      const params: EndpointParams = {
        user,
      }

      if (tracking_properties) params.tracking_properties = tracking_properties

      Api()
        .put<APIResponse<{ user: FullAuthUser }>>('/me', params)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling updateUser (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
