import * as deleteReferralActions from '@dashboard/store/actions/partner/delete'
import * as getActions from '@dashboard/store/actions/partner/get'
import * as remindReferralActions from '@dashboard/store/actions/partner/reminder'

import { LoadingStates } from '@dashboard/lib/types'

export interface State {
  deleteStates: Record<string, LoadingStates | undefined>
  getResults: Record<string, getActions.PartnersResponse | undefined>
  getStates: Record<string, LoadingStates | undefined>
  remindStates: Record<string, LoadingStates | undefined>
}

const INITIAL_STATE: State = {
  deleteStates: {},
  getResults: {},
  getStates: {},
  remindStates: {},
}

type Action =
  | deleteReferralActions.Action
  | getActions.Action
  | remindReferralActions.Action

export default function partnerReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case getActions.REQUEST:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'loading',
        },
      }
    case getActions.REQUEST_SUCCESS:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'success',
        },
        getResults: {
          ...state.getResults,
          [action.query]: action.data,
        },
      }
    case getActions.REQUEST_FAILURE:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'failure',
        },
      }

    case deleteReferralActions.REQUEST:
      return {
        ...state,
        deleteStates: {
          ...state.deleteStates,
          [action.referralID]: 'loading',
        },
      }
    case deleteReferralActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteStates: {
          ...state.deleteStates,
          [action.referralID]: 'success',
        },
      }
    case deleteReferralActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteStates: {
          ...state.deleteStates,
          [action.referralID]: 'failure',
        },
      }

    case remindReferralActions.REQUEST:
      return {
        ...state,
        remindStates: {
          ...state.remindStates,
          [action.referralID]: 'loading',
        },
      }
    case remindReferralActions.REQUEST_SUCCESS:
      return {
        ...state,
        remindStates: {
          ...state.remindStates,
          [action.referralID]: 'success',
        },
      }
    case remindReferralActions.REQUEST_FAILURE:
      return {
        ...state,
        remindStates: {
          ...state.remindStates,
          [action.referralID]: 'failure',
        },
      }

    default:
      return state
  }
}
