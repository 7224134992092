import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'

import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface GroupDepartment {
  id: string
  text: string
}

export const REQUEST = 'GROUPS_DEPARTMENTS_GET_START'
const request = () =>
  ({
    type: REQUEST,
  }) as const

export const REQUEST_SUCCESS = 'GROUPS_DEPARTMENTS_GET_SUCCESS'
const requestSuccess = (data: GroupDepartment[]) =>
  ({
    type: REQUEST_SUCCESS,
    data,
  }) as const

export const REQUEST_FAILURE = 'GROUPS_DEPARTMENTS_GET_FAILURE'
const requestFailure = (error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function getGroupDepartments() {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .get<APIResponse<GroupDepartment[]>>('/group_departments')
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))
          resolve()
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(err))
          reject(
            `Error calling getGroupDepartments (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
