import { ProfileContent } from '@dashboard/lib/types'

export const REQUEST = 'PROFILE_RELATIONSHIP_START'
interface RequestAction {
  type: typeof REQUEST
  profileID: string
}

export const REQUEST_SUCCESS = 'PROFILE_RELATIONSHIP_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profileID: string
  content: ProfileContent
}

export const REQUEST_FAILURE = 'PROFILE_RELATIONSHIP_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  profileID: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
