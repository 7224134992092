import { Datum, ProfileContent } from '@dashboard/lib/types'

export interface GroupAnalysisContentResponse {
  content: Record<
    string,
    {
      archetype: Datum[]
      strength: Datum[]
    }
  >
  relationships: Record<string, ProfileContent | undefined>
}

export const REQUEST = 'GROUP_ANALYSIS_GROUP_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}

export const REQUEST_SUCCESS = 'GROUP_ANALYSIS_GROUP_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: GroupAnalysisContentResponse
}

export const REQUEST_FAILURE = 'GROUP_ANALYSIS_GROUP_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
