import * as groupAnalysisContentActions from '@dashboard/store/actions/group/content'
import { LoadingStates } from '@dashboard/lib/types'

export interface State {
  groupAnalysisContentStates: Record<string, LoadingStates | undefined>
  groupAnalysisContentResults: Record<
    string,
    groupAnalysisContentActions.GroupAnalysisContentResponse | undefined
  >
}

const INITIAL_STATE: State = {
  groupAnalysisContentStates: {},
  groupAnalysisContentResults: {},
}

type Action = groupAnalysisContentActions.Action

export default function groupReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case groupAnalysisContentActions.REQUEST:
      return {
        ...state,
        groupAnalysisContentStates: {
          ...state.groupAnalysisContentStates,
          [action.key]: 'loading',
        },
      }

    case groupAnalysisContentActions.REQUEST_SUCCESS:
      return {
        ...state,
        groupAnalysisContentStates: {
          ...state.groupAnalysisContentStates,
          [action.key]: 'success',
        },
        groupAnalysisContentResults: {
          ...state.groupAnalysisContentResults,
          [action.key]: action.data,
        },
      }

    case groupAnalysisContentActions.REQUEST_FAILURE:
      return {
        ...state,
        groupAnalysisContentStates: {
          ...state.groupAnalysisContentStates,
          [action.key]: 'failure',
        },
      }

    default:
      return state
  }
}
