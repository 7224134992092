import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'
import {
  APIResponse,
  LegacyCreateCandidateResponse,
} from '@dashboard/lib/types'

export interface CandidateProfileLegacyAttributes {
  first_name: string
  last_name: string
  email: string
  team_id: string
  personality: {
    degrees: number
    intensity: number
  }
  profile_id: string
  job_id: string
}

export const REQUEST = 'PUBLIC_PROFILE_CREATE_LEGACY_JOB_CANDIDATE_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS =
  'PUBLIC_PROFILE_CREATE_LEGACY_JOB_CANDIDATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: LegacyCreateCandidateResponse
}
const requestSuccess = (
  key: string,
  data: LegacyCreateCandidateResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE =
  'PUBLIC_PROFILE_CREATE_LEGACY_JOB_CANDIDATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const createLegacyJobAssessmentProfile = (
  attributes: CandidateProfileLegacyAttributes,
) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIResponse<LegacyCreateCandidateResponse>>(
      (resolve, reject) => {
        dispatch(request(attributes.email))

        PublicApi()
          .post<APIResponse<LegacyCreateCandidateResponse>>(
            'public/job_candidates/legacy',
            attributes,
          )
          .then(({ data }) => {
            dispatch(requestSuccess(attributes.email, data.data))

            resolve(data)
          })
          .catch(err => {
            dispatch(requestFailure(attributes.email))
            reject(
              `Error calling createLegaccyJobAssessmentProfile (Status: ${err
                .response?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      },
    )
  }
}
