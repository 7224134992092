import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { Profile, AssessmentTypes } from '@dashboard/lib/types'

export const REQUEST = 'ASSESSMENT_ACCURACY_START'
interface RequestAction {
  type: typeof REQUEST
  scoreType: string
}
const request = (scoreType: string): RequestAction => ({
  type: REQUEST,
  scoreType,
})

export const REQUEST_SUCCESS = 'ASSESSMENT_ACCURACY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profile: Profile
  scoreType: string
  scores: Record<string, number | null>
}
const requestSuccess = (
  profile: Profile,
  scoreType: string,
  scores: Record<string, number | null>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  profile,
  scoreType,
  scores,
})

export const REQUEST_FAILURE = 'ASSESSMENT_ACCURACY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  scoreType: string
  error: unknown
}
const requestFailure = (
  scoreType: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  scoreType,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function submitAssessmentAccuracy(
  profile: Profile,
  type: AssessmentTypes,
  score: number,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(type))

      const accuracies = profile.accuracies
      const scores: Record<string, number | null> = {
        disc: accuracies ? accuracies.disc : null,
        myers_briggs: accuracies ? accuracies.myers_briggs : null,
        enneagram: accuracies ? accuracies.enneagram : null,
        strength: accuracies ? accuracies.strength : null,
      }

      scores[type] = score

      Api()
        .post(`/profiles/${profile.id}/accuracies`, { scores })
        .then(() => {
          dispatch(requestSuccess(profile, type, scores))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(type, err))
          reject(
            `Error calling submitAssessmentAccuracy (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
