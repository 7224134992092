import * as createImageActions from '@dashboard/store/actions/images/create'
import * as createFullPageScreenshotActions from '@dashboard/store/actions/images/full_page_screenshot'

import { KeyedStateHelper, LoadingStates } from '@dashboard/lib/types'

export interface State {
  createImageStates: KeyedStateHelper<LoadingStates>
  createImageResults: KeyedStateHelper<string>
  createFullPageScreenshotStates: KeyedStateHelper<LoadingStates>
  createFullPageScreenshotResults: KeyedStateHelper<string>
}

const INITIAL_STATE: State = {
  createImageStates: {},
  createImageResults: {},
  createFullPageScreenshotStates: {},
  createFullPageScreenshotResults: {},
}

type Action = createImageActions.Action | createFullPageScreenshotActions.Action

export default function imageReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case createImageActions.REQUEST:
      return {
        ...state,
        createImageStates: {
          ...state.createImageStates,
          [action.key]: 'loading',
        },
      }

    case createImageActions.REQUEST_SUCCESS:
      return {
        ...state,
        createImageStates: {
          ...state.createImageStates,
          [action.key]: 'success',
        },
        createImageResults: {
          ...state.createImageResults,
          [action.key]: action.imageURL,
        },
      }

    case createImageActions.REQUEST_FAILURE:
      return {
        ...state,
        createImageStates: {
          ...state.createImageStates,
          [action.key]: 'failure',
        },
      }

    case createFullPageScreenshotActions.REQUEST:
      return {
        ...state,
        createFullPageScreenshotStates: {
          ...state.createFullPageScreenshotStates,
          [action.key]: 'loading',
        },
      }

    case createFullPageScreenshotActions.REQUEST_SUCCESS:
      return {
        ...state,
        createFullPageScreenshotStates: {
          ...state.createFullPageScreenshotStates,
          [action.key]: 'success',
        },
        createFullPageScreenshotResults: {
          ...state.createFullPageScreenshotResults,
          [action.key]: action.data,
        },
      }

    case createFullPageScreenshotActions.REQUEST_FAILURE:
      return {
        ...state,
        createFullPageScreenshotStates: {
          ...state.createFullPageScreenshotStates,
          [action.key]: 'failure',
        },
      }

    default:
      return state
  }
}
