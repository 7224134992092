export const REQUEST = 'DELETE_NOTIFICATIONS_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS'
type RequestSuccessAction = { type: typeof REQUEST_SUCCESS }

export const REQUEST_FAILURE = 'DELETE_NOTIFICATIONS_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
