import * as GetCelebrityActions from '@dashboard/store/actions/celebrities/public'
import * as ShowCelebrityActions from '@dashboard/store/actions/celebrities/public/show'
import { LoadingStates, Profile, APIPagedResponse } from '@dashboard/lib/types'

export interface State {
  getCelebrityStates: Record<string, LoadingStates | undefined>
  getCelebrityResults: Record<string, APIPagedResponse<Profile> | undefined>
  celebrityState: LoadingStates
  celebrity: Profile | null
}

const INITIAL_STATE: State = {
  getCelebrityStates: {},
  getCelebrityResults: {},
  celebrityState: 'default',
  celebrity: null,
}

type Action = GetCelebrityActions.Action | ShowCelebrityActions.Action

export default function celebritiesReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Index
    case GetCelebrityActions.REQUEST:
      return {
        ...state,
        getCelebrityStates: {
          ...state.getCelebrityStates,
          [action.key]: 'loading',
        },
      }
    case GetCelebrityActions.REQUEST_SUCCESS:
      return {
        ...state,
        getCelebrityStates: {
          ...state.getCelebrityStates,
          [action.key]: 'success',
        },
        getCelebrityResults: {
          ...state.getCelebrityResults,
          [action.key]: action.data,
        },
      }
    case GetCelebrityActions.REQUEST_FAILURE:
      return {
        ...state,
        getCelebrityStates: {
          ...state.getCelebrityStates,
          [action.key]: 'failure',
        },
      }

    // show
    case ShowCelebrityActions.REQUEST:
      return {
        ...state,
        celebrityState: 'loading',
      }
    case ShowCelebrityActions.REQUEST_SUCCESS:
      return {
        ...state,
        celebrityState: 'success',
        celebrity: action.data,
      }
    case ShowCelebrityActions.REQUEST_FAILURE:
      return {
        ...state,
        celebrityState: 'failure',
      }

    default:
      return state
  }
}
