export const REQUEST = 'PASSWORD_CHANGE_LOAD_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'PASSWORD_CHANGE_LOAD_SUCCESS'
type RequestSuccessAction = { type: typeof REQUEST_SUCCESS }

export const REQUEST_FAILURE = 'PASSWORD_CHANGE_LOAD_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
