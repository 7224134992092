export interface UserNotification {
  id: number
  actor: {
    id: string
    first_name: string
    last_name: string
    photo_url: string | null
  }
  type:
    | 'invitation_accepted'
    | 'endorsement_received'
    | 'other'
    | 'approval_received'
    | 'skill_assessment_taken'
}

export const REQUEST = 'GET_NOTIFICATIONS_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  notifications: UserNotification[]
}

export const REQUEST_FAILURE = 'GET_NOTIFICATIONS_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
