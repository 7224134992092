import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import Link from 'next/link'

import Loader from '@crystal-eyes/components/elements/Loader/Loader'
import SuccessCheck from '@dashboard/components/SuccessCheck'
import css from './styles.module.scss'

interface OwnProps {
  href?: string
  to?: string
  as?: string
  target?: string
  onClick?: (e?: React.FormEvent | React.MouseEvent) => void
  onMouseDown?: () => void
  blueOutline?: boolean
  buttonTag?: boolean
  center?: boolean
  disabled?: boolean
  green?: boolean
  highlight?: boolean
  inline?: boolean
  large?: boolean
  loading?: boolean
  margin?: boolean
  noMargin?: boolean
  outline?: boolean
  pill?: boolean
  red?: boolean
  orange?: boolean
  small?: boolean
  success?: boolean
  successMessage?: string
  text?: string
  white?: boolean
  actionBlue?: boolean
  cta?: boolean
  slimCTA?: boolean
  midnight?: boolean
  wide?: boolean
  xWide?: boolean
  id?: string
  openNewTab?: boolean
  thick?: boolean
  color?: string
  children?: React.ReactNode
  type?: string
}

const classNames = classNamesBind.bind(css)

const Button: FC<OwnProps> = ({
  white,
  to,
  as,
  buttonTag,
  disabled,
  green,
  href,
  inline,
  large,
  loading,
  margin,
  noMargin,
  onClick,
  outline,
  pill,
  red,
  orange,
  actionBlue,
  small,
  success,
  target,
  center,
  onMouseDown,
  blueOutline,
  cta,
  slimCTA,
  id,
  openNewTab,
  wide,
  xWide,
  midnight,
  thick,
  color,
  successMessage,
  text,
  children,
}: OwnProps) => {
  const buttonProps: Record<string, unknown> = {}

  if (id !== undefined && id !== null) buttonProps.id = id
  buttonProps.className = classNames('container', {
    white,
    disabled,
    green,
    inline,
    large,
    loading,
    margin,
    'no-margin': noMargin,
    outline,
    pill,
    red,
    orange,
    actionBlue,
    small,
    success,
    center,
    blueOutline,
    cta,
    midnight,
    slimCTA,
    wide,
    xWide,
    thick,
  })

  if (color) buttonProps.style = { background: color }
  if (onClick) buttonProps.onClick = !disabled && !loading ? onClick : null
  if (to) buttonProps.to = to
  if (href) {
    buttonProps.href = href
    if (target) buttonProps.target = target
  }
  if (onMouseDown)
    buttonProps.onMouseDown = !disabled && !loading ? onMouseDown : null
  if (openNewTab) {
    buttonProps.target = '_blank'
    buttonProps.rel = 'noopener noreferrer'
  }

  // Partials
  const renderContent = () => {
    if (loading) return <Loader size={18} />

    if (success) return <SuccessCheck text={successMessage} show={true} />

    if (text) return text

    return children
  }

  // Render
  if (to) {
    return (
      <Link href={to} as={as} {...buttonProps} passHref>
        {renderContent()}
      </Link>
    )
  }

  if (buttonTag) {
    return <button {...buttonProps}>{renderContent()}</button>
  }

  return <a {...buttonProps}>{renderContent()}</a>
}

export default Button
