import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'
import { AxiosError } from 'axios'

export const REQUEST = 'JOB_ADD_CANDIDATES_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'JOB_ADD_CANDIDATES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'JOB_ADD_CANDIDATES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: string
}
const requestFailure = (error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function addCandidates(jobId: string, profile_ids: string[]) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .post<APIResponse<{ id: string }>>(`/jobs/${jobId}/profiles`, {
          profile_ids: profile_ids,
        })
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch((err: AxiosError<{ message: string; status: number }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(errorMessage))
          reject(
            `Error calling addCandidates (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
