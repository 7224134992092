import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import {
  APIPagedResponse,
  PlaybookOrReportResponse,
  PlaybookTemplateType,
  PlaybookTemplateTag,
} from '@dashboard/lib/types'

export const REQUEST = 'PLAYBOOKS_GET_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'PLAYBOOKS_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: string
  data: APIPagedResponse<PlaybookOrReportResponse>
}
const requestSuccess = (
  query: string,
  data: APIPagedResponse<PlaybookOrReportResponse>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOKS_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: string
  error: unknown
}
const requestFailure = (
  query: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface GetPlaybooksOptions {
  page: number
  per_page?: number
  query?: string
  template_types?: PlaybookTemplateType[]
  template_name?: string
  playbook_template_tags?: PlaybookTemplateTag[]
}

export const playbooksQuery = (options: GetPlaybooksOptions) => {
  return JSON.stringify(options)
}

export function getPlaybooks(options: GetPlaybooksOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<PlaybookOrReportResponse>>(
      (resolve, reject) => {
        const queryString = playbooksQuery(options)
        dispatch(request(queryString))

        interface QueryParams
          extends Omit<
            GetPlaybooksOptions,
            'template_types' | 'playbook_template_tags'
          > {
          template_types?: string
          playbook_template_tags?: string
        }

        const params: QueryParams = {
          ...options,
          template_types: options.template_types
            ? options.template_types.join(',')
            : undefined,
          playbook_template_tags: options.playbook_template_tags
            ? options.playbook_template_tags.join(',')
            : undefined,
        }

        Api()
          .get<APIPagedResponse<PlaybookOrReportResponse>>('/playbooks', {
            params,
          })
          .then(({ data }) => {
            dispatch(requestSuccess(queryString, data))
            resolve(data)
          })
          .catch(error => {
            dispatch(requestFailure(queryString, error))
            reject(error.response?.data)
          })
      },
    )
  }
}
