import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { Profile, APIPagedResponse } from '@dashboard/lib/types'

interface CandidateJobList {
  id: string
  name: string
}

export interface CandidatePeopleProfile extends Profile {
  interview_playbook_id: string
  job_names: CandidateJobList[]
}

export const REQUEST = 'CANDIDATES_GET_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'CANDIDATES_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<CandidatePeopleProfile>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<CandidatePeopleProfile>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
  key,
})

export const REQUEST_FAILURE = 'CANDIDATES_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  key: string
}
const requestFailure = (error: unknown, key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
export type SortField =
  | null
  | 'name'
  | 'disc_type'
  | 'myers_briggs'
  | 'enneagram'

interface RouteParams {
  page: number
  query: string
  per_page?: number
  trait_key?: number
  disc_type?: string
  tags?: string
  verified?: boolean
  sort?: SortField
  direction?: null | 'asc' | 'desc'
  order_by_type?:
    | 'api'
    | 'owned'
    | 'unowned'
    | 'candidate'
    | 'assessment'
    | 'celebrity'
}

export interface GetCandidatesOptions {
  page: number
  query?: string
  limit?: number
  trait_key?: number
  disc_type?: string
  tags?: string[]
  sort?: SortField
  direction?: null | 'asc' | 'desc'
  order_by_type?:
    | 'api'
    | 'owned'
    | 'unowned'
    | 'candidate'
    | 'assessment'
    | 'celebrity'
}

export const candidateQuery = (options: GetCandidatesOptions) => {
  return JSON.stringify(options)
}

export function getCandidates(options: GetCandidatesOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<CandidatePeopleProfile>>(
      (resolve, reject) => {
        const key = candidateQuery(options)
        const {
          page,
          query,
          limit,
          trait_key,
          disc_type,
          sort,
          direction,
          order_by_type,
        } = options
        const tags = options.tags || []

        dispatch(request(key))

        const params: RouteParams = {
          page,
          query: '',
          per_page: 20,
        }

        if (query) params.query = query
        if (limit) params.per_page = limit
        if (trait_key) params.trait_key = trait_key
        if (disc_type) params.disc_type = disc_type
        if (tags.length > 0) params.tags = tags.join(',')
        if (sort) params.sort = sort
        if (direction) params.direction = direction
        if (order_by_type) params.order_by_type = order_by_type

        Api()
          .get<APIPagedResponse<CandidatePeopleProfile>>('/candidates', {
            params,
          })
          .then(({ data }) => {
            dispatch(requestSuccess(key, data))

            resolve(data)
          })
          .catch(err => {
            dispatch(requestFailure(err, key))
            reject(
              `Error calling getCandidates (Status: ${err.response
                ?.status}): ${JSON.stringify(err.response?.data)}`,
            )
          })
      },
    )
  }
}
