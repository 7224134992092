import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export interface UpdateWhiteLabelParams {
  logo?: File | null
  intro?: string | null
  logo_url?: string | null
  completion_button_text?: string | null
  completion_button_url?: string | null
  job_survey_button_text?: string | null
  job_survey_button_url?: string | null
  show_results?: boolean
}

export const REQUEST = 'TEAM_UPDATE_WHITE_LABEL_ASSESSMENTS_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'TEAM_UPDATE_WHITE_LABEL_ASSESSMENTS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'TEAM_UPDATE_WHITE_LABEL_ASSESSMENTS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: { response: { data: { message: string } } }
}
const requestFailure = (error: {
  response: { data: { message: string } }
}): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function updateWhiteLabelAssessments(
  teamId: string,
  updateParams: UpdateWhiteLabelParams,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      const {
        logo,
        intro,
        completion_button_text,
        completion_button_url,
        job_survey_button_text,
        job_survey_button_url,
        logo_url,
      } = updateParams

      let payload: FormData | UpdateWhiteLabelParams

      if (logo) {
        payload = new FormData()
        payload.append('logo', logo)
        if (typeof intro == 'string') payload.append('intro', intro)
        if (completion_button_text)
          payload.append('completion_button_text', completion_button_text)
        if (completion_button_url)
          payload.append('completion_button_url', completion_button_url)
        if (job_survey_button_text)
          payload.append('job_survey_button_text', job_survey_button_text)
        if (job_survey_button_url)
          payload.append('job_survey_button_url', job_survey_button_url)
        if (logo_url) payload.append('logo_url', logo_url)
      } else payload = updateParams
      Api()
        .put(`/teams/${teamId}/white_label_assessments`, payload)
        .then(() => {
          dispatch(requestSuccess())

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling updateWhiteLabelAssessments (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
