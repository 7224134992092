import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { PublicApi } from '@dashboard/lib/api'
import {
  APIResponse,
  ProfileContent,
  DiscDataCircumplexVector,
} from '@dashboard/lib/types'

export interface PublicDiscDataOptions {
  limit?: number
}

export function publicDiscDataKey(
  circumplexes: DiscDataCircumplexVector[],
  types: string[][],
  options: PublicDiscDataOptions = {},
) {
  return `${circumplexes
    .map(c => `${c.degrees}-${c.intensity}`)
    .join('-')}-${types.join('-')}=${JSON.stringify(options)}`
}

export const REQUEST = 'PROFILE_PUBLIC_DATA_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PROFILE_PUBLIC_DATA_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: ProfileContent
}
const requestSuccess = (
  key: string,
  data: ProfileContent,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PROFILE_PUBLIC_DATA_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getPublicDiscDataRequest(
  ...args: Parameters<typeof getPublicDiscData>
) {
  const [circumplexes, types, options] = args
  const dataTags: Record<string, string[]> = {}
  types.forEach((types, index) => {
    dataTags[index] = types
  })

  return PublicApi().post<APIResponse<ProfileContent>>('public/data', {
    circumplexes,
    data_tags: dataTags,
    ...options,
  })
}

export function getPublicDiscData(
  circumplexes: DiscDataCircumplexVector[],
  types: string[][],
  options: PublicDiscDataOptions = {},
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<ProfileContent>((resolve, reject) => {
      const key = publicDiscDataKey(circumplexes, types, options)
      dispatch(request(key))

      getPublicDiscDataRequest(circumplexes, types, options)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling getPublicDiscData (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
