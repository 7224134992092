import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'
import { AxiosError } from 'axios'

export function activateCandidateKey(jobId: string, profileId: string) {
  return `${jobId}-${profileId}`
}

export const REQUEST = 'JOB_ACTIVATE_CANDIDATE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'JOB_ACTIVATE_CANDIDATE_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'JOB_ACTIVATE_CANDIDATE_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function activateCandidate(jobId: string, profileId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = activateCandidateKey(jobId, profileId)

      dispatch(request(key))

      Api()
        .put<APIResponse<{ id: string }>>(
          `/jobs/${jobId}/profiles/${profileId}/activate`,
        )
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch((err: AxiosError<{ message: string; status: number }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling activateCandidate (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
