import * as indexActions from '@dashboard/store/actions/playbooks/index'
import * as updateActions from '@dashboard/store/actions/playbooks/update'
import * as showActions from '@dashboard/store/actions/playbooks/show'
import * as profileDataActions from '@dashboard/store/actions/playbooks/discData/profile/data'
import * as templateActions from '@dashboard/store/actions/playbooks/templates'
import * as groupsActions from '@dashboard/store/actions/playbooks/discData/group'
import * as archetypesActions from '@dashboard/store/actions/playbooks/discData/archetypes'
import * as discRelationshipActions from '@dashboard/store/actions/playbooks/discData/relationship'
import * as dataActions from '@dashboard/store/actions/playbooks/discData/data'
import * as findActions from '@dashboard/store/actions/playbooks/find'
import * as structuresActions from '@dashboard/store/actions/playbooks/structures'
import * as createTemplateActions from '@dashboard/store/actions/playbooks/templates/create'
import * as sampleActions from '@dashboard/store/actions/playbooks/sample'
import * as matrixActions from '@dashboard/store/actions/playbooks/discData/matrix'
import * as superlativesActions from '@dashboard/store/actions/playbooks/discData/superlatives'
import * as deleteCustomTemplateActions from '@dashboard/store/actions/playbooks/templates/destroy'
import * as deletePlaybookActions from '@dashboard/store/actions/playbooks/destroy'
import * as addPlaybookProfilesActions from '@dashboard/store/actions/playbooks/addProfiles'
import * as removePlaybookProfilesActions from '@dashboard/store/actions/playbooks/removeProfiles'
import * as communicationAdviceActions from '@dashboard/store/actions/playbooks/discData/communicationAdvice'
import * as interviewQuestions from '@dashboard/store/actions/playbooks/discData/interviewQuestions'
import * as templateShowActions from '@dashboard/store/actions/playbooks/templates/show'
import * as getMeetingsActions from '@dashboard/store/actions/playbooks/meetings'
import * as getInterviewsActions from '@dashboard/store/actions/playbooks/interviews'
import * as getHiringTemplatesActions from '@dashboard/store/actions/playbooks/templates/hiring'
import * as getLeadershipTemplatesActions from '@dashboard/store/actions/playbooks/templates/leadership'
import * as getSalesTemplatesActions from '@dashboard/store/actions/playbooks/templates/sales'
import * as createLeadershipActions from '@dashboard/store/actions/playbooks/leadership/create'
import * as createNoteActions from '@dashboard/store/actions/playbooks/notes/create'
import * as updateNoteActions from '@dashboard/store/actions/playbooks/notes/update'
import * as deleteNoteActions from '@dashboard/store/actions/playbooks/notes/destroy'
import * as createSalesActions from '@dashboard/store/actions/playbooks/sales/create'
import * as createPlaybookActions from '@dashboard/store/actions/playbooks/create'
import type { PlaybookResponse } from '@dashboard/lib/types'

import {
  LoadingStates,
  PlaybookTemplateObject,
  APIPagedResponse,
  PlaybookOrReportResponse,
  DiscType,
  ProfileContent,
  SuperlativeResponse,
  Datum,
  KeyedStateHelper,
  PlaybookNote,
  Profile,
} from '@dashboard/lib/types'
import { StructuresResponse } from '@dashboard/store/actions/playbooks/structures'
import { PlaybookGroupDataResponse } from '@dashboard/store/actions/playbooks/discData/group'
import { MatrixResponse } from '@dashboard/store/actions/playbooks/discData/matrix'

export interface State {
  getStates: Record<string, LoadingStates | undefined>
  getResults: Record<
    string,
    APIPagedResponse<PlaybookOrReportResponse> | undefined
  >
  showStates: Record<string, LoadingStates | undefined>
  showResults: Record<string, PlaybookResponse | undefined>
  relationshipStates: Record<string, LoadingStates | undefined>
  relationshipData: Record<
    string,
    discRelationshipActions.PlaybookRelationshipResponse | undefined
  >
  dataStates: Record<string, LoadingStates | undefined>
  data: Record<string, ProfileContent | undefined>
  templatesStates: KeyedStateHelper<LoadingStates>
  templatesResults: KeyedStateHelper<APIPagedResponse<PlaybookTemplateObject>>
  groupsStates: Record<string, LoadingStates | undefined>
  groupsData: Record<string, PlaybookGroupDataResponse | undefined>
  archetypesStates: Record<string, LoadingStates | undefined>
  archetypesResults: Record<
    string,
    Partial<Record<DiscType, archetypesActions.DiscArchetype>> | undefined
  >
  updatePlaybookStates: Record<string, LoadingStates | undefined>
  profileData: Record<string, ProfileContent | undefined>
  profileDataStates: Record<string, LoadingStates | undefined>
  findResults: Record<string, string | undefined>
  findStates: Record<string, LoadingStates | undefined>
  structuresState: LoadingStates
  structuresResult: StructuresResponse | null
  createTemplateStates: Record<string, LoadingStates | undefined>
  createTemplateResults: Record<string, string | undefined>
  createTemplateErrors: KeyedStateHelper<{
    errorMessage: string
    status: number
  }>
  sampleStates: Record<string, LoadingStates | undefined>
  sampleResults: Record<string, PlaybookResponse | undefined>
  matrixStates: Record<string, LoadingStates | undefined>
  matrixResults: Record<string, MatrixResponse | undefined>
  matrixErrors: Record<string, string | undefined>
  superlativesStates: Record<string, LoadingStates | undefined>
  superlativesResults: Record<string, SuperlativeResponse | undefined>
  superlativesErrors: Record<string, string | undefined>
  destroyTemplateStates: Record<string, LoadingStates | undefined>
  destroyTemplateErrorMessages: Record<string, string | undefined>
  destroyPlaybookStates: Record<string, LoadingStates | undefined>
  addProfilesStates: Record<string, LoadingStates | undefined>
  removeProfilesStates: Record<string, LoadingStates | undefined>
  adviceStates: Record<string, LoadingStates | undefined>
  adviceResults: Record<
    string,
    communicationAdviceActions.PlaybookCommunicationAdviceResponse | undefined
  >
  interviewQuestionsStates: Record<string, LoadingStates | undefined>
  interviewQuestionsResults: Record<string, Datum[] | undefined>
  templateShowStates: KeyedStateHelper<LoadingStates>
  templateShowResults: KeyedStateHelper<PlaybookTemplateObject>
  meetingsStates: KeyedStateHelper<LoadingStates>
  meetings: KeyedStateHelper<APIPagedResponse<PlaybookResponse>>
  interviewsStates: KeyedStateHelper<LoadingStates>
  interviews: KeyedStateHelper<APIPagedResponse<PlaybookResponse>>
  hiringTemplatesStates: KeyedStateHelper<LoadingStates>
  hiringTemplatesResults: KeyedStateHelper<
    APIPagedResponse<PlaybookTemplateObject>
  >
  leadershipTemplatesStates: KeyedStateHelper<LoadingStates>
  leadershipTemplatesResults: KeyedStateHelper<
    APIPagedResponse<PlaybookTemplateObject>
  >
  salesTemplatesStates: KeyedStateHelper<LoadingStates>
  salesTemplatesResults: KeyedStateHelper<
    APIPagedResponse<PlaybookTemplateObject>
  >
  createLeadershipStates: KeyedStateHelper<LoadingStates>
  createLeadershipResults: KeyedStateHelper<string>
  createLeadershipError: KeyedStateHelper<string>
  createLeadershipErrorProfiles: KeyedStateHelper<Profile[]>
  getNotesStates: KeyedStateHelper<LoadingStates>
  getNotesResults: KeyedStateHelper<PlaybookNote[]>
  createNoteStates: KeyedStateHelper<LoadingStates>
  updateNoteStates: KeyedStateHelper<LoadingStates>
  deleteNoteStates: KeyedStateHelper<LoadingStates>
  createSalesStates: KeyedStateHelper<LoadingStates>
  createSalesResults: KeyedStateHelper<string>
  createSalesError: KeyedStateHelper<string>
  createSalesErrorProfiles: KeyedStateHelper<Profile[]>
  createPlaybookStates: KeyedStateHelper<LoadingStates>
  createPlaybookResults: KeyedStateHelper<string>
  createPlaybookErrors: KeyedStateHelper<string>
}

const INITIAL_STATE: State = {
  getStates: {},
  getResults: {},
  showStates: {},
  showResults: {},
  relationshipStates: {},
  relationshipData: {},
  dataStates: {},
  data: {},
  templatesStates: {},
  templatesResults: {},
  groupsStates: {},
  groupsData: {},
  archetypesStates: {},
  archetypesResults: {},
  updatePlaybookStates: {},
  profileData: {},
  profileDataStates: {},
  findResults: {},
  findStates: {},
  structuresState: 'default',
  structuresResult: null,
  createTemplateStates: {},
  createTemplateResults: {},
  createTemplateErrors: {},
  sampleStates: {},
  sampleResults: {},
  matrixStates: {},
  matrixResults: {},
  matrixErrors: {},
  superlativesStates: {},
  superlativesResults: {},
  superlativesErrors: {},
  destroyTemplateStates: {},
  destroyTemplateErrorMessages: {},
  destroyPlaybookStates: {},
  addProfilesStates: {},
  removeProfilesStates: {},
  adviceStates: {},
  adviceResults: {},
  interviewQuestionsStates: {},
  interviewQuestionsResults: {},
  templateShowStates: {},
  templateShowResults: {},
  meetingsStates: {},
  meetings: {},
  interviewsStates: {},
  interviews: {},
  hiringTemplatesStates: {},
  hiringTemplatesResults: {},
  leadershipTemplatesStates: {},
  leadershipTemplatesResults: {},
  salesTemplatesStates: {},
  salesTemplatesResults: {},
  createLeadershipStates: {},
  createLeadershipResults: {},
  createLeadershipError: {},
  createLeadershipErrorProfiles: {},
  getNotesStates: {},
  getNotesResults: {},
  createNoteStates: {},
  updateNoteStates: {},
  deleteNoteStates: {},
  createSalesStates: {},
  createSalesResults: {},
  createSalesError: {},
  createSalesErrorProfiles: {},
  createPlaybookStates: {},
  createPlaybookResults: {},
  createPlaybookErrors: {},
}

type Action =
  | indexActions.Action
  | showActions.Action
  | discRelationshipActions.Action
  | dataActions.Action
  | templateActions.Action
  | groupsActions.Action
  | archetypesActions.Action
  | updateActions.Action
  | profileDataActions.Action
  | findActions.Action
  | structuresActions.Action
  | createTemplateActions.Action
  | sampleActions.Action
  | matrixActions.Action
  | superlativesActions.Action
  | deleteCustomTemplateActions.Action
  | deletePlaybookActions.Action
  | addPlaybookProfilesActions.Action
  | removePlaybookProfilesActions.Action
  | communicationAdviceActions.Action
  | interviewQuestions.Action
  | templateShowActions.Action
  | getMeetingsActions.Action
  | getInterviewsActions.Action
  | getHiringTemplatesActions.Action
  | getLeadershipTemplatesActions.Action
  | getSalesTemplatesActions.Action
  | createLeadershipActions.Action
  | createNoteActions.Action
  | updateNoteActions.Action
  | deleteNoteActions.Action
  | createSalesActions.Action
  | createPlaybookActions.Action

export default function playbookReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    case indexActions.REQUEST:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'loading',
        },
      }
    case indexActions.REQUEST_SUCCESS:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'success',
        },
        getResults: {
          ...state.getResults,
          [action.query]: action.data,
        },
      }
    case indexActions.REQUEST_FAILURE:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'failure',
        },
      }

    case showActions.REQUEST:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.playbookId]: 'loading',
        },
      }
    case showActions.REQUEST_SUCCESS:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.playbookId]: 'success',
        },
        showResults: {
          ...state.showResults,
          [action.playbookId]: action.data,
        },
      }
    case showActions.REQUEST_FAILURE:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.playbookId]: 'failure',
        },
      }

    case discRelationshipActions.REQUEST:
      return {
        ...state,
        relationshipStates: {
          ...state.relationshipStates,
          [action.playbookId]: 'loading',
        },
      }
    case discRelationshipActions.REQUEST_SUCCESS:
      return {
        ...state,
        relationshipStates: {
          ...state.relationshipStates,
          [action.playbookId]: 'success',
        },
        relationshipData: {
          ...state.relationshipData,
          [action.playbookId]: action.data,
        },
      }
    case discRelationshipActions.REQUEST_FAILURE:
      return {
        ...state,
        relationshipStates: {
          ...state.relationshipStates,
          [action.playbookId]: 'failure',
        },
      }

    case dataActions.REQUEST:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'loading',
        },
      }
    case dataActions.REQUEST_SUCCESS:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'success',
        },
        data: {
          ...state.data,
          [action.key]: action.data,
        },
      }
    case dataActions.REQUEST_FAILURE:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'failure',
        },
      }

    // Get Templates
    case templateActions.REQUEST:
      return {
        ...state,
        templatesStates: {
          ...state.templatesStates,
          [action.key]: 'loading',
        },
      }
    case templateActions.REQUEST_SUCCESS:
      return {
        ...state,
        templatesStates: {
          ...state.templatesStates,
          [action.key]: 'success',
        },
        templatesResults: {
          ...state.templatesResults,
          [action.key]: action.data,
        },
      }
    case templateActions.REQUEST_FAILURE:
      return {
        ...state,
        templatesStates: {
          ...state.templatesStates,
          [action.key]: 'failure',
        },
      }

    case groupsActions.REQUEST:
      return {
        ...state,
        groupsStates: {
          ...state.groupsStates,
          [action.key]: 'loading',
        },
      }
    case groupsActions.REQUEST_SUCCESS:
      return {
        ...state,
        groupsStates: {
          ...state.groupsStates,
          [action.key]: 'success',
        },
        groupsData: {
          ...state.groupsData,
          [action.key]: action.data,
        },
      }
    case groupsActions.REQUEST_FAILURE:
      return {
        ...state,
        groupsStates: {
          ...state.groupsStates,
          [action.key]: 'failure',
        },
      }

    case archetypesActions.REQUEST:
      return {
        ...state,
        archetypesStates: {
          ...state.archetypesStates,
          [action.key]: 'loading',
        },
      }
    case archetypesActions.REQUEST_SUCCESS:
      return {
        ...state,
        archetypesStates: {
          ...state.archetypesStates,
          [action.key]: 'success',
        },
        archetypesResults: {
          ...state.archetypesResults,
          [action.key]: action.data,
        },
      }
    case archetypesActions.REQUEST_FAILURE:
      return {
        ...state,
        archetypesStates: {
          ...state.archetypesStates,
          [action.key]: 'failure',
        },
      }

    case updateActions.REQUEST:
      return {
        ...state,
        updatePlaybookStates: {
          ...state.updatePlaybookStates,
          [action.playbookId]: 'loading',
        },
      }
    case updateActions.REQUEST_SUCCESS:
      return {
        ...state,
        updatePlaybookStates: {
          ...state.updatePlaybookStates,
          [action.playbookId]: 'success',
        },
      }
    case updateActions.REQUEST_FAILURE:
      return {
        ...state,
        updatePlaybookStates: {
          ...state.updatePlaybookStates,
          [action.playbookId]: 'failure',
        },
      }

    case profileDataActions.REQUEST:
      return {
        ...state,
        profileDataStates: {
          ...state.profileDataStates,
          [action.key]: 'loading',
        },
      }
    case profileDataActions.REQUEST_SUCCESS:
      return {
        ...state,
        profileDataStates: {
          ...state.profileDataStates,
          [action.key]: 'success',
        },
        profileData: {
          ...state.profileData,
          [action.key]: action.data,
        },
      }
    case profileDataActions.REQUEST_FAILURE:
      return {
        ...state,
        profileDataStates: {
          ...state.profileDataStates,
          [action.key]: 'failure',
        },
      }

    // Find Playbook
    case findActions.REQUEST:
      return {
        ...state,
        findStates: {
          ...state.findStates,
          [action.key]: 'loading',
        },
      }
    case findActions.REQUEST_SUCCESS:
      return {
        ...state,
        findStates: {
          ...state.findStates,
          [action.key]: 'success',
        },
        findResults: {
          ...state.findResults,
          [action.key]: action.uuid,
        },
      }
    case findActions.REQUEST_FAILURE:
      return {
        ...state,
        findStates: {
          ...state.findStates,
          [action.key]: 'failure',
        },
      }

    case structuresActions.REQUEST:
      return {
        ...state,
        structuresState: 'loading',
      }
    case structuresActions.REQUEST_SUCCESS:
      return {
        ...state,
        structuresState: 'success',
        structuresResult: action.structures,
      }
    case structuresActions.REQUEST_FAILURE:
      return {
        ...state,
        structuresState: 'failure',
      }

    // Find Playbook
    case createTemplateActions.REQUEST:
      return {
        ...state,
        createTemplateStates: {
          ...state.createTemplateStates,
          [action.name]: 'loading',
        },
      }
    case createTemplateActions.REQUEST_SUCCESS:
      return {
        ...state,
        createTemplateStates: {
          ...state.createTemplateStates,
          [action.name]: 'success',
        },
        createTemplateResults: {
          ...state.createTemplateResults,
          [action.name]: action.id,
        },
      }
    case createTemplateActions.REQUEST_FAILURE:
      return {
        ...state,
        createTemplateStates: {
          ...state.createTemplateStates,
          [action.name]: 'failure',
        },
        createTemplateErrors: {
          ...state.createTemplateErrors,
          [action.name]: action.error,
        },
      }

    case sampleActions.REQUEST:
      return {
        ...state,
        sampleStates: {
          ...state.sampleStates,
          [action.templateId]: 'loading',
        },
      }
    case sampleActions.REQUEST_SUCCESS:
      return {
        ...state,
        sampleStates: {
          ...state.sampleStates,
          [action.templateId]: 'success',
        },
        sampleResults: {
          ...state.sampleResults,
          [action.templateId]: action.data,
        },
      }
    case sampleActions.REQUEST_FAILURE:
      return {
        ...state,
        sampleStates: {
          ...state.sampleStates,
          [action.templateId]: 'failure',
        },
      }

    case matrixActions.REQUEST:
      return {
        ...state,
        matrixStates: {
          ...state.matrixStates,
          [action.key]: 'loading',
        },
      }
    case matrixActions.REQUEST_SUCCESS:
      return {
        ...state,
        matrixStates: {
          ...state.matrixStates,
          [action.key]: 'success',
        },
        matrixResults: {
          ...state.matrixResults,
          [action.key]: action.data,
        },
      }
    case matrixActions.REQUEST_FAILURE:
      return {
        ...state,
        matrixStates: {
          ...state.matrixStates,
          [action.key]: 'failure',
        },
        matrixErrors: {
          ...state.matrixErrors,
          [action.key]: action.error,
        },
      }

    case superlativesActions.REQUEST:
      return {
        ...state,
        superlativesStates: {
          ...state.superlativesStates,
          [action.key]: 'loading',
        },
      }
    case superlativesActions.REQUEST_SUCCESS:
      return {
        ...state,
        superlativesStates: {
          ...state.superlativesStates,
          [action.key]: 'success',
        },
        superlativesResults: {
          ...state.superlativesResults,
          [action.key]: action.data,
        },
      }
    case superlativesActions.REQUEST_FAILURE:
      return {
        ...state,
        superlativesStates: {
          ...state.superlativesStates,
          [action.key]: 'failure',
        },
        superlativesErrors: {
          ...state.superlativesErrors,
          [action.key]: action.error,
        },
      }

    // Delete custom template
    case deleteCustomTemplateActions.REQUEST:
      return {
        ...state,
        destroyTemplateStates: {
          ...state.destroyTemplateStates,
          [action.key]: 'loading',
        },
      }
    case deleteCustomTemplateActions.REQUEST_SUCCESS:
      return {
        ...state,
        destroyTemplateStates: {
          ...state.destroyTemplateStates,
          [action.key]: 'success',
        },
        leadershipTemplatesStates: {},
      }
    case deleteCustomTemplateActions.REQUEST_FAILURE:
      return {
        ...state,
        destroyTemplateStates: {
          ...state.destroyTemplateStates,
          [action.key]: 'failure',
        },
        destroyTemplateErrorMessages: {
          ...state.destroyTemplateErrorMessages,
          [action.key]: action.errorMessage,
        },
      }

    // Delete playbook
    case deletePlaybookActions.REQUEST:
      return {
        ...state,
        destroyPlaybookStates: {
          ...state.destroyPlaybookStates,
          [action.key]: 'loading',
        },
      }
    case deletePlaybookActions.REQUEST_SUCCESS:
      return {
        ...state,
        destroyPlaybookStates: {
          ...state.destroyPlaybookStates,
          [action.key]: 'success',
        },
        getStates: {},
      }
    case deletePlaybookActions.REQUEST_FAILURE:
      return {
        ...state,
        destroyPlaybookStates: {
          ...state.destroyPlaybookStates,
          [action.key]: 'failure',
        },
      }

    // Add profiles
    case addPlaybookProfilesActions.REQUEST:
      return {
        ...state,
        addProfilesStates: {
          ...state.addProfilesStates,
          [action.key]: 'loading',
        },
      }
    case addPlaybookProfilesActions.REQUEST_SUCCESS:
      return {
        ...state,
        addProfilesStates: {
          ...state.addProfilesStates,
          [action.key]: 'success',
        },
      }
    case addPlaybookProfilesActions.REQUEST_FAILURE:
      return {
        ...state,
        addProfilesStates: {
          ...state.addProfilesStates,
          [action.key]: 'failure',
        },
      }

    // Remove profiles
    case removePlaybookProfilesActions.REQUEST:
      return {
        ...state,
        removeProfilesStates: {
          ...state.removeProfilesStates,
          [action.key]: 'loading',
        },
      }
    case removePlaybookProfilesActions.REQUEST_SUCCESS:
      return {
        ...state,
        removeProfilesStates: {
          ...state.removeProfilesStates,
          [action.key]: 'success',
        },
      }
    case removePlaybookProfilesActions.REQUEST_FAILURE:
      return {
        ...state,
        removeProfilesStates: {
          ...state.removeProfilesStates,
          [action.key]: 'failure',
        },
      }

    // Communication advice
    case communicationAdviceActions.REQUEST:
      return {
        ...state,
        adviceStates: {
          ...state.adviceStates,
          [action.key]: 'loading',
        },
      }
    case communicationAdviceActions.REQUEST_SUCCESS:
      return {
        ...state,
        adviceStates: {
          ...state.adviceStates,
          [action.key]: 'success',
        },
        adviceResults: {
          ...state.adviceResults,
          [action.key]: action.data,
        },
      }
    case communicationAdviceActions.REQUEST_FAILURE:
      return {
        ...state,
        adviceStates: {
          ...state.adviceStates,
          [action.key]: 'failure',
        },
      }

    // Interview Questions
    case interviewQuestions.REQUEST:
      return {
        ...state,
        interviewQuestionsStates: {
          ...state.interviewQuestionsStates,
          [action.key]: 'loading',
        },
      }
    case interviewQuestions.REQUEST_SUCCESS:
      return {
        ...state,
        interviewQuestionsStates: {
          ...state.interviewQuestionsStates,
          [action.key]: 'success',
        },
        interviewQuestionsResults: {
          ...state.interviewQuestionsResults,
          [action.key]: action.data,
        },
      }
    case interviewQuestions.REQUEST_FAILURE:
      return {
        ...state,
        interviewQuestionsStates: {
          ...state.interviewQuestionsStates,
          [action.key]: 'failure',
        },
      }

    // Template Show
    case templateShowActions.REQUEST:
      return {
        ...state,
        templateShowStates: {
          ...state.templateShowStates,
          [action.key]: 'loading',
        },
      }
    case templateShowActions.REQUEST_SUCCESS:
      return {
        ...state,
        templateShowStates: {
          ...state.templateShowStates,
          [action.key]: 'success',
        },
        templateShowResults: {
          ...state.templateShowResults,
          [action.key]: action.data,
        },
      }
    case templateShowActions.REQUEST_FAILURE:
      return {
        ...state,
        templateShowStates: {
          ...state.templateShowStates,
          [action.key]: 'failure',
        },
      }

    // Meetings Index
    case getMeetingsActions.REQUEST:
      return {
        ...state,
        meetingsStates: {
          ...state.meetingsStates,
          [action.key]: 'loading',
        },
      }
    case getMeetingsActions.REQUEST_SUCCESS:
      return {
        ...state,
        meetingsStates: {
          ...state.meetingsStates,
          [action.key]: 'success',
        },
        meetings: {
          ...state.meetings,
          [action.key]: action.data,
        },
      }
    case getMeetingsActions.REQUEST_FAILURE:
      return {
        ...state,
        meetingsStates: {
          ...state.meetingsStates,
          [action.key]: 'failure',
        },
      }

    // Interview Index
    case getInterviewsActions.REQUEST:
      return {
        ...state,
        interviewsStates: {
          ...state.interviewsStates,
          [action.key]: 'loading',
        },
      }
    case getInterviewsActions.REQUEST_SUCCESS:
      return {
        ...state,
        interviewsStates: {
          ...state.interviewsStates,
          [action.key]: 'success',
        },
        interviews: {
          ...state.interviews,
          [action.key]: action.data,
        },
      }
    case getInterviewsActions.REQUEST_FAILURE:
      return {
        ...state,
        interviewsStates: {
          ...state.interviewsStates,
          [action.key]: 'failure',
        },
      }

    // Create Playbook
    case createPlaybookActions.REQUEST:
      return {
        ...state,
        createPlaybookStates: {
          ...state.createPlaybookStates,
          [action.key]: 'loading',
        },
      }
    case createPlaybookActions.REQUEST_SUCCESS:
      return {
        ...state,
        createPlaybookStates: {
          ...state.createPlaybookStates,
          [action.key]: 'success',
        },
        createPlaybookResults: {
          ...state.createPlaybookResults,
          [action.key]: action.data,
        },
      }
    case createPlaybookActions.REQUEST_FAILURE:
      return {
        ...state,
        createPlaybookStates: {
          ...state.createPlaybookStates,
          [action.key]: 'failure',
        },
        createPlaybookErrors: {
          ...state.createPlaybookErrors,
          [action.key]: action.error,
        },
      }

    // Get Hiring Templates
    case getHiringTemplatesActions.REQUEST:
      return {
        ...state,
        hiringTemplatesStates: {
          ...state.hiringTemplatesStates,
          [action.key]: 'loading',
        },
      }
    case getHiringTemplatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        hiringTemplatesStates: {
          ...state.hiringTemplatesStates,
          [action.key]: 'success',
        },
        hiringTemplatesResults: {
          ...state.hiringTemplatesResults,
          [action.key]: action.data,
        },
      }
    case getHiringTemplatesActions.REQUEST_FAILURE:
      return {
        ...state,
        hiringTemplatesStates: {
          ...state.hiringTemplatesStates,
          [action.key]: 'failure',
        },
      }

    // Get Leadership Templates
    case getLeadershipTemplatesActions.REQUEST:
      return {
        ...state,
        leadershipTemplatesStates: {
          ...state.leadershipTemplatesStates,
          [action.key]: 'loading',
        },
      }
    case getLeadershipTemplatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        leadershipTemplatesStates: {
          ...state.leadershipTemplatesStates,
          [action.key]: 'success',
        },
        leadershipTemplatesResults: {
          ...state.leadershipTemplatesResults,
          [action.key]: action.data,
        },
      }
    case getLeadershipTemplatesActions.REQUEST_FAILURE:
      return {
        ...state,
        leadershipTemplatesStates: {
          ...state.leadershipTemplatesStates,
          [action.key]: 'failure',
        },
      }

    // Get Sales Templates
    case getSalesTemplatesActions.REQUEST:
      return {
        ...state,
        salesTemplatesStates: {
          ...state.salesTemplatesStates,
          [action.key]: 'loading',
        },
      }
    case getSalesTemplatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        salesTemplatesStates: {
          ...state.salesTemplatesStates,
          [action.key]: 'success',
        },
        salesTemplatesResults: {
          ...state.salesTemplatesResults,
          [action.key]: action.data,
        },
      }
    case getSalesTemplatesActions.REQUEST_FAILURE:
      return {
        ...state,
        salesTemplatesStates: {
          ...state.salesTemplatesStates,
          [action.key]: 'failure',
        },
      }

    case createLeadershipActions.REQUEST:
      return {
        ...state,
        createLeadershipStates: {
          ...state.createLeadershipStates,
          [action.query]: 'loading',
        },
      }
    case createLeadershipActions.REQUEST_SUCCESS:
      return {
        ...state,
        createLeadershipStates: {
          ...state.createLeadershipStates,
          [action.query]: 'success',
        },
        createLeadershipResults: {
          ...state.createLeadershipResults,
          [action.query]: action.data,
        },
        getStates: {},
      }
    case createLeadershipActions.REQUEST_FAILURE:
      return {
        ...state,
        createLeadershipStates: {
          ...state.createLeadershipStates,
          [action.query]: 'failure',
        },
        createLeadershipError: {
          ...state.createLeadershipError,
          [action.query]: action.error,
        },
        createLeadershipErrorProfiles: {
          ...state.createLeadershipErrorProfiles,
          [action.query]: action.profiles,
        },
      }

    case createSalesActions.REQUEST:
      return {
        ...state,
        createSalesStates: {
          ...state.createSalesStates,
          [action.query]: 'loading',
        },
      }
    case createSalesActions.REQUEST_SUCCESS:
      return {
        ...state,
        createSalesStates: {
          ...state.createSalesStates,
          [action.query]: 'success',
        },
        createSalesResults: {
          ...state.createSalesResults,
          [action.query]: action.data,
        },
      }
    case createSalesActions.REQUEST_FAILURE:
      return {
        ...state,
        createSalesStates: {
          ...state.createSalesStates,
          [action.query]: 'failure',
        },
        createSalesError: {
          ...state.createSalesError,
          [action.query]: action.error,
        },
        createSalesErrorProfiles: {
          ...state.createSalesErrorProfiles,
          [action.query]: action.profiles,
        },
      }

    // Create Playbook Note
    case createNoteActions.REQUEST:
      return {
        ...state,
        createNoteStates: {
          ...state.createNoteStates,
          [action.key]: 'loading',
        },
      }
    case createNoteActions.REQUEST_SUCCESS:
      return {
        ...state,
        createNoteStates: {
          ...state.createNoteStates,
          [action.key]: 'success',
        },
      }
    case createNoteActions.REQUEST_FAILURE:
      return {
        ...state,
        createNoteStates: {
          ...state.createNoteStates,
          [action.key]: 'failure',
        },
      }

    // Update Playbook Note
    case updateNoteActions.REQUEST:
      return {
        ...state,
        updateNoteStates: {
          ...state.updateNoteStates,
          [action.key]: 'loading',
        },
      }
    case updateNoteActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateNoteStates: {
          ...state.updateNoteStates,
          [action.key]: 'success',
        },
      }
    case updateNoteActions.REQUEST_FAILURE:
      return {
        ...state,
        updateNoteStates: {
          ...state.updateNoteStates,
          [action.key]: 'failure',
        },
      }

    // Delete Playbook Note
    case deleteNoteActions.REQUEST:
      return {
        ...state,
        deleteNoteStates: {
          ...state.deleteNoteStates,
          [action.key]: 'loading',
        },
      }
    case deleteNoteActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteNoteStates: {
          ...state.deleteNoteStates,
          [action.key]: 'success',
        },
      }
    case deleteNoteActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteNoteStates: {
          ...state.deleteNoteStates,
          [action.key]: 'failure',
        },
      }

    default:
      return state
  }
}
