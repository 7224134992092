import * as assignProductLicenseActions from '@dashboard/store/actions/user/products/assign'
import * as unassignProductLicenseActions from '@dashboard/store/actions/user/products/unassign'
import * as createActions from '@dashboard/store/actions/team/create'
import * as destroyActions from '@dashboard/store/actions/team/destroy'
import * as getPaymentSourceActions from '@dashboard/store/actions/team/payment_sources/index'
import * as grantAccessActions from '@dashboard/store/actions/team/users/grantAccess'
import * as inviteActions from '@dashboard/store/actions/team/invite'
import * as remindAllActions from '@dashboard/store/actions/team/users/remindAll'
import * as remindIndividualActions from '@dashboard/store/actions/team/users/remindIndividual'
import * as revokeAccessActions from '@dashboard/store/actions/team/users/revokeAccess'
import * as showActions from '@dashboard/store/actions/team/show'
import * as updateActions from '@dashboard/store/actions/team/update'
import * as updatePaymentSourceActions from '@dashboard/store/actions/team/payment_sources/update'
import * as uploadAssessmentLogoActions from '@dashboard/store/actions/team/uploadAssessmentLogo'
import * as loadPublicTeamActions from '@dashboard/store/actions/team/public/show'
import * as statsActions from '@dashboard/store/actions/team/stats'

import * as indexUserActions from '@dashboard/store/actions/team/users'
import * as exportUsersActions from '@dashboard/store/actions/team/users/export'
import * as destroyUserActions from '@dashboard/store/actions/team/users/destroy'
import * as updateUserActions from '@dashboard/store/actions/team/users/update'

import * as unitsIndexActions from '@dashboard/store/actions/team/units'
import * as unitsShowActions from '@dashboard/store/actions/team/units/show'
import * as unitsCreateActions from '@dashboard/store/actions/team/units/create'
import * as unitsUpdateActions from '@dashboard/store/actions/team/units/update'
import * as unitsDestroyActions from '@dashboard/store/actions/team/units/destroy'
import * as unitMembersIndexActions from '@dashboard/store/actions/team/units/members'
import * as unitMembersCreateActions from '@dashboard/store/actions/team/units/members/create'
import * as unitMembersDestroyActions from '@dashboard/store/actions/team/units/members/destroy'
import * as teamSimpleActions from '@dashboard/store/actions/team/show_simple'
import * as teamInviteActions from '@dashboard/store/actions/team/invites'

import * as indexCustomEmailActions from '@dashboard/store/actions/team/email'
import * as createCustomEmailActions from '@dashboard/store/actions/team/email/create'
import * as updateCustomEmailActions from '@dashboard/store/actions/team/email/update'
import * as testCustomEmailActions from '@dashboard/store/actions/team/email/test'

import * as createCustomContentActions from '../actions/team/custom_content/create'
import * as indexCustomContentActions from '../actions/team/custom_content'
import * as indexCustomContentProfileActions from '../actions/team/custom_content_profile'

import * as updateWhiteLabelActions from '@dashboard/store/actions/team/white_label/update'
import * as indexWhiteLabelActions from '@dashboard/store/actions/team/white_label'

import * as inviteCandidateActions from '@dashboard/store/actions/team/email/inviteCandidate'
import * as statsExportActions from '@dashboard/store/actions/team/stats/export'

import * as sendCustomerSuccessManagerEmailActions from '@dashboard/store/actions/team/customerSuccessManagerEmail'

import {
  Team,
  Teams,
  LoadingStates,
  SubscriptionStates,
  APIPagedResponse,
  TeamUser,
  TeamStats,
  KeyedStateHelper,
  APIErrorResponse,
  TeamUnit,
  EmailResponse,
  TeamSimple,
  InviteResponse,
  CustomContentResponse,
  CustomContentProfileResponse,
} from '@dashboard/lib/types'
import { WhiteLabelAssessmentsResponse } from '@dashboard/store/actions/team/white_label'

export interface State {
  grantAccessStates: KeyedStateHelper<LoadingStates>
  showStates: KeyedStateHelper<LoadingStates>
  showResults: KeyedStateHelper<Team>
  inviteState: LoadingStates
  inviteError: APIErrorResponse | null
  loadStates: LoadingStates
  paymentSources: getPaymentSourceActions.PaymentSource[]
  paymentSourcesState: LoadingStates
  remindAllState: LoadingStates
  remindIndividualStates: KeyedStateHelper<LoadingStates>
  revokeAccessStates: KeyedStateHelper<LoadingStates>
  teams: Teams
  updateTeamState: LoadingStates
  teamUsers: KeyedStateHelper<indexUserActions.TeamUsersResponse>
  teamUsersStates: KeyedStateHelper<LoadingStates>
  updateCreditCardState: SubscriptionStates
  userDestroyState: LoadingStates
  uploadAssessmentLogoState: LoadingStates
  publicTeamStates: KeyedStateHelper<LoadingStates>
  publicTeamResults: Record<
    string,
    loadPublicTeamActions.PublicTeam | undefined
  >
  statsStates: KeyedStateHelper<LoadingStates>
  statsResults: KeyedStateHelper<TeamStats>

  unitStates: KeyedStateHelper<LoadingStates>
  unitResults: KeyedStateHelper<APIPagedResponse<TeamUnit>>
  unitsCreateState: LoadingStates
  unitShowStates: KeyedStateHelper<LoadingStates>
  unitShowResults: KeyedStateHelper<TeamUnit>
  unitUpdateStates: KeyedStateHelper<LoadingStates>
  unitDestroyStates: KeyedStateHelper<LoadingStates>
  unitMembersStates: KeyedStateHelper<LoadingStates>
  unitMembersResults: KeyedStateHelper<APIPagedResponse<TeamUser>>
  unitMemberCreateStates: KeyedStateHelper<LoadingStates>
  unitMemberDestroyStates: KeyedStateHelper<LoadingStates>
  exportUsersState: LoadingStates

  getCustomEmailState: LoadingStates
  getCustomEmailResult: EmailResponse[]
  createCustomEmailState: LoadingStates
  updateCustomEmailStates: KeyedStateHelper<LoadingStates>
  testCustomEmailState: LoadingStates

  createCustomContentState: LoadingStates
  getCustomContentState: LoadingStates
  getCustomContentResult: CustomContentResponse
  getCustomContentProfileState: LoadingStates
  getCustomContentProfileResult: CustomContentProfileResponse

  getWhiteLabelAssessmentsState: LoadingStates
  getWhiteLabelAssessmentsResults: WhiteLabelAssessmentsResponse | null
  updateWhiteLabelAssessmentsState: LoadingStates

  inviteCandidateStates: KeyedStateHelper<LoadingStates>
  statsExportStates: KeyedStateHelper<LoadingStates>
  updateUserStates: KeyedStateHelper<LoadingStates>
  sendCustomerSuccessManagerEmailState: LoadingStates
  loadTeamSimpleStates: KeyedStateHelper<LoadingStates>
  loadTeamSimpleResults: KeyedStateHelper<TeamSimple>
  loadTeamInvitesStates: KeyedStateHelper<LoadingStates>
  loadTeamInvitesResults: KeyedStateHelper<InviteResponse>
}

const INITIAL_STATE: State = {
  grantAccessStates: {},
  showStates: {},
  showResults: {},
  inviteState: 'default',
  inviteError: null,
  loadStates: 'default',
  paymentSources: [],
  paymentSourcesState: 'default',
  remindAllState: 'default',
  remindIndividualStates: {},
  revokeAccessStates: {},
  teams: [],
  updateTeamState: 'default',
  teamUsers: {},
  teamUsersStates: {},
  updateCreditCardState: { state: 'default', err: '' },
  userDestroyState: 'default',
  uploadAssessmentLogoState: 'default',
  publicTeamStates: {},
  publicTeamResults: {},
  statsStates: {},
  statsResults: {},
  unitStates: {},
  unitResults: {},
  unitsCreateState: 'default',
  unitShowStates: {},
  unitShowResults: {},
  unitUpdateStates: {},
  unitDestroyStates: {},
  unitMembersStates: {},
  unitMembersResults: {},
  unitMemberCreateStates: {},
  unitMemberDestroyStates: {},
  exportUsersState: 'default',

  getCustomEmailState: 'default',
  getCustomEmailResult: [],
  createCustomEmailState: 'default',
  updateCustomEmailStates: {},
  testCustomEmailState: 'default',

  getCustomContentResult: { section_name: '', disc_content: {} },
  createCustomContentState: 'default',
  getCustomContentState: 'default',

  getCustomContentProfileResult: { section_name: '', disc_content: null },
  getCustomContentProfileState: 'default',

  getWhiteLabelAssessmentsState: 'default',
  getWhiteLabelAssessmentsResults: null,
  updateWhiteLabelAssessmentsState: 'default',
  inviteCandidateStates: {},
  statsExportStates: {},
  updateUserStates: {},
  sendCustomerSuccessManagerEmailState: 'default',
  loadTeamSimpleStates: {},
  loadTeamSimpleResults: {},
  loadTeamInvitesStates: {},
  loadTeamInvitesResults: {},
}

type Action =
  | createActions.Action
  | destroyActions.Action
  | getPaymentSourceActions.Action
  | grantAccessActions.Action
  | inviteActions.Action
  | remindAllActions.Action
  | remindIndividualActions.Action
  | revokeAccessActions.Action
  | showActions.Action
  | statsActions.Action
  | updateActions.Action
  | updatePaymentSourceActions.Action
  | indexUserActions.Action
  | destroyUserActions.Action
  | uploadAssessmentLogoActions.Action
  | loadPublicTeamActions.Action
  | unitsIndexActions.Action
  | unitsCreateActions.Action
  | unitsShowActions.Action
  | unitsUpdateActions.Action
  | unitsDestroyActions.Action
  | unitMembersIndexActions.Action
  | unitMembersCreateActions.Action
  | unitMembersDestroyActions.Action
  | exportUsersActions.Action
  | indexCustomEmailActions.Action
  | createCustomEmailActions.Action
  | updateCustomEmailActions.Action
  | createCustomContentActions.Action
  | indexCustomContentActions.Action
  | indexCustomContentProfileActions.Action
  | updateWhiteLabelActions.Action
  | indexWhiteLabelActions.Action
  | testCustomEmailActions.Action
  | inviteCandidateActions.Action
  | statsExportActions.Action
  | updateUserActions.Action
  | sendCustomerSuccessManagerEmailActions.Action
  | teamSimpleActions.Action
  | assignProductLicenseActions.Action
  | unassignProductLicenseActions.Action
  | teamInviteActions.Action

export default function teamReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // SHOW
    case showActions.REQUEST:
      return {
        ...state,
        showStates: { ...state.showStates, [action.id]: 'loading' },
      }

    case showActions.REQUEST_SUCCESS:
      return {
        ...state,
        showStates: { ...state.showStates, [action.id]: 'success' },
        showResults: { ...state.showResults, [action.id]: action.team },
      }

    case showActions.REQUEST_FAILURE:
      return {
        ...state,
        showStates: { ...state.showStates, [action.id]: 'failure' },
      }

    // CREATE
    case createActions.REQUEST:
      return { ...state, loadStates: 'loading' }

    case createActions.REQUEST_SUCCESS:
      state.teams.push(action.team)

      return {
        ...state,
        loadStates: 'success',
        showResults: { ...state.showResults, [action.team.id]: action.team },
      }

    case createActions.REQUEST_FAILURE:
      return { ...state, loadStates: 'failure' }

    // DESTROY
    case destroyActions.REQUEST:
      return { ...state, loadStates: 'loading' }

    case destroyActions.REQUEST_SUCCESS: {
      const actionID = action.id
      return {
        ...state,
        loadStates: 'success',
        teams: state.teams.filter(item => {
          return item.id !== actionID
        }),
      }
    }

    case destroyActions.REQUEST_FAILURE:
      return { ...state, loadStates: 'failure' }

    // UPDATE
    case updateActions.REQUEST:
      return { ...state, updateTeamState: 'loading' }

    case updateActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateTeamState: 'success',
      }

    case updateActions.REQUEST_FAILURE:
      return { ...state, updateTeamState: 'failure' }

    // INVITE
    case inviteActions.REQUEST:
      return { ...state, inviteState: 'loading' }

    case inviteActions.REQUEST_SUCCESS:
      return { ...state, inviteState: 'success' }

    case inviteActions.REQUEST_FAILURE:
      return { ...state, inviteState: 'failure', inviteError: action.error }

    // USERS
    case indexUserActions.REQUEST:
      return {
        ...state,
        teamUsersStates: {
          ...state.teamUsersStates,
          [action.key]: 'loading',
        },
      }

    case indexUserActions.REQUEST_SUCCESS:
      return {
        ...state,
        teamUsersStates: {
          ...state.teamUsersStates,
          [action.key]: 'success',
        },
        teamUsers: {
          ...state.teamUsers,
          [action.key]: action.data,
        },
      }

    case indexUserActions.REQUEST_FAILURE:
      return {
        ...state,
        teamUsersStates: {
          ...state.teamUsersStates,
          [action.key]: 'failure',
        },
      }

    case assignProductLicenseActions.REQUEST_SUCCESS:
      return { ...state, teamUsersStates: {} }

    case unassignProductLicenseActions.REQUEST_SUCCESS:
      return { ...state, teamUsersStates: {} }

    // USER DESTROY
    case destroyUserActions.REQUEST:
      return { ...state, userDestroyState: 'loading' }

    case destroyUserActions.REQUEST_SUCCESS: {
      return { ...state, userDestroyState: 'success' }
    }

    case destroyUserActions.REQUEST_FAILURE:
      return { ...state, userDestroyState: 'failure' }

    // USER UPDATE
    case updateUserActions.REQUEST:
      return {
        ...state,
        updateUserStates: {
          ...state.updateUserStates,
          [action.key]: 'loading',
        },
      }

    case updateUserActions.REQUEST_SUCCESS: {
      return {
        ...state,
        updateUserStates: {
          ...state.updateUserStates,
          [action.key]: 'success',
        },
      }
    }

    case updateUserActions.REQUEST_FAILURE:
      return {
        ...state,
        updateUserStates: {
          ...state.updateUserStates,
          [action.key]: 'failure',
        },
      }

    // Remind all
    case remindAllActions.REQUEST:
      return { ...state, remindAllState: 'loading' }

    case remindAllActions.REQUEST_SUCCESS:
      return { ...state, remindAllState: 'success' }

    case remindAllActions.REQUEST_FAILURE:
      return { ...state, remindAllState: 'failure' }

    // Remind individual
    case remindIndividualActions.REQUEST:
      return {
        ...state,
        remindIndividualStates: {
          ...state.remindIndividualStates,
          [action.token]: 'loading',
        },
      }

    case remindIndividualActions.REQUEST_SUCCESS:
      return {
        ...state,
        remindIndividualStates: {
          ...state.remindIndividualStates,
          [action.token]: 'success',
        },
      }

    case remindIndividualActions.REQUEST_FAILURE:
      return {
        ...state,
        remindIndividualStates: {
          ...state.remindIndividualStates,
          [action.token]: 'failure',
        },
      }

    // Change access level
    case grantAccessActions.REQUEST:
      return {
        ...state,
        grantAccessStates: {
          ...state.grantAccessStates,
          [action.userID]: 'loading',
        },
      }

    case grantAccessActions.REQUEST_SUCCESS:
      return {
        ...state,
        grantAccessStates: {
          ...state.grantAccessStates,
          [action.userID]: 'success',
        },
      }

    case grantAccessActions.REQUEST_FAILURE:
      return {
        ...state,
        grantAccessStates: {
          ...state.grantAccessStates,
          [action.userID]: 'failure',
        },
      }

    case revokeAccessActions.REQUEST:
      return {
        ...state,
        revokeAccessStates: {
          ...state.revokeAccessStates,
          [action.userID]: 'loading',
        },
      }

    case revokeAccessActions.REQUEST_SUCCESS:
      return {
        ...state,
        revokeAccessStates: {
          ...state.revokeAccessStates,
          [action.userID]: 'success',
        },
      }

    case revokeAccessActions.REQUEST_FAILURE:
      return {
        ...state,
        revokeAccessStates: {
          ...state.revokeAccessStates,
          [action.userID]: 'failure',
        },
      }

    case updatePaymentSourceActions.REQUEST:
      return { ...state, updateCreditCardState: { state: 'loading', err: '' } }

    case updatePaymentSourceActions.REQUEST_SUCCESS:
      return { ...state, updateCreditCardState: { state: 'success', err: '' } }

    case updatePaymentSourceActions.REQUEST_FAILURE:
      return {
        ...state,
        updateCreditCardState: {
          state: 'failure',
          err: action.error.response.data.message,
        },
      }

    case getPaymentSourceActions.REQUEST:
      return { ...state, paymentSourcesState: 'loading' }

    case getPaymentSourceActions.REQUEST_SUCCESS:
      return {
        ...state,
        paymentSourcesState: 'success',
        paymentSources: action.paymentSources,
      }

    case getPaymentSourceActions.REQUEST_FAILURE:
      return { ...state, paymentSourcesState: 'failure' }

    // Upload assessment logo
    case uploadAssessmentLogoActions.REQUEST:
      return { ...state, uploadAssessmentLogoState: 'loading' }

    case uploadAssessmentLogoActions.REQUEST_SUCCESS:
      return { ...state, uploadAssessmentLogoState: 'success' }

    case uploadAssessmentLogoActions.REQUEST_FAILURE:
      return { ...state, uploadAssessmentLogoState: 'failure' }

    // Load public team
    case loadPublicTeamActions.REQUEST:
      return {
        ...state,
        publicTeamStates: {
          ...state.publicTeamStates,
          [action.key]: 'loading',
        },
      }

    case loadPublicTeamActions.REQUEST_SUCCESS:
      return {
        ...state,
        publicTeamStates: {
          ...state.publicTeamStates,
          [action.key]: 'success',
        },
        publicTeamResults: {
          ...state.publicTeamResults,
          [action.key]: action.data,
        },
      }

    case loadPublicTeamActions.REQUEST_FAILURE:
      return {
        ...state,
        publicTeamStates: {
          ...state.publicTeamStates,
          [action.key]: 'failure',
        },
      }

    // STATS
    case statsActions.REQUEST:
      return {
        ...state,
        statsStates: { ...state.statsStates, [action.key]: 'loading' },
      }

    case statsActions.REQUEST_SUCCESS:
      return {
        ...state,
        statsStates: { ...state.statsStates, [action.key]: 'success' },
        statsResults: { ...state.statsResults, [action.key]: action.data },
      }

    case statsActions.REQUEST_FAILURE:
      return {
        ...state,
        statsStates: { ...state.statsStates, [action.key]: 'failure' },
      }

    // Unit Index
    case unitsIndexActions.REQUEST:
      return {
        ...state,
        unitStates: { ...state.unitStates, [action.key]: 'loading' },
      }

    case unitsIndexActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitStates: { ...state.unitStates, [action.key]: 'success' },
        unitResults: { ...state.unitResults, [action.key]: action.data },
      }

    case unitsIndexActions.REQUEST_FAILURE:
      return {
        ...state,
        unitStates: { ...state.unitStates, [action.key]: 'failure' },
      }

    // Unit Show
    case unitsShowActions.REQUEST:
      return {
        ...state,
        unitShowStates: { ...state.unitShowStates, [action.key]: 'loading' },
      }

    case unitsShowActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitShowStates: { ...state.unitShowStates, [action.key]: 'success' },
        unitShowResults: {
          ...state.unitShowResults,
          [action.key]: action.data,
        },
      }

    case unitsShowActions.REQUEST_FAILURE:
      return {
        ...state,
        unitShowStates: { ...state.unitShowStates, [action.key]: 'failure' },
      }

    // Unit Update
    case unitsUpdateActions.REQUEST:
      return {
        ...state,
        unitUpdateStates: {
          ...state.unitUpdateStates,
          [action.key]: 'loading',
        },
      }

    case unitsUpdateActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitUpdateStates: {
          ...state.unitUpdateStates,
          [action.key]: 'success',
        },
        unitStates: {},
        teamUsersStates: {},
      }

    case unitsUpdateActions.REQUEST_FAILURE:
      return {
        ...state,
        unitUpdateStates: {
          ...state.unitUpdateStates,
          [action.key]: 'failure',
        },
      }

    // Unit Destroy
    case unitsDestroyActions.REQUEST:
      return {
        ...state,
        unitDestroyStates: {
          ...state.unitDestroyStates,
          [action.key]: 'loading',
        },
      }

    case unitsDestroyActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitDestroyStates: {
          ...state.unitDestroyStates,
          [action.key]: 'success',
        },
        unitStates: {},
        teamUsersStates: {},
      }

    case unitsDestroyActions.REQUEST_FAILURE:
      return {
        ...state,
        unitDestroyStates: {
          ...state.unitDestroyStates,
          [action.key]: 'failure',
        },
      }

    // Unit Create
    case unitsCreateActions.REQUEST:
      return {
        ...state,
        unitsCreateState: 'loading',
      }

    case unitsCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitsCreateState: 'success',
        unitStates: {},
      }

    case unitsCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        unitsCreateState: 'failure',
      }

    // Unit Members Index
    case unitMembersIndexActions.REQUEST:
      return {
        ...state,
        unitMembersStates: {
          ...state.unitMembersStates,
          [action.key]: 'loading',
        },
      }

    case unitMembersIndexActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitMembersStates: {
          ...state.unitMembersStates,
          [action.key]: 'success',
        },
        unitMembersResults: {
          ...state.unitMembersResults,
          [action.key]: action.data,
        },
      }

    case unitMembersIndexActions.REQUEST_FAILURE:
      return {
        ...state,
        unitMembersStates: {
          ...state.unitMembersStates,
          [action.key]: 'failure',
        },
      }

    // Unit Member Create
    case unitMembersCreateActions.REQUEST:
      return {
        ...state,
        unitMemberCreateStates: {
          ...state.unitMemberCreateStates,
          [action.key]: 'loading',
        },
      }

    case unitMembersCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitMemberCreateStates: {
          ...state.unitMemberCreateStates,
          [action.key]: 'success',
        },
        unitMembersStates: {},
        unitStates: {},
        teamUsersStates: {},
      }

    case unitMembersCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        unitMemberCreateStates: {
          ...state.unitMemberCreateStates,
          [action.key]: 'failure',
        },
      }

    // Unit Member Destroy
    case unitMembersDestroyActions.REQUEST:
      return {
        ...state,
        unitMemberDestroyStates: {
          ...state.unitMemberDestroyStates,
          [action.key]: 'loading',
        },
      }

    case unitMembersDestroyActions.REQUEST_SUCCESS:
      return {
        ...state,
        unitMemberDestroyStates: {
          ...state.unitMemberDestroyStates,
          [action.key]: 'success',
        },
        unitMembersStates: {},
        teamUsersStates: {},
        unitStates: {},
      }

    case unitMembersDestroyActions.REQUEST_FAILURE:
      return {
        ...state,
        unitMemberDestroyStates: {
          ...state.unitMemberDestroyStates,
          [action.key]: 'failure',
        },
      }

    // Users Export
    case exportUsersActions.REQUEST:
      return {
        ...state,
        exportUsersState: 'loading',
      }

    case exportUsersActions.REQUEST_SUCCESS:
      return {
        ...state,
        exportUsersState: 'success',
      }

    case exportUsersActions.REQUEST_FAILURE:
      return {
        ...state,
        exportUsersState: 'failure',
      }

    // get custom emails
    case indexCustomEmailActions.REQUEST:
      return {
        ...state,
        getCustomEmailState: 'loading',
      }

    case indexCustomEmailActions.REQUEST_SUCCESS:
      return {
        ...state,
        getCustomEmailState: 'success',
        getCustomEmailResult: action.data,
      }

    case indexCustomEmailActions.REQUEST_FAILURE:
      return {
        ...state,
        getCustomEmailState: 'failure',
      }

    // Create custom emails
    case createCustomEmailActions.REQUEST:
      return {
        ...state,
        createCustomEmailState: 'loading',
      }

    case createCustomEmailActions.REQUEST_SUCCESS:
      return {
        ...state,
        createCustomEmailState: 'success',
      }

    case createCustomEmailActions.REQUEST_FAILURE:
      return {
        ...state,
        createCustomEmailState: 'failure',
      }

    // Update custom emails
    case updateCustomEmailActions.REQUEST:
      return {
        ...state,
        updateCustomEmailStates: {
          ...state.updateCustomEmailStates,
          [action.key]: 'loading',
        },
      }

    case updateCustomEmailActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateCustomEmailStates: {
          ...state.updateCustomEmailStates,
          [action.key]: 'success',
        },
      }

    case updateCustomEmailActions.REQUEST_FAILURE:
      return {
        ...state,
        updateCustomEmailStates: {
          ...state.updateCustomEmailStates,
          [action.key]: 'failure',
        },
      }

    // Create custom content
    case createCustomContentActions.REQUEST:
      return {
        ...state,
        createCustomContentState: 'loading',
      }

    case createCustomContentActions.REQUEST_SUCCESS:
      return {
        ...state,
        createCustomContentState: 'success',
      }

    case createCustomContentActions.REQUEST_FAILURE:
      return {
        ...state,
        createCustomContentState: 'failure',
      }

    // Get custom content
    case indexCustomContentActions.REQUEST:
      return {
        ...state,
        getCustomContentState: 'loading',
      }

    case indexCustomContentActions.REQUEST_SUCCESS:
      return {
        ...state,
        getCustomContentState: 'success',
        getCustomContentResult: action.data,
      }

    case indexCustomContentActions.REQUEST_FAILURE:
      return {
        ...state,
        getCustomContentState: 'failure',
      }

    // Get custom content profile
    case indexCustomContentProfileActions.REQUEST:
      return {
        ...state,
        getCustomContentProfileState: 'loading',
      }

    case indexCustomContentProfileActions.REQUEST_SUCCESS:
      return {
        ...state,
        getCustomContentProfileState: 'success',
        getCustomContentProfileResult: action.data,
      }

    case indexCustomContentProfileActions.REQUEST_FAILURE:
      return {
        ...state,
        getCustomContentProfileState: 'failure',
      }

    // Test custom email
    case testCustomEmailActions.REQUEST:
      return {
        ...state,
        testCustomEmailState: 'loading',
      }

    case testCustomEmailActions.REQUEST_SUCCESS:
      return {
        ...state,
        testCustomEmailState: 'success',
      }

    case testCustomEmailActions.REQUEST_FAILURE:
      return {
        ...state,
        testCustomEmailState: 'failure',
      }

    // get white label assessment
    case indexWhiteLabelActions.REQUEST:
      return {
        ...state,
        getWhiteLabelAssessmentsState: 'loading',
      }

    case indexWhiteLabelActions.REQUEST_SUCCESS:
      return {
        ...state,
        getWhiteLabelAssessmentsState: 'success',
        getWhiteLabelAssessmentsResults: action.data,
      }

    case indexWhiteLabelActions.REQUEST_FAILURE:
      return {
        ...state,
        getWhiteLabelAssessmentsState: 'failure',
      }

    // Update white label assessment
    case updateWhiteLabelActions.REQUEST:
      return {
        ...state,
        updateWhiteLabelAssessmentsState: 'loading',
      }

    case updateWhiteLabelActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateWhiteLabelAssessmentsState: 'success',
      }

    case updateWhiteLabelActions.REQUEST_FAILURE:
      return {
        ...state,
        updateWhiteLabelAssessmentsState: 'failure',
      }

    // Invite Candidate
    case inviteCandidateActions.REQUEST:
      return {
        ...state,
        inviteCandidateStates: {
          ...state.inviteCandidateStates,
          [action.key]: 'loading',
        },
      }

    case inviteCandidateActions.REQUEST_SUCCESS:
      return {
        ...state,
        inviteCandidateStates: {
          ...state.inviteCandidateStates,
          [action.key]: 'success',
        },
      }

    case inviteCandidateActions.REQUEST_FAILURE:
      return {
        ...state,
        inviteCandidateStates: {
          ...state.inviteCandidateStates,
          [action.key]: 'failure',
        },
      }

    // Export Stats
    case statsExportActions.REQUEST:
      return {
        ...state,
        statsExportStates: {
          ...state.statsExportStates,
          [action.key]: 'loading',
        },
      }

    case statsExportActions.REQUEST_SUCCESS:
      return {
        ...state,
        statsExportStates: {
          ...state.statsExportStates,
          [action.key]: 'success',
        },
      }

    case statsExportActions.REQUEST_FAILURE:
      return {
        ...state,
        statsExportStates: {
          ...state.statsExportStates,
          [action.key]: 'failure',
        },
      }

    // Send Customer Success Manager Email
    case sendCustomerSuccessManagerEmailActions.REQUEST:
      return {
        ...state,
        sendCustomerSuccessManagerEmailState: 'loading',
      }

    case sendCustomerSuccessManagerEmailActions.REQUEST_SUCCESS:
      return {
        ...state,
        sendCustomerSuccessManagerEmailState: 'success',
      }

    case sendCustomerSuccessManagerEmailActions.REQUEST_FAILURE:
      return {
        ...state,
        sendCustomerSuccessManagerEmailState: 'failure',
      }

    // Show Simple Team
    case teamSimpleActions.REQUEST:
      return {
        ...state,
        loadTeamSimpleStates: {
          ...state.loadTeamSimpleStates,
          [action.key]: 'loading',
        },
      }

    case teamSimpleActions.REQUEST_SUCCESS:
      return {
        ...state,
        loadTeamSimpleStates: {
          ...state.loadTeamSimpleStates,
          [action.key]: 'success',
        },
        loadTeamSimpleResults: {
          ...state.loadTeamSimpleResults,
          [action.key]: action.data,
        },
      }

    case teamSimpleActions.REQUEST_FAILURE:
      return {
        ...state,
        loadTeamSimpleStates: {
          ...state.loadTeamSimpleStates,
          [action.key]: 'failure',
        },
      }

    case teamInviteActions.REQUEST:
      return {
        ...state,
        loadTeamInvitesStates: {
          ...state.loadTeamInvitesStates,
          [action.id]: 'loading',
        },
      }

    case teamInviteActions.REQUEST_SUCCESS:
      return {
        ...state,
        loadTeamInvitesStates: {
          ...state.loadTeamInvitesStates,
          [action.id]: 'success',
        },
        loadTeamInvitesResults: {
          ...state.loadTeamInvitesResults,
          [action.id]: action.data,
        },
      }

    case teamInviteActions.REQUEST_FAILURE:
      return {
        ...state,
        loadTeamInvitesStates: {
          ...state.loadTeamSimpleStates,
          [action.id]: 'failure',
        },
      }

    default:
      return state
  }
}
