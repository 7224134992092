import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api, AuthedHeaders } from '@dashboard/lib/api'
import { APIResponse, ProfileContent } from '@dashboard/lib/types'

export interface ProfileDataOptions {
  limit?: number
  job_behavior?: boolean
}

export function profileDiscDataKey(
  profileId: string,
  types: string[][],
  options?: ProfileDataOptions,
) {
  return `${profileId}-${types.join(
    '-',
  )}-${options?.limit}-${options?.job_behavior}`
}

export const REQUEST = 'PROFILE_DATA_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PROFILE_DATA_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: ProfileContent
}
const requestSuccess = (
  key: string,
  data: ProfileContent,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PROFILE_DATA_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

function getProfileDataRequest(
  profileId: string,
  types: string[][],
  options: ProfileDataOptions = {},
  headers?: AuthedHeaders,
) {
  const dataTags: Record<string, string[]> = {}
  types.forEach((types, index) => {
    dataTags[index] = types
  })

  return Api(headers).post<APIResponse<ProfileContent>>(
    `/profiles/${profileId}/data`,
    {
      data_tags: dataTags,
      limit: options.limit || 3,
      job_behavior: options.job_behavior || null,
    },
  )
}

export function getProfileData(
  profileId: string,
  types: string[][],
  options: ProfileDataOptions = {},
  headers?: AuthedHeaders,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<ProfileContent>((resolve, reject) => {
      const key = profileDiscDataKey(profileId, types, options)
      dispatch(request(key))

      getProfileDataRequest(profileId, types, options, headers)
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling getProfileData (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
