import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'
import { ChangeEvent } from 'react'

const REQUEST = 'COMPANY_SUGGEST_REQUEST'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'COMPANY_SUGGEST_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: []
}
const requestSuccess = (data: []): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

const REQUEST_FAILURE = 'COMPANY_SUGGEST_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface SuggestedCompaniesResponse {
  name: string
  domain: string
  logo: string
}

export const suggestCompany = (text: ChangeEvent<HTMLInputElement>) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<SuggestedCompaniesResponse>((resolve, reject) => {
      dispatch(request())
      if (!text.target.value || text.target.value.match(/^\s+$/)) {
        return dispatch(requestSuccess([]))
      }

      axios
        .get(
          `https://autocomplete.clearbit.com/v1/companies/suggest?query=${text.target.value}`,
        )
        .then(({ data }) => {
          dispatch(requestSuccess(data))
          resolve(data)
        })
        .catch(err => {
          reject(
            `Error calling suggestCompany (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
