import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { sessionToken } from '@dashboard/lib/user'

export const REQUEST = 'PASSWORD_RESET_LOAD_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PASSWORD_RESET_LOAD_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  token: string
}
const requestSuccess = (token: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  token,
})

export const REQUEST_FAILURE = 'PASSWORD_RESET_LOAD_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function resetPassword(token: string, password: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      const session_token = sessionToken()
      const session_app = 'dashboard'
      const params = {
        user: { reset_password_token: token, new_password: password },
        session_token,
        session_app,
      }

      Api()
        .post('/passwords', params)
        .then(({ data }) => {
          dispatch(requestSuccess(data.data.access_token))
          resolve(data.data.access_token)
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling resetPassword (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
