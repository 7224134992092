export const REQUEST = 'SEND_RECEIPT_START'
type RequestAction = { type: typeof REQUEST; chargeId: string }

export const REQUEST_SUCCESS = 'SEND_RECEIPT_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  chargeId: string
}

export const REQUEST_FAILURE = 'SEND_RECEIPT_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  chargeId: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
