import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export interface PlaybookNoteCreateParams {
  playbook_id: string
  section_id: string
  text: string
}

export function createPlaybookNoteQueryKey({
  playbook_id,
  section_id,
  text,
}: PlaybookNoteCreateParams) {
  return `${playbook_id}-${section_id}-${text}`
}

export const REQUEST = 'PLAYBOOKS_NOTES_CREATE_START'
interface RequestAction {
  key: string
  type: typeof REQUEST
}
const request = (key: string): RequestAction => ({
  key,
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PLAYBOOKS_NOTES_CREATE_SUCCESS'
interface RequestSuccessAction {
  key: string
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  key,
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'PLAYBOOKS_NOTES_CREATE_FAILURE'
interface RequestFailureAction {
  key: string
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  key,
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function createPlaybookNote(params: PlaybookNoteCreateParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = createPlaybookNoteQueryKey(params)
      const { playbook_id, section_id, text } = params
      dispatch(request(key))

      Api()
        .post(`/playbooks/${playbook_id}/notes`, { section_id, text })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch(error => {
          dispatch(requestFailure(key, error))
          reject(error.response?.data)
        })
    })
  }
}
