import { APIPagedResponse, Profile, Personality } from '@dashboard/lib/types'

interface PeerAssessmentContributor {
  id: string
  created_by: Profile
  personality: Personality
  profile: Profile
}

export type PeerAssessmentIndexResponse =
  APIPagedResponse<PeerAssessmentContributor>

export const REQUEST = 'GET_PEER_ASSESSMENT_START'
interface RequestAction {
  type: typeof REQUEST
  id: string
}

export const REQUEST_SUCCESS = 'GET_PEER_ASSESSMENT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  id: string
  data: PeerAssessmentIndexResponse
}

export const REQUEST_FAILURE = 'GET_PEER_ASSESSMENT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  id: string
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
