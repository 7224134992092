import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export const REQUEST = 'TEAM_CUSTOMER_SUCCESS_MANAGER_START'
const request = () =>
  ({
    type: REQUEST,
  }) as const

export const REQUEST_SUCCESS = 'TEAM_CUSTOMER_SUCCESS_MANAGER_SUCCESS'
const requestSuccess = () =>
  ({
    type: REQUEST_SUCCESS,
  }) as const

export const REQUEST_FAILURE = 'TEAM_CUSTOMER_SUCCESS_MANAGER_FAILURE'
const requestFailure = () =>
  ({
    type: REQUEST_FAILURE,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function sendCustomerSuccessManagerEmail(
  teamId: string,
  text: string,
  email?: string,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())
      const params = { text, email: email || '' }
      Api()
        .post(`/teams/${teamId}/customer_success`, params)
        .then(() => {
          dispatch(requestSuccess())

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling sendCustomerSuccessManagerEmail (Status: ${err
              .response?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
