import { Profile } from '@dashboard/lib/types'

export const REQUEST = 'GUESS_LOAD_START'
interface RequestAction {
  type: typeof REQUEST
  uuid: string
}

export const REQUEST_SUCCESS = 'GUESS_LOAD_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  uuid: string
  profile: Profile
}

export const REQUEST_FAILURE = 'GUESS_LOAD_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  uuid: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
