import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export const REQUEST = 'PROFILE_TAGS_CREATE_START'
type requestAction = { type: typeof REQUEST }
const request = (): requestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PROFILE_TAGS_CREATE_SUCCESS'
type requestSuccessAction = {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): requestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'PROFILE_TAGS_CREATE_FAILURE'
type requestFailureAction = { type: typeof REQUEST_FAILURE }
const requestFailure = (): requestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = requestAction | requestSuccessAction | requestFailureAction

export function createTag(name: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      Api()
        .post('/tags', { name })
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling createTag (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
