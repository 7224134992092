import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { GuessProfileAttributes } from '@dashboard/lib/types'

export const REQUEST = 'GUESS_CREATE_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'GUESS_CREATE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: { uuid: string }
}
const requestSuccess = (data: { uuid: string }): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_EXISTS = 'GUESS_CREATE_EXISTS'
interface RequestExistsAction {
  type: typeof REQUEST_EXISTS
  error: unknown
}
const requestExists = (error: unknown): RequestExistsAction => ({
  type: REQUEST_EXISTS,
  error,
})

export const REQUEST_FAILURE = 'GUESS_CREATE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action =
  | RequestAction
  | RequestSuccessAction
  | RequestExistsAction
  | RequestFailureAction

export function createGuessProfile(attributes: GuessProfileAttributes) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(request())

      const {
        first_name,
        last_name,
        email,
        disc_type,
        degrees,
        intensity,
        sources,
      } = attributes

      let personality: Record<string, unknown> = { disc_type }
      if (degrees && intensity)
        personality = { ...personality, degrees, intensity }

      const enrichment = {
        profile: {
          first_name,
          last_name,
          email: email ? email : null,
          contributor_app_name: 'dashboard',
        },
        personality,
        sources,
      }

      Api()
        .post('/unowned_profiles', enrichment)
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))
          resolve(data.data)
        })
        .catch(err => {
          if (err.response.status == 400) {
            dispatch(requestExists(err))
          } else {
            dispatch(requestFailure(err))
          }
          reject(
            `Error calling createGuessProfile (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
