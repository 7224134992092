export const REQUEST = 'REFERRAL_REMINDER_START'
interface RequestAction {
  type: typeof REQUEST
  referralID: string
}

export const REQUEST_SUCCESS = 'REFERRAL_REMINDER_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  referralID: string
}

export const REQUEST_FAILURE = 'REFERRAL_REMINDER_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  referralID: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
