import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'UPDATE_CANDIDATE_SCORES_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'UPDATE_CANDIDATE_SCORES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'UPDATE_CANDIDATE_SCORES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: string
}
const requestFailure = (key: string, error: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface updateCandidateScoresParams {
  playbook_id: string
  responses: {
    datum_id: string
    score: number
  }[]
}

export function updateSurvey(params: updateCandidateScoresParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const playbookId = params.playbook_id
      dispatch(request(playbookId))

      Api()
        .put(`job_interview_assessments/${playbookId}`, {
          responses: params.responses,
        })
        .then(() => {
          dispatch(requestSuccess(playbookId))
          resolve()
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(playbookId, errorMessage))
          reject(
            `Error calling updateSurvey (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
