import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api, AuthedHeaders } from '@dashboard/lib/api'
import { APIResponse, JobResponse } from '@dashboard/lib/types'

export const REQUEST = 'JOB_SHOW_START'
interface RequestAction {
  type: typeof REQUEST
  jobId: string
}
const request = (jobId: string): RequestAction => ({
  type: REQUEST,
  jobId,
})

export const REQUEST_SUCCESS = 'JOB_SHOW_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  jobId: string
  data: JobResponse
}
const requestSuccess = (
  jobId: string,
  data: JobResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  jobId,
  data,
})

export const REQUEST_FAILURE = 'JOB_SHOW_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  jobId: string
  error: unknown
}
const requestFailure = (
  jobId: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  jobId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadJobRequest(jobId: string, authedHeaders?: AuthedHeaders) {
  return Api(authedHeaders).get<APIResponse<JobResponse>>(`jobs/${jobId}`)
}

export function loadJob(jobId: string, authedHeaders?: AuthedHeaders) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<JobResponse>((resolve, reject) => {
      dispatch(request(jobId))
      loadJobRequest(jobId, authedHeaders)
        .then(({ data }) => {
          dispatch(requestSuccess(jobId, data.data))

          resolve(data.data)
        })
        .catch(error => {
          dispatch(requestFailure(jobId, error))

          reject(error.response?.data)
        })
    })
  }
}
