import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { PagedOptions } from '@dashboard/lib/types'

export interface TeamStatsExportOptions extends PagedOptions {
  teamId: string
  range: string
  profile_id?: string
  query?: string
  sort?: string
}

export const getTeamStatsExportQueryKey = (options: TeamStatsExportOptions) => {
  return JSON.stringify(options)
}

export interface NextTeamStatsExportOptions extends PagedOptions {
  teamId: string
  range: string
  profile_id?: string
}

export const nextGetTeamStatsExportQueryKey = (
  options: NextTeamStatsExportOptions,
) => {
  return JSON.stringify(options)
}

export const REQUEST = 'TEAM_STATS_LOAD_EXPORT_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEAM_STATS_LOAD_EXPORT_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'TEAM_STATS_LOAD_EXPORT_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

interface StatsRouteParams {
  range?: string
  profile_id?: string
  query?: string
  sort?: string
}

export function getTeamStatsExport(statParams: TeamStatsExportOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { teamId, range, profile_id, query, sort } = statParams
      const key = getTeamStatsExportQueryKey(statParams)
      dispatch(request(key))

      const params: StatsRouteParams = {
        range,
      }

      if (profile_id) params.profile_id = profile_id
      if (query) params.query = query
      if (sort) params.sort = sort

      Api()
        .get(`/teams/${teamId}/members/export_usage`, {
          params,
        })
        .then(() => {
          dispatch(requestSuccess(key))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling getTeamStatsExport (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}

export function nextGetTeamStatsExport(statParams: TeamStatsExportOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const { teamId, range, profile_id, sort } = statParams
      const key = getTeamStatsExportQueryKey(statParams)
      dispatch(request(key))

      const params: StatsRouteParams = {
        range,
      }

      if (profile_id) params.profile_id = profile_id
      if (sort) params.sort = sort

      Api()
        .get(`/teams/${teamId}/members/export_usage`, {
          params,
        })
        .then(() => {
          dispatch(requestSuccess(key))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling nextGetTeamStatsExport (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
