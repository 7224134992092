import * as AdaptiveMessagingActions from '@dashboard/store/actions/adaptive_messaging/get_suggestions'
import { DiscType, ContentSuggestion } from '@dashboard/lib/types'

export type State = {
  discSuggestionResults: {
    [individualDisc in DiscType]?: ContentSuggestion[]
  }
}

const INITIAL_STATE: State = { discSuggestionResults: {} }

type Action = AdaptiveMessagingActions.Action

export default function adaptiveMessagingReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Search Coach Data
    case AdaptiveMessagingActions.REQUEST:
      return {
        ...state,
        discSuggestionResults: {
          ...state.discSuggestionResults,
        },
      }

    case AdaptiveMessagingActions.REQUEST_SUCCESS:
      return {
        ...state,
        discSuggestionResults: {
          ...state.discSuggestionResults,
          [action.query]: action.data,
        },
      }

    case AdaptiveMessagingActions.REQUEST_FAILURE:
      return {
        ...state,
        discSuggestionResults: {
          ...state.discSuggestionResults,
          [action.query]: 'failure',
        },
      }

    default:
      return state
  }
}
