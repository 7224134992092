import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIResponse, JobExpectationQuestion } from '@dashboard/lib/types'

export const REQUEST = 'LOAD_JOB_QUESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'LOAD_JOB_QUESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: JobExpectationQuestion[]
}
const requestSuccess = (
  data: JobExpectationQuestion[],
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'LOAD_JOB_QUESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadQuestions() {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<JobExpectationQuestion[]>((resolve, reject) => {
      dispatch(request())

      Api()
        .get<APIResponse<JobExpectationQuestion[]>>(
          '/public/job_survey_questions',
        )
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))

          resolve(data.data)
        })
        .catch(error => {
          dispatch(requestFailure(error))

          reject(error.response?.data)
        })
    })
  }
}
