export const REQUEST = 'INVITE_INDIVIDUAL_USERS_START'
type RequestAction = { type: typeof REQUEST; email: string }

export const REQUEST_SUCCESS = 'INVITE_INDIVIDUAL_USERS_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  email: string
}

export const REQUEST_FAILURE = 'INVITE_INDIVIDUAL_USERS_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  email: string
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
