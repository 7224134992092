import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface JobTitleResponse {
  job_title: string
  degrees: number
  intensity: number
}

export const REQUEST = 'JOB_TITLE_AVERAGE_START'
interface RequestAction {
  type: typeof REQUEST
  jobTitle: string
}
const request = (jobTitle: string): RequestAction => ({
  type: REQUEST,
  jobTitle,
})

export const REQUEST_SUCCESS = 'JOB_TITLE_AVERAGE_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  jobTitle: string
  data: JobTitleResponse
}
const requestSuccess = (
  jobTitle: string,
  data: JobTitleResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  jobTitle,
  data,
})

export const REQUEST_FAILURE = 'JOB_TITLE_AVERAGE_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  jobTitle: string
  error: unknown
}
const requestFailure = (
  jobTitle: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  jobTitle,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getAverage(job_title: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<JobTitleResponse>((resolve, reject) => {
      dispatch(request(job_title))

      Api()
        .post<APIResponse<JobTitleResponse>>('/analysis/job_title', {
          job_title,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(job_title, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(job_title, err))
          reject(
            `Error calling getAverage (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
