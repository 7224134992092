import * as textAnalysisActions from '@dashboard/store/actions/analysis/text_analysis'
import * as jobCompatibilityActions from '@dashboard/store/actions/analysis/job_compatibility'

import { LoadingStates, Datum } from '@dashboard/lib/types'
import { JobCompatibilityResponse } from '@dashboard/store/actions/analysis/job_compatibility'

export interface State {
  textAnalysisState: LoadingStates
  textAnalysisResults: Datum | null
  jobCompatibilityState: LoadingStates
  jobCompatibilityResults: JobCompatibilityResponse | null
}

const INITIAL_STATE: State = {
  textAnalysisState: 'default',
  textAnalysisResults: null,
  jobCompatibilityState: 'default',
  jobCompatibilityResults: null,
}

type Action = textAnalysisActions.Action | jobCompatibilityActions.Action

export default function analysisReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Get Public Relationship Data
    case textAnalysisActions.REQUEST:
      return {
        ...state,
        textAnalysisState: 'loading',
      }
    case textAnalysisActions.REQUEST_SUCCESS:
      return {
        ...state,
        textAnalysisState: 'success',
        textAnalysisResults: action.data,
      }
    case textAnalysisActions.REQUEST_FAILURE:
      return {
        ...state,
        textAnalysisState: 'failure',
      }

    // Get Public Job Compatibility
    case jobCompatibilityActions.REQUEST:
      return {
        ...state,
        jobCompatibilityState: 'loading',
      }
    case jobCompatibilityActions.REQUEST_SUCCESS:
      return {
        ...state,
        jobCompatibilityState: 'success',
        jobCompatibilityResults: action.data,
      }
    case jobCompatibilityActions.REQUEST_FAILURE:
      return {
        ...state,
        jobCompatibilityState: 'failure',
      }

    default:
      return state
  }
}
