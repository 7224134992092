import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export const REQUEST = 'URL_SHORTEN_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  url: string
}
const request = (url: string): RequestAction => ({
  type: REQUEST,
  url,
})

export const REQUEST_SUCCESS = 'URL_SHORTEN_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  url: string
  shortened: string
}
const requestSuccess = (
  url: string,
  shortened: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  url,
  shortened,
})

export const REQUEST_FAILURE = 'URL_SHORTEN_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  url: string
}
const requestFailure = (url: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  url,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const shortenURL = (url: string) => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  ): Promise<string> => {
    return new Promise<string>(resolve => {
      dispatch(request(url))

      Api()
        .post<APIResponse<{ url: string }>>('s', { url })
        .then(({ data }) => {
          dispatch(requestSuccess(url, data.data.url))
          resolve(data.data.url)
        })
        .catch(() => {
          dispatch(requestFailure(url))
        })
    })
  }
}
