import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export const REQUEST = 'INVITE_USERS_START'
type RequestAction = { type: typeof REQUEST; emails: Array<string> }
const request = (emails: Array<string>): RequestAction => ({
  type: REQUEST,
  emails,
})

export const REQUEST_SUCCESS = 'INVITE_USERS_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  emails: Array<string>
}
const requestSuccess = (emails: Array<string>): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  emails,
})

export const REQUEST_FAILURE = 'INVITE_USERS_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  emails: Array<string>
  error: unknown
}
const requestFailure = (
  emails: Array<string>,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  emails,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function sendInvites(emails: Array<string>) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(emails))

      Api()
        .post('/invites', { emails })
        .then(() => {
          dispatch(requestSuccess(emails))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(emails, err))
          reject(
            `Error calling sendInvites (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
