import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface CareerAlignmentQuestion {
  id: string
  text: string
}

export const REQUEST = 'CAREER_ALIGNMENT_QUESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'CAREER_ALIGNMENT_QUESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  careerAlignmentQuestions: CareerAlignmentQuestion[]
}
const requestSuccess = (
  careerAlignmentQuestions: CareerAlignmentQuestion[],
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  careerAlignmentQuestions,
})

export const REQUEST_FAILURE = 'CAREER_ALIGNMENT_QUESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export const getCareerAlignmentQuestions = () => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<CareerAlignmentQuestion[]>((resolve, reject) => {
      dispatch(request())

      Api()
        .get<APIResponse<CareerAlignmentQuestion[]>>(
          '/career_alignment_questions',
        )
        .then(({ data }) => {
          const randomizedQuestions = (data.data || []).sort(
            () => Math.random() - 0.5,
          )
          dispatch(requestSuccess(randomizedQuestions))
          resolve(randomizedQuestions)
        })
        .catch(err => {
          dispatch(requestFailure())
          reject(
            `Error calling getCareerAlignmentQuestions (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
