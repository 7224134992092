export const REQUEST = 'REMIND_ALL_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'REMIND_ALL_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'REMIND_ALL_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
