import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { Products } from '@dashboard/lib/types'

export const REQUEST = 'UPDATE_PRODUCT_SUBSCRIPTION_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'UPDATE_PRODUCT_SUBSCRIPTION_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
}
const requestSuccess = (): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
})

export const REQUEST_FAILURE = 'UPDATE_PRODUCT_SUBSCRIPTION_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: { response: { data: { message: string } } }
}
const requestFailure = (error: {
  response: { data: { message: string } }
}): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

interface UpdateParams {
  plan_id: string
  quantity?: number
}

export function updateProductSubscription(
  product_id: string,
  plan_id: string,
  product: Products,
  quantity?: number,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request())

      let url = '/subscriptions'
      if (product == 'sales') url += '/sales'
      if (product == 'leadership') url += '/leadership'

      const params: UpdateParams = { plan_id }

      if (quantity) params.quantity = quantity

      Api()
        .put(`${url}/${product_id}`, params)
        .then(() => {
          dispatch(requestSuccess())
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling updateSubscription (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
