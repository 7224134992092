import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export const REQUEST = 'PLAYBOOKS_NOTES_DESTROY_START'
interface RequestAction {
  key: string
  type: typeof REQUEST
}
const request = (key: string): RequestAction => ({
  key,
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'PLAYBOOKS_NOTES_DESTROY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'PLAYBOOKS_NOTES_DESTROY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function destroyPlaybookNote(playbookId: string, noteId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(noteId))

      Api()
        .delete(`/playbooks/${playbookId}/notes/${noteId}`)
        .then(() => {
          dispatch(requestSuccess(noteId))
          resolve()
        })
        .catch(error => {
          dispatch(requestFailure(noteId, error))
          reject(error.response?.data)
        })
    })
  }
}
