import { Datum } from '@dashboard/lib/types'

export const REQUEST = 'PUBLIC_TEXT_ANALYSIS_REQUEST'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'PUBLIC_TEXT_ANALYSIS_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: Datum
}

export const REQUEST_FAILURE = 'PUBLIC_TEXT_ANALYSIS_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
