import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse } from '@dashboard/lib/types'
import { AxiosError } from 'axios'

export const REQUEST = 'TEMPLATE_DESTROY_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'TEMPLATE_DESTROY_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'TEMPLATE_DESTROY_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  errorMessage: string
}
const requestFailure = (
  key: string,
  errorMessage: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  errorMessage,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function destroyTemplate(templateId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(templateId))

      Api()
        .delete<APIResponse<{ id: string }>>(
          `/playbook_templates/${templateId}`,
        )
        .then(() => {
          dispatch(requestSuccess(templateId))
          resolve()
        })
        .catch((err: AxiosError<{ message: string }>) => {
          dispatch(
            requestFailure(templateId, err.response?.data?.message || ''),
          )
          reject(err.response?.data.message)
        })
    })
  }
}
