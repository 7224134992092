import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

export function createTeamUnitMemberKey(unitId: string, profile_id: string) {
  return `${unitId}-${profile_id}`
}

export const REQUEST = 'TEAM_UNIT_MEMBERS_CREATE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'TEAM_UNIT_MEMBERS_CREATE_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'TEAM_UNIT_MEMBERS_CREATE_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function createTeamUnitMember(unitId: string, profile_id: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = createTeamUnitMemberKey(unitId, profile_id)
      dispatch(request(key))

      Api()
        .post(`/team_units/${unitId}/members`, {
          profile_id,
        })
        .then(() => {
          dispatch(requestSuccess(key))

          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling createTeamUnitMember (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
