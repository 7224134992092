export const REQUEST = 'USER_UPDATE_PRIVACY_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'USER_UPDATE_PRIVACY_SUCCESS'
type RequestSuccessAction = { type: typeof REQUEST_SUCCESS }

export const REQUEST_FAILURE = 'USER_UPDATE_PRIVACY_FAILURE'
type RequestFailureAction = { type: typeof REQUEST_FAILURE }

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
