import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api, AuthedHeaders } from '@dashboard/lib/api'
import { APIResponse, ProfileContent } from '@dashboard/lib/types'

export interface ProfileDataOptions {
  limit?: number
}

export function playbookProfileDiscDataKey(
  playbookId: string,
  profileId: string,
  types: string[][],
  options?: ProfileDataOptions,
) {
  return `${playbookId}-${profileId}-${types.join('-')}-${JSON.stringify(
    options || {},
  )}`
}

export const REQUEST = 'PLAYBOOK_PROFILE_DATA_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PLAYBOOK_PROFILE_DATA_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: ProfileContent
}
const requestSuccess = (
  key: string,
  data: ProfileContent,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'PLAYBOOK_PROFILE_DATA_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getPlaybookProfileData(
  playbookId: string,
  profileId: string,
  types: string[][],
  options: ProfileDataOptions = {},
  headers?: AuthedHeaders,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<ProfileContent>((resolve, reject) => {
      const key = playbookProfileDiscDataKey(
        playbookId,
        profileId,
        types,
        options,
      )
      dispatch(request(key))

      const dataTags: Record<string, string[]> = {}
      types.forEach((types, index) => {
        dataTags[index] = types
      })

      Api(headers)
        .post<APIResponse<ProfileContent>>(
          `/playbooks/${playbookId}/profiles/${profileId}/data`,
          {
            data_tags: dataTags,
            limit: options.limit || 3,
          },
        )
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling getPlaybookProfileData (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
