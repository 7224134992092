'use client'
import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import Button from '@dashboard/components/Button'
import Head from 'next/head'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  pageTitle?: string
  title: string
  subtitle?: string
  cta?: {
    text: string
    href: string
  }
  subCta?: {
    text: string
    href: string
  }
}

const GlacierError: FC<OwnProps> = ({
  pageTitle,
  title,
  subtitle,
  cta,
  subCta,
}: OwnProps) => {
  // Partials
  const renderCta = () => {
    if (!cta) return null

    return <Button href={cta.href}>{cta.text}</Button>
  }

  const renderSubCta = () => {
    if (!subCta) return null

    return (
      <a className={classNames('link')} href={subCta.href}>
        {subCta.text}
      </a>
    )
  }

  return (
    <div>
      <Head>
        <title>{pageTitle || 'Crystal Comparison - Page Not Found'}</title>
      </Head>
      <div>
        <div className={classNames('container')}>
          <div className={classNames('error-sky')}>
            <div className={classNames('error-header')}>
              <div className={classNames('error-header-text')}>{title}</div>
              <p>{subtitle}</p>
              {renderCta()}
              {renderSubCta()}
            </div>
          </div>
          <div className={classNames('iceberg')}>
            <img src="/static/share/images/iceburg.svg"></img>
          </div>
          <div className={classNames('water-wrapper')}>
            <div className={classNames('ocean')}>
              <div className={classNames('wave')}></div>
              <div className={classNames('wave')}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GlacierError
