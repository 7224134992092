import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { PlaybookModuleTypes } from '@dashboard/lib/playbooks'

type StrutureSections = Partial<
  Record<
    PlaybookModuleTypes,
    {
      example_url: string | null
    }
  >
>

export interface StructuresResponse {
  individual: {
    sections: StrutureSections
  }
  relationship: {
    sections: StrutureSections
  }
  group: {
    sections: StrutureSections
  }
  job: {
    sections: StrutureSections
  }
  job_profile: {
    sections: StrutureSections
  }
  job_profiles: {
    sections: StrutureSections
  }
  jobs_profile: {
    sections: StrutureSections
  }
}

export const REQUEST = 'LOAD_STRUCTURES_START'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'LOAD_STRUCTURES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  structures: StructuresResponse
}
const requestSuccess = (
  structures: StructuresResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  structures,
})

export const REQUEST_FAILURE = 'LOAD_STRUCTURES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadStructures() {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<StructuresResponse>((resolve, reject) => {
      dispatch(request())

      Api()
        .get<StructuresResponse>('/playbook_templates/types')
        .then(({ data }) => {
          dispatch(requestSuccess(data))

          resolve(data)
        })
        .catch(error => {
          dispatch(requestFailure(error))

          reject(error.response?.data)
        })
    })
  }
}
