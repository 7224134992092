import * as indexActions from '@dashboard/store/actions/jobs/index'
import * as createActions from '@dashboard/store/actions/jobs/create'
import * as showActions from '@dashboard/store/actions/jobs/show'
import * as destroyActions from '@dashboard/store/actions/jobs/destroy'
import * as updateActions from '@dashboard/store/actions/jobs/update'
import * as surveyQuestionsActions from '@dashboard/store/actions/jobs/surveys/questions'
import * as surveyCreateActions from '@dashboard/store/actions/jobs/surveys/create'
import * as surveyDestroyActions from '@dashboard/store/actions/jobs/surveys/destroy'
import * as surveyCreatePublicActions from '@dashboard/store/actions/jobs/surveys/createPublic'
import * as dataActions from '@dashboard/store/actions/jobs/discData/get'
import * as surveyProfilesActions from '@dashboard/store/actions/jobs/surveys'
import * as candidateProfilesActions from '@dashboard/store/actions/jobs/profiles'
import * as addCandidatesActions from '@dashboard/store/actions/jobs/profiles/add'
import * as removeCandidatesActions from '@dashboard/store/actions/jobs/profiles/remove'
import * as behaviorActions from '@dashboard/store/actions/jobs/behaviors'
import * as loadScoresActions from '@dashboard/store/actions/jobs/scores/index'
import * as createScoresActions from '@dashboard/store/actions/jobs/scores/create'
import * as updateScoresActions from '@dashboard/store/actions/jobs/scores/update'
import * as candidateJobsActions from '@dashboard/store/actions/jobs/forCandidate'
import * as updateJobAccessActions from '@dashboard/store/actions/jobs/access/update'
import * as updateJobTeamAccessActions from '@dashboard/store/actions/jobs/access/team'
import * as createUnownedCandidateActions from '@dashboard/store/actions/jobs/profiles/unowned'
import * as enrichCandidatesActions from '@dashboard/store/actions/jobs/profiles/enrich'
import * as archivedCandidatesActions from '@dashboard/store/actions/jobs/profiles/archived'
import * as updateCandidateArchivedStatusActions from '@dashboard/store/actions/jobs/profiles/update_archived_status'
import * as duplicateJobActions from '@dashboard/store/actions/jobs/duplicate'
import * as activateCandidateActions from '@dashboard/store/actions/jobs/profiles/activate'
import * as destroyCandidateActions from '@dashboard/store/actions/jobs/candidates/destroy'
import * as sendEmailActions from '@dashboard/store/actions/jobs/email/send'
import * as sendReminderActions from '@dashboard/store/actions/jobs/email/remind'
import * as updateSurveyActions from '@dashboard/store/actions/jobs/surveys/update'
import * as compareSurveysActions from '@dashboard/store/actions/jobs/surveys/compare'
import * as updateSurveyAnswerActions from '@dashboard/store/actions/jobs/surveys/updateAnswer'

import {
  LoadingStates,
  APIPagedResponse,
  JobResponse,
  ProfileContent,
  JobExpectationQuestion,
  SurveyProfile,
  CandidateProfile,
  KeyedStateHelper,
  ScoreAverageObject,
} from '@dashboard/lib/types'

export interface State {
  getStates: KeyedStateHelper<LoadingStates>
  getResults: KeyedStateHelper<APIPagedResponse<JobResponse>>
  showStates: KeyedStateHelper<LoadingStates>
  showResults: KeyedStateHelper<JobResponse>
  createStates: KeyedStateHelper<LoadingStates>
  createResults: KeyedStateHelper<string>
  createError: KeyedStateHelper<string>
  addCandidatesState: LoadingStates
  addCandidatesError: string | null
  destroyStates: KeyedStateHelper<LoadingStates>
  updateStates: KeyedStateHelper<LoadingStates>
  surveyQuestionsState: LoadingStates
  surveyQuestionsResults: JobExpectationQuestion[]
  surveyCreateStates: KeyedStateHelper<LoadingStates>
  surveyCreateErrors: KeyedStateHelper<string>
  surveyDestroyStates: KeyedStateHelper<LoadingStates>
  surveyDestroyErrors: KeyedStateHelper<string>
  surveyCreatePublicStates: KeyedStateHelper<LoadingStates>
  surveyCreatePublicErrors: KeyedStateHelper<string>
  dataStates: KeyedStateHelper<LoadingStates>
  data: KeyedStateHelper<ProfileContent>
  surveyProfilesStates: KeyedStateHelper<LoadingStates>
  surveyProfiles: KeyedStateHelper<APIPagedResponse<SurveyProfile>>
  candidateProfilesStates: KeyedStateHelper<LoadingStates>
  candidateProfiles: KeyedStateHelper<APIPagedResponse<CandidateProfile>>
  behaviorsStates: KeyedStateHelper<LoadingStates>
  behaviorsResults: KeyedStateHelper<behaviorActions.JobBehavior[]>
  loadCandidateScoresStates: KeyedStateHelper<LoadingStates>
  loadCandidateScoresResults: KeyedStateHelper<
    APIPagedResponse<ScoreAverageObject>
  >
  createCandidateScoresStates: KeyedStateHelper<LoadingStates>
  updateCandidateScoresStates: KeyedStateHelper<LoadingStates>
  candidateJobsStates: KeyedStateHelper<LoadingStates>
  candidateJobsResults: KeyedStateHelper<APIPagedResponse<JobResponse>>
  updateJobAccessStates: KeyedStateHelper<LoadingStates>
  updateJobTeamAccessStates: KeyedStateHelper<LoadingStates>
  removeCandidatesStates: KeyedStateHelper<LoadingStates>
  createCandidateStates: KeyedStateHelper<LoadingStates>
  createCandidateResults: KeyedStateHelper<string>
  enrichCandidatesStates: KeyedStateHelper<LoadingStates>
  archivedCandidatesStates: KeyedStateHelper<LoadingStates>
  archivedCandidatesResults: KeyedStateHelper<
    APIPagedResponse<CandidateProfile>
  >
  updateCandidateArchivedStatusStates: KeyedStateHelper<LoadingStates>
  duplicateJobStates: KeyedStateHelper<LoadingStates>
  duplicateJobResults: KeyedStateHelper<string>

  sendEmailStates: KeyedStateHelper<LoadingStates>
  sendReminderStates: KeyedStateHelper<LoadingStates>
  activateCandidateStates: KeyedStateHelper<LoadingStates>
  destroyCandidateStates: KeyedStateHelper<LoadingStates>
  updateSurveyStates: KeyedStateHelper<LoadingStates>
  compareSurveysStates: KeyedStateHelper<LoadingStates>
  compareSurveysResults: KeyedStateHelper<compareSurveysActions.CompareSurveysResponse>
  updateSurveyAnswerStates: KeyedStateHelper<LoadingStates>
}

const INITIAL_STATE: State = {
  getStates: {},
  getResults: {},
  showStates: {},
  showResults: {},
  createStates: {},
  createResults: {},
  createError: {},
  addCandidatesState: 'default',
  addCandidatesError: null,
  destroyStates: {},
  updateStates: {},
  surveyQuestionsState: 'default',
  surveyQuestionsResults: [],
  surveyCreateStates: {},
  surveyCreateErrors: {},
  surveyDestroyStates: {},
  surveyDestroyErrors: {},
  surveyCreatePublicStates: {},
  surveyCreatePublicErrors: {},
  dataStates: {},
  data: {},
  surveyProfilesStates: {},
  surveyProfiles: {},
  candidateProfilesStates: {},
  candidateProfiles: {},
  behaviorsStates: {},
  behaviorsResults: {},
  loadCandidateScoresStates: {},
  loadCandidateScoresResults: {},
  createCandidateScoresStates: {},
  updateCandidateScoresStates: {},
  candidateJobsStates: {},
  candidateJobsResults: {},
  updateJobAccessStates: {},
  updateJobTeamAccessStates: {},
  removeCandidatesStates: {},
  createCandidateStates: {},
  createCandidateResults: {},
  enrichCandidatesStates: {},
  archivedCandidatesStates: {},
  archivedCandidatesResults: {},
  updateCandidateArchivedStatusStates: {},
  duplicateJobStates: {},
  duplicateJobResults: {},
  sendEmailStates: {},
  sendReminderStates: {},
  activateCandidateStates: {},
  destroyCandidateStates: {},
  updateSurveyStates: {},
  compareSurveysStates: {},
  compareSurveysResults: {},
  updateSurveyAnswerStates: {},
}

type Action =
  | indexActions.Action
  | showActions.Action
  | createActions.Action
  | addCandidatesActions.Action
  | destroyActions.Action
  | surveyQuestionsActions.Action
  | surveyCreateActions.Action
  | surveyDestroyActions.Action
  | surveyCreatePublicActions.Action
  | updateActions.Action
  | dataActions.Action
  | surveyProfilesActions.Action
  | candidateProfilesActions.Action
  | behaviorActions.Action
  | loadScoresActions.Action
  | createScoresActions.Action
  | updateScoresActions.Action
  | candidateJobsActions.Action
  | updateJobAccessActions.Action
  | updateJobTeamAccessActions.Action
  | removeCandidatesActions.Action
  | createUnownedCandidateActions.Action
  | enrichCandidatesActions.Action
  | archivedCandidatesActions.Action
  | updateCandidateArchivedStatusActions.Action
  | duplicateJobActions.Action
  | sendEmailActions.Action
  | sendReminderActions.Action
  | activateCandidateActions.Action
  | destroyCandidateActions.Action
  | updateSurveyActions.Action
  | compareSurveysActions.Action
  | updateSurveyAnswerActions.Action

export default function jobsReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Index Actions
    case indexActions.REQUEST:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'loading',
        },
      }
    case indexActions.REQUEST_SUCCESS:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'success',
        },
        getResults: {
          ...state.getResults,
          [action.query]: action.data,
        },
      }
    case indexActions.REQUEST_FAILURE:
      return {
        ...state,
        getStates: {
          ...state.getStates,
          [action.query]: 'failure',
        },
      }

    // Show Actions
    case showActions.REQUEST:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.jobId]: 'loading',
        },
      }
    case showActions.REQUEST_SUCCESS:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.jobId]: 'success',
        },
        showResults: {
          ...state.showResults,
          [action.jobId]: action.data,
        },
      }
    case showActions.REQUEST_FAILURE:
      return {
        ...state,
        showStates: {
          ...state.showStates,
          [action.jobId]: 'failure',
        },
      }

    // Create Actions
    case createActions.REQUEST:
      return {
        ...state,
        createStates: {
          ...state.createStates,
          [action.query]: 'loading',
        },
      }
    case createActions.REQUEST_SUCCESS:
      return {
        ...state,
        createStates: {
          ...state.createStates,
          [action.query]: 'success',
        },
        createResults: {
          ...state.createResults,
          [action.query]: action.data,
        },
      }
    case createActions.REQUEST_FAILURE:
      return {
        ...state,
        createStates: {
          ...state.createStates,
          [action.query]: 'failure',
        },
        createError: {
          ...state.createError,
          [action.query]: action.error as string,
        },
      }

    // Destroy Actions
    case destroyActions.REQUEST:
      return {
        ...state,
        destroyStates: {
          ...state.destroyStates,
          [action.key]: 'loading',
        },
      }
    case destroyActions.REQUEST_SUCCESS:
      return {
        ...state,
        destroyStates: {
          ...state.destroyStates,
          [action.key]: 'success',
        },
      }
    case destroyActions.REQUEST_FAILURE:
      return {
        ...state,
        destroyStates: {
          ...state.destroyStates,
          [action.key]: 'failure',
        },
      }

    // Update Actions
    case updateActions.REQUEST:
      return {
        ...state,
        updateStates: {
          ...state.updateStates,
          [action.key]: 'loading',
        },
      }
    case updateActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateStates: {
          ...state.updateStates,
          [action.key]: 'success',
        },
        showStates: {
          ...state.showStates,
          [action.key]: 'default',
        },
      }
    case updateActions.REQUEST_FAILURE:
      return {
        ...state,
        updateStates: {
          ...state.updateStates,
          [action.key]: 'failure',
        },
      }

    // Add Candidates Actions
    case addCandidatesActions.REQUEST:
      return {
        ...state,
        addCandidatesState: 'loading',
      }
    case addCandidatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        addCandidatesState: 'success',
        candidateProfilesStates: {},
      }
    case addCandidatesActions.REQUEST_FAILURE:
      return {
        ...state,
        addCandidatesState: 'failure',
        addCandidatesError: action.error as string,
      }

    // Survey Create Actions
    case surveyCreateActions.REQUEST:
      return {
        ...state,
        surveyCreateStates: {
          ...state.surveyCreateStates,
          [action.jobId]: 'loading',
        },
      }
    case surveyCreateActions.REQUEST_SUCCESS:
      return {
        ...state,
        surveyCreateStates: {
          ...state.surveyCreateStates,
          [action.jobId]: 'success',
        },
      }
    case surveyCreateActions.REQUEST_FAILURE:
      return {
        ...state,
        surveyCreateStates: {
          ...state.surveyCreateStates,
          [action.jobId]: 'failure',
        },
        surveyCreateErrors: {
          ...state.surveyCreateErrors,
          [action.jobId]: action.error as string,
        },
      }

    // Survey Questions Actions
    case surveyQuestionsActions.REQUEST:
      return {
        ...state,
        surveyQuestionsState: 'loading',
      }
    case surveyQuestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        surveyQuestionsState: 'success',
        surveyQuestionsResults: action.data,
      }
    case surveyQuestionsActions.REQUEST_FAILURE:
      return {
        ...state,
        surveyQuestionsState: 'failure',
      }

    // Survey Destroy Actions
    case surveyDestroyActions.REQUEST:
      return {
        ...state,
        surveyDestroyStates: {
          ...state.surveyDestroyStates,
          [action.jobId]: 'loading',
        },
      }
    case surveyDestroyActions.REQUEST_SUCCESS:
      return {
        ...state,
        surveyDestroyStates: {
          ...state.surveyDestroyStates,
          [action.jobId]: 'success',
        },
      }
    case surveyDestroyActions.REQUEST_FAILURE:
      return {
        ...state,
        surveyDestroyStates: {
          ...state.surveyDestroyStates,
          [action.jobId]: 'failure',
        },
        surveyDestroyErrors: {
          ...state.surveyDestroyErrors,
          [action.jobId]: action.error as string,
        },
      }

    // Survey Create Public Actions
    case surveyCreatePublicActions.REQUEST:
      return {
        ...state,
        surveyCreatePublicStates: {
          ...state.surveyCreatePublicStates,
          [action.jobId]: 'loading',
        },
      }
    case surveyCreatePublicActions.REQUEST_SUCCESS:
      return {
        ...state,
        surveyCreatePublicStates: {
          ...state.surveyCreatePublicStates,
          [action.jobId]: 'success',
        },
      }
    case surveyCreatePublicActions.REQUEST_FAILURE:
      return {
        ...state,
        surveyCreatePublicStates: {
          ...state.surveyCreatePublicStates,
          [action.jobId]: 'failure',
        },
        surveyCreatePublicErrors: {
          ...state.surveyCreatePublicErrors,
          [action.jobId]: action.error as string,
        },
      }

    // Get Disc Data Actions
    case dataActions.REQUEST:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'loading',
        },
      }
    case dataActions.REQUEST_SUCCESS:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'success',
        },
        data: {
          ...state.data,
          [action.key]: action.data,
        },
      }
    case dataActions.REQUEST_FAILURE:
      return {
        ...state,
        dataStates: {
          ...state.dataStates,
          [action.key]: 'failure',
        },
      }

    // Get Survey Profiles
    case surveyProfilesActions.REQUEST:
      return {
        ...state,
        surveyProfilesStates: {
          ...state.surveyProfilesStates,
          [action.key]: 'loading',
        },
      }
    case surveyProfilesActions.REQUEST_SUCCESS:
      return {
        ...state,
        surveyProfilesStates: {
          ...state.surveyProfilesStates,
          [action.key]: 'success',
        },
        surveyProfiles: {
          ...state.surveyProfiles,
          [action.key]: action.data,
        },
      }
    case surveyProfilesActions.REQUEST_FAILURE:
      return {
        ...state,
        surveyProfilesStates: {
          ...state.surveyProfilesStates,
          [action.key]: 'failure',
        },
      }

    // Get Candidate Profiles
    case candidateProfilesActions.REQUEST:
      return {
        ...state,
        candidateProfilesStates: {
          ...state.candidateProfilesStates,
          [action.key]: 'loading',
        },
      }
    case candidateProfilesActions.REQUEST_SUCCESS:
      return {
        ...state,
        candidateProfilesStates: {
          ...state.candidateProfilesStates,
          [action.key]: 'success',
        },
        candidateProfiles: {
          ...state.candidateProfiles,
          [action.key]: action.data,
        },
      }
    case candidateProfilesActions.REQUEST_FAILURE:
      return {
        ...state,
        candidateProfilesStates: {
          ...state.candidateProfilesStates,
          [action.key]: 'failure',
        },
      }

    // Behaviors
    case behaviorActions.REQUEST:
      return {
        ...state,
        behaviorsStates: {
          ...state.behaviorsStates,
          [action.key]: 'loading',
        },
      }
    case behaviorActions.REQUEST_SUCCESS:
      return {
        ...state,
        behaviorsStates: {
          ...state.behaviorsStates,
          [action.key]: 'success',
        },
        behaviorsResults: {
          ...state.behaviorsResults,
          [action.key]: action.data,
        },
      }
    case behaviorActions.REQUEST_FAILURE:
      return {
        ...state,
        behaviorsStates: {
          ...state.behaviorsStates,
          [action.key]: 'failure',
        },
      }

    // Load Candidate Score
    case loadScoresActions.REQUEST:
      return {
        ...state,
        loadCandidateScoresStates: {
          ...state.loadCandidateScoresStates,
          [action.key]: 'loading',
        },
      }
    case loadScoresActions.REQUEST_SUCCESS:
      return {
        ...state,
        loadCandidateScoresStates: {
          ...state.loadCandidateScoresStates,
          [action.key]: 'success',
        },
        loadCandidateScoresResults: {
          ...state.loadCandidateScoresResults,
          [action.key]: action.data,
        },
      }
    case loadScoresActions.REQUEST_FAILURE:
      return {
        ...state,
        loadCandidateScoresStates: {
          ...state.loadCandidateScoresStates,
          [action.key]: 'failure',
        },
      }

    // Create Candidate Score
    case createScoresActions.REQUEST:
      return {
        ...state,
        createCandidateScoresStates: {
          ...state.createCandidateScoresStates,
          [action.key]: 'loading',
        },
      }
    case createScoresActions.REQUEST_SUCCESS:
      return {
        ...state,
        createCandidateScoresStates: {
          ...state.createCandidateScoresStates,
          [action.key]: 'success',
        },
      }
    case createScoresActions.REQUEST_FAILURE:
      return {
        ...state,
        createCandidateScoresStates: {
          ...state.createCandidateScoresStates,
          [action.key]: 'failure',
        },
      }

    // Update Candidate Score
    case updateScoresActions.REQUEST:
      return {
        ...state,
        updateCandidateScoresStates: {
          ...state.updateCandidateScoresStates,
          [action.key]: 'loading',
        },
      }
    case updateScoresActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateCandidateScoresStates: {
          ...state.updateCandidateScoresStates,
          [action.key]: 'success',
        },
      }
    case updateScoresActions.REQUEST_FAILURE:
      return {
        ...state,
        updateCandidateScoresStates: {
          ...state.updateCandidateScoresStates,
          [action.key]: 'failure',
        },
      }

    // Jobs for candidate
    case candidateJobsActions.REQUEST:
      return {
        ...state,
        candidateJobsStates: {
          ...state.candidateJobsStates,
          [action.key]: 'loading',
        },
      }
    case candidateJobsActions.REQUEST_SUCCESS:
      return {
        ...state,
        candidateJobsStates: {
          ...state.candidateJobsStates,
          [action.key]: 'success',
        },
        candidateJobsResults: {
          ...state.candidateJobsResults,
          [action.key]: action.data,
        },
      }
    case candidateJobsActions.REQUEST_FAILURE:
      return {
        ...state,
        candidateJobsStates: {
          ...state.candidateJobsStates,
          [action.key]: 'failure',
        },
      }

    // Update Job Access
    case updateJobAccessActions.REQUEST:
      return {
        ...state,
        updateJobAccessStates: {
          ...state.updateJobAccessStates,
          [action.key]: 'loading',
        },
      }
    case updateJobAccessActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateJobAccessStates: {
          ...state.updateJobAccessStates,
          [action.key]: 'success',
        },
      }
    case updateJobAccessActions.REQUEST_FAILURE:
      return {
        ...state,
        updateJobAccessStates: {
          ...state.updateJobAccessStates,
          [action.key]: 'failure',
        },
      }

    // Update Job Team Access
    case updateJobTeamAccessActions.REQUEST:
      return {
        ...state,
        updateJobTeamAccessStates: {
          ...state.updateJobTeamAccessStates,
          [action.key]: 'loading',
        },
      }
    case updateJobTeamAccessActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateJobTeamAccessStates: {
          ...state.updateJobTeamAccessStates,
          [action.key]: 'success',
        },
      }
    case updateJobTeamAccessActions.REQUEST_FAILURE:
      return {
        ...state,
        updateJobTeamAccessStates: {
          ...state.updateJobTeamAccessStates,
          [action.key]: 'failure',
        },
      }

    // Remove Candidate
    case removeCandidatesActions.REQUEST:
      return {
        ...state,
        removeCandidatesStates: {
          ...state.removeCandidatesStates,
          [action.key]: 'loading',
        },
      }
    case removeCandidatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        removeCandidatesStates: {
          ...state.removeCandidatesStates,
          [action.key]: 'success',
        },
      }
    case removeCandidatesActions.REQUEST_FAILURE:
      return {
        ...state,
        removeCandidatesStates: {
          ...state.removeCandidatesStates,
          [action.key]: 'failure',
        },
      }

    // Create Unowned Candidate
    case createUnownedCandidateActions.REQUEST:
      return {
        ...state,
        createCandidateStates: {
          ...state.createCandidateStates,
          [action.key]: 'loading',
        },
      }
    case createUnownedCandidateActions.REQUEST_SUCCESS:
      return {
        ...state,
        createCandidateStates: {
          ...state.createCandidateStates,
          [action.key]: 'success',
        },
        createCandidateResults: {
          ...state.createCandidateResults,
          [action.key]: action.data,
        },
      }
    case createUnownedCandidateActions.REQUEST_FAILURE:
      return {
        ...state,
        createCandidateStates: {
          ...state.createCandidateStates,
          [action.key]: 'failure',
        },
      }

    // Enrich Candidate
    case enrichCandidatesActions.REQUEST:
      return {
        ...state,
        enrichCandidatesStates: {
          ...state.enrichCandidatesStates,
          [action.key]: 'loading',
        },
      }
    case enrichCandidatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        enrichCandidatesStates: {
          ...state.enrichCandidatesStates,
          [action.key]: 'success',
        },
      }
    case enrichCandidatesActions.REQUEST_FAILURE:
      return {
        ...state,
        enrichCandidatesStates: {
          ...state.enrichCandidatesStates,
          [action.key]: 'failure',
        },
      }

    // Archived Candidates
    case archivedCandidatesActions.REQUEST:
      return {
        ...state,
        archivedCandidatesStates: {
          ...state.archivedCandidatesStates,
          [action.key]: 'loading',
        },
      }
    case archivedCandidatesActions.REQUEST_SUCCESS:
      return {
        ...state,
        archivedCandidatesStates: {
          ...state.archivedCandidatesStates,
          [action.key]: 'success',
        },
        archivedCandidatesResults: {
          ...state.archivedCandidatesResults,
          [action.key]: action.data,
        },
      }
    case archivedCandidatesActions.REQUEST_FAILURE:
      return {
        ...state,
        archivedCandidatesStates: {
          ...state.archivedCandidatesStates,
          [action.key]: 'failure',
        },
      }

    // Update Candidate Archived Status
    case updateCandidateArchivedStatusActions.REQUEST:
      return {
        ...state,
        updateCandidateArchivedStatusStates: {
          ...state.updateCandidateArchivedStatusStates,
          [action.key]: 'loading',
        },
      }
    case updateCandidateArchivedStatusActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateCandidateArchivedStatusStates: {
          ...state.updateCandidateArchivedStatusStates,
          [action.key]: 'success',
        },
      }
    case updateCandidateArchivedStatusActions.REQUEST_FAILURE:
      return {
        ...state,
        updateCandidateArchivedStatusStates: {
          ...state.updateCandidateArchivedStatusStates,
          [action.key]: 'failure',
        },
      }

    // Duplicate Job
    case duplicateJobActions.REQUEST:
      return {
        ...state,
        duplicateJobStates: {
          ...state.duplicateJobStates,
          [action.key]: 'loading',
        },
      }
    case duplicateJobActions.REQUEST_SUCCESS:
      return {
        ...state,
        duplicateJobStates: {
          ...state.duplicateJobStates,
          [action.key]: 'success',
        },
        duplicateJobResults: {
          ...state.duplicateJobResults,
          [action.key]: action.data,
        },
      }
    case duplicateJobActions.REQUEST_FAILURE:
      return {
        ...state,
        duplicateJobStates: {
          ...state.duplicateJobStates,
          [action.key]: 'failure',
        },
      }

    // Send candidate email
    case sendEmailActions.REQUEST:
      return {
        ...state,
        sendEmailStates: {
          ...state.sendEmailStates,
          [action.key]: 'loading',
        },
      }
    case sendEmailActions.REQUEST_SUCCESS:
      return {
        ...state,
        sendEmailStates: {
          ...state.sendEmailStates,
          [action.key]: 'success',
        },
      }
    case sendEmailActions.REQUEST_FAILURE:
      return {
        ...state,
        sendEmailStates: {
          ...state.sendEmailStates,
          [action.key]: 'failure',
        },
      }

    // Send candidate reminder email
    case sendReminderActions.REQUEST:
      return {
        ...state,
        sendReminderStates: {
          ...state.sendReminderStates,
          [action.key]: 'loading',
        },
      }
    case sendReminderActions.REQUEST_SUCCESS:
      return {
        ...state,
        sendReminderStates: {
          ...state.sendReminderStates,
          [action.key]: 'success',
        },
      }
    case sendReminderActions.REQUEST_FAILURE:
      return {
        ...state,
        sendReminderStates: {
          ...state.sendReminderStates,
          [action.key]: 'failure',
        },
      }

    // Activate Candidate
    case activateCandidateActions.REQUEST:
      return {
        ...state,
        activateCandidateStates: {
          ...state.activateCandidateStates,
          [action.key]: 'loading',
        },
      }
    case activateCandidateActions.REQUEST_SUCCESS:
      return {
        ...state,
        activateCandidateStates: {
          ...state.activateCandidateStates,
          [action.key]: 'success',
        },
      }
    case activateCandidateActions.REQUEST_FAILURE:
      return {
        ...state,
        activateCandidateStates: {
          ...state.activateCandidateStates,
          [action.key]: 'failure',
        },
      }

    // Destroy Candidate
    case destroyCandidateActions.REQUEST:
      return {
        ...state,
        destroyCandidateStates: {
          ...state.destroyCandidateStates,
          [action.key]: 'loading',
        },
      }
    case destroyCandidateActions.REQUEST_SUCCESS:
      return {
        ...state,
        destroyCandidateStates: {
          ...state.destroyCandidateStates,
          [action.key]: 'success',
        },
      }
    case destroyCandidateActions.REQUEST_FAILURE:
      return {
        ...state,
        destroyCandidateStates: {
          ...state.destroyCandidateStates,
          [action.key]: 'failure',
        },
      }

    // Update Survey
    case updateSurveyActions.REQUEST:
      return {
        ...state,
        updateSurveyStates: {
          ...state.updateSurveyStates,
          [action.key]: 'loading',
        },
      }
    case updateSurveyActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateSurveyStates: {
          ...state.updateSurveyStates,
          [action.key]: 'success',
        },
      }
    case updateSurveyActions.REQUEST_FAILURE:
      return {
        ...state,
        updateSurveyStates: {
          ...state.updateSurveyStates,
          [action.key]: 'failure',
        },
      }

    // Compare Survey Answers
    case compareSurveysActions.REQUEST:
      return {
        ...state,
        compareSurveysStates: {
          ...state.compareSurveysStates,
          [action.key]: 'loading',
        },
      }
    case compareSurveysActions.REQUEST_SUCCESS:
      return {
        ...state,
        compareSurveysStates: {
          ...state.compareSurveysStates,
          [action.key]: 'success',
        },
        compareSurveysResults: {
          ...state.compareSurveysResults,
          [action.key]: action.data,
        },
      }
    case compareSurveysActions.REQUEST_FAILURE:
      return {
        ...state,
        compareSurveysStates: {
          ...state.compareSurveysStates,
          [action.key]: 'failure',
        },
      }

    // Update Survey Answer
    case updateSurveyAnswerActions.REQUEST:
      return {
        ...state,
        updateSurveyAnswerStates: {
          ...state.updateSurveyAnswerStates,
          [action.key]: 'loading',
        },
      }
    case updateSurveyAnswerActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateSurveyAnswerStates: {
          ...state.updateSurveyAnswerStates,
          [action.key]: 'success',
        },
      }
    case updateSurveyAnswerActions.REQUEST_FAILURE:
      return {
        ...state,
        updateSurveyAnswerStates: {
          ...state.updateSurveyAnswerStates,
          [action.key]: 'failure',
        },
      }

    default:
      return state
  }
}
