import * as publicDataActions from '@dashboard/store/actions/discData/public/data'
import * as JobTitleAverageActions from '@dashboard/store/actions/discData/job_title'
import * as TopThreeTraitsActions from '@dashboard/store/actions/discData/topThreeTraits'
import * as customPitchQuestionsActions from '@dashboard/store/actions/discData/pitches/questions'
import * as createCustomPitchesActions from '@dashboard/store/actions/discData/pitches/create'

import { CustomPitchDatum } from '@dashboard/store/actions/discData/pitches/questions'

import {
  KeyedStateHelper,
  LoadingStates,
  ProfileContent,
} from '@dashboard/lib/types'

export interface State {
  jobTitleAverageStates: KeyedStateHelper<LoadingStates>
  jobTitleAverageResults: KeyedStateHelper<JobTitleAverageActions.JobTitleResponse>
  topThreeTraitsState: LoadingStates
  topThreeTraits: null | TopThreeTraitsActions.TopThreeTraitsArchetype[]
  publicDataStates: KeyedStateHelper<LoadingStates>
  publicDataResults: KeyedStateHelper<ProfileContent>
  createCustomPitchesState: LoadingStates
  customPitchQuestionsState: LoadingStates
  customPitchQuestions: CustomPitchDatum[]
}

const INITIAL_STATE: State = {
  jobTitleAverageStates: {},
  jobTitleAverageResults: {},
  topThreeTraitsState: 'default',
  topThreeTraits: null,
  publicDataStates: {},
  publicDataResults: {},
  createCustomPitchesState: 'default',
  customPitchQuestionsState: 'default',
  customPitchQuestions: [],
}

type Action =
  | JobTitleAverageActions.Action
  | TopThreeTraitsActions.Action
  | publicDataActions.Action
  | customPitchQuestionsActions.Action
  | createCustomPitchesActions.Action

export default function discDataReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Job Title Average Disc Type
    case JobTitleAverageActions.REQUEST:
      return {
        ...state,
        jobTitleAverageStates: {
          ...state.jobTitleAverageStates,
          [action.jobTitle]: 'loading',
        },
      }

    case JobTitleAverageActions.REQUEST_SUCCESS:
      return {
        ...state,
        jobTitleAverageStates: {
          ...state.jobTitleAverageStates,
          [action.jobTitle]: 'success',
        },
        jobTitleAverageResults: {
          ...state.jobTitleAverageResults,
          [action.jobTitle]: action.data,
        },
      }

    case JobTitleAverageActions.REQUEST_FAILURE:
      return {
        ...state,
        jobTitleAverageStates: {
          ...state.jobTitleAverageStates,
          [action.jobTitle]: 'failure',
        },
      }

    // Top three traits
    case TopThreeTraitsActions.REQUEST:
      return {
        ...state,
        topThreeTraitsState: 'loading',
      }

    case TopThreeTraitsActions.REQUEST_SUCCESS:
      return {
        ...state,
        topThreeTraitsState: 'success',
        topThreeTraits: action.data,
      }

    case TopThreeTraitsActions.REQUEST_FAILURE:
      return {
        ...state,
        topThreeTraitsState: 'failure',
      }

    // Public data
    case publicDataActions.REQUEST:
      return {
        ...state,
        publicDataStates: {
          ...state.publicDataStates,
          [action.key]: 'loading',
        },
      }

    case publicDataActions.REQUEST_SUCCESS:
      return {
        ...state,
        publicDataStates: {
          ...state.publicDataStates,
          [action.key]: 'success',
        },
        publicDataResults: {
          ...state.publicDataResults,
          [action.key]: action.data,
        },
      }

    case publicDataActions.REQUEST_FAILURE:
      return {
        ...state,
        publicDataStates: {
          ...state.publicDataStates,
          [action.key]: 'failure',
        },
      }

    // Custom Pitch Questions
    case customPitchQuestionsActions.REQUEST:
      return {
        ...state,
        customPitchQuestionsState: 'loading',
      }

    case customPitchQuestionsActions.REQUEST_SUCCESS:
      return {
        ...state,
        customPitchQuestionsState: 'success',
        customPitchQuestions: action.data,
      }

    case customPitchQuestionsActions.REQUEST_FAILURE:
      return {
        ...state,
        customPitchQuestionsState: 'failure',
      }

    // Create Custom Pitches
    case createCustomPitchesActions.REQUEST:
      return {
        ...state,
        createCustomPitchesState: 'loading',
      }

    case createCustomPitchesActions.REQUEST_SUCCESS:
      return {
        ...state,
        createCustomPitchesState: 'success',
      }

    case createCustomPitchesActions.REQUEST_FAILURE:
      return {
        ...state,
        createCustomPitchesState: 'failure',
      }

    default:
      return state
  }
}
