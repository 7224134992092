import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'

export function assignProfileTagKey(profileId: string, tagName: string) {
  return `${profileId}-${tagName}`
}

export const REQUEST = 'PROFILE_TAGS_ASSIGN_START'
type requestAction = { type: typeof REQUEST; key: string }
const request = (key: string): requestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'PROFILE_TAGS_ASSIGN_SUCCESS'
type requestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  key: string
}
const requestSuccess = (key: string): requestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
})

export const REQUEST_FAILURE = 'PROFILE_TAGS_ASSIGN_FAILURE'
type requestFailureAction = { type: typeof REQUEST_FAILURE; key: string }
const requestFailure = (key: string): requestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = requestAction | requestSuccessAction | requestFailureAction

export function assignTag(profileId: string, tag_name: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = assignProfileTagKey(profileId, tag_name)
      dispatch(request(key))

      Api()
        .post(`/profiles/${profileId}/groupings`, { tag_name })
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(key))
          reject(
            `Error calling assignTag (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
