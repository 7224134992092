import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { APIPagedResponse, ScoreAverageObject } from '@dashboard/lib/types'

export const getJobScoresQuery = (params: getScoresParams): string => {
  return JSON.stringify(params)
}

export const REQUEST = 'LOAD_CANDIDATE_SCORES_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'LOAD_CANDIDATE_SCORES_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<ScoreAverageObject>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<ScoreAverageObject>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'LOAD_CANDIDATE_SCORES_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export interface getScoresParams {
  playbook_id: string
  page: number
}

export function loadCandidateScores(params: getScoresParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<ScoreAverageObject>>(
      (resolve, reject) => {
        const key = getJobScoresQuery(params)

        dispatch(request(key))

        Api()
          .get<APIPagedResponse<ScoreAverageObject>>(
            '/job_interview_assessments',
            {
              params,
            },
          )
          .then(({ data }) => {
            dispatch(requestSuccess(key, data))
            resolve(data)
          })
          .catch(error => {
            dispatch(requestFailure(key, error))

            reject(error.response?.data)
          })
      },
    )
  }
}
