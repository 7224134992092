import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface DemographicsResponse {
  id: string
  created_at: string
  updated_at: string
  age: string | null
  gender: string | null
  education: string | null
  language: string | null
  industry: string | null
  uuid: string
  profile_id: string
}

export const REQUEST = 'DEMOGRAPHIC_GET_START'
type RequestAction = { type: typeof REQUEST }
const requestStart = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'DEMOGRAPHIC_GET_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  data: DemographicsResponse
}
const requestSuccess = (data: DemographicsResponse): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'DEMOGRAPHIC_GET_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getDemographics() {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      dispatch(requestStart())

      Api()
        .get<APIResponse<DemographicsResponse>>('/demographics')
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))
          resolve(data.data)
        })
        .catch(error => {
          dispatch(requestFailure(error))
          reject(error.response?.data)
        })
    })
  }
}
