export const REQUEST = 'UPDATE_CHECKLIST_START'
type RequestAction = { type: typeof REQUEST }

export const REQUEST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
}

export const REQUEST_FAILURE = 'UPDATE_CHECKLIST_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
