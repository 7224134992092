import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'

import { Profile, APIResponse } from '@dashboard/lib/types'

export const REQUEST = 'PUBLIC_PROFILE_FIND_START'
interface RequestAction {
  type: typeof REQUEST
  uuid: string
}
const request = (uuid: string): RequestAction => ({
  type: REQUEST,
  uuid,
})

export const REQUEST_SUCCESS = 'PUBLIC_PROFILE_FIND_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  uuid: string
  profile: Profile
}
const requestSuccess = (
  uuid: string,
  profile: Profile,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  uuid,
  profile,
})

export const REQUEST_FAILURE = 'PUBLIC_PROFILE_FIND_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  uuid: string
  error: unknown
}
const requestFailure = (
  uuid: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  uuid,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

function loadPublicProfileRequest(profileId: string) {
  return PublicApi().get<APIResponse<Profile>>(`/public/profiles/${profileId}`)
}

export function loadPublicProfile(profileId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<Profile>((resolve, reject) => {
      dispatch(request(profileId))

      loadPublicProfileRequest(profileId)
        .then(({ data }) => {
          dispatch(requestSuccess(profileId, data.data))

          if (data.data.id !== profileId)
            dispatch(requestSuccess(data.data.id, data.data))

          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(profileId, err))
          reject(
            `Error calling loadPublicProfile (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
