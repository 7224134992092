import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { Profile } from '@dashboard/lib/types'
import { profileQuery } from '@dashboard/lib/profile'

interface ReferralProfile extends Profile {
  signed_up_at: string
  upgraded_at: string | null
  product_price: number | null
  plan_type: string | null
  plan_active: boolean
}

interface PartnerReferral {
  id: string
  profile: ReferralProfile | null
  email: string
}

export interface PartnersResponse {
  data: {
    referrals: PartnerReferral[]
    referrals_accepted: number
    referrals_premium_yearly: number
    referrals_premium_monthly: number
    referrals_leadership_yearly: number
    referrals_leadership_monthly: number
    referrals_sales_yearly: number
    referrals_sales_monthly: number
  }
  total_pages: number
  total_count: number
}

export const REQUEST = 'REFERRALS_GET_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'REFERRALS_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: PartnersResponse
  query: string
}
const requestSuccess = (
  data: PartnersResponse,
  query: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
  query,
})

export const REQUEST_FAILURE = 'REFERRALS_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  query: string
}
const requestFailure = (
  error: unknown,
  query: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  query,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getReferrals(
  page: number,
  filters: Array<string>,
  query: string,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      const fullQuery = profileQuery(page, filters, query)

      dispatch(request(fullQuery))

      const params = { page, filters: '', query: '' }
      params.filters = filters.join(',')
      if (query) params.query = query

      Api()
        .get<PartnersResponse>('/referrals', { params })
        .then(({ data }) => {
          dispatch(requestSuccess(data, fullQuery))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(err, fullQuery))
          reject(
            `Error calling getReferrals (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
