import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { PublicApi } from '@dashboard/lib/api'
import { Profile, APIPagedResponse } from '@dashboard/lib/types'

export interface PublicCelebQueryOptions {
  query?: string
  disc?: string
  enneagram?: string
  myers_briggs?: string
  page?: number
  per_page?: number
}

export function publicCelebritiesKey(options: PublicCelebQueryOptions = {}) {
  return `${options.query}-${options.page}-${options.disc}-${options.enneagram}-${options.myers_briggs}-${options.per_page}`
}

export const REQUEST = 'CELEBRITY_INDEX_REQUEST'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'CELEBRITY_INDEX_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: APIPagedResponse<Profile>
}
const requestSuccess = (
  key: string,
  data: APIPagedResponse<Profile>,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'CELEBRITY_INDEX_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
}
const requestFailure = (key: string): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function loadCelebritiesRequest(options: PublicCelebQueryOptions = {}) {
  return PublicApi().get<APIPagedResponse<Profile>>('public/celebrities', {
    params: options,
  })
}

export const loadCelebrities = (options: PublicCelebQueryOptions = {}) => {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<APIPagedResponse<Profile>>((resolve, reject) => {
      const key = publicCelebritiesKey(options)
      dispatch(request(key))

      loadCelebritiesRequest(options)
        .then(({ data }) => {
          window.setTimeout(() => {
            dispatch(requestSuccess(key, data))
            resolve(data)
          }, 2000)
        })
        .catch(err => {
          dispatch(requestFailure(key))
          reject(
            `Error calling loadCelebrities (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
