import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api, AuthedHeaders } from '@dashboard/lib/api'

import { Profile, APIResponse } from '@dashboard/lib/types'

export const LOAD_START = 'PROFILES_LOAD_START'
interface LoadStartAction {
  type: 'PROFILES_LOAD_START'
  uuid: string
}
const loadStart = (uuid: string): LoadStartAction => ({
  type: LOAD_START,
  uuid,
})

export const LOAD_SUCCESS = 'PROFILES_LOAD_SUCCESS'
interface LoadSuccessAction {
  type: 'PROFILES_LOAD_SUCCESS'
  uuid: string
  profile: Profile
}
const loadSuccess = (uuid: string, profile: Profile): LoadSuccessAction => ({
  type: LOAD_SUCCESS,
  uuid,
  profile,
})

export const LOAD_FAILURE = 'PROFILES_LOAD_FAILURE'
interface LoadFailureAction {
  type: 'PROFILES_LOAD_FAILURE'
  uuid: string
  errorCode: number
}
const loadFailure = (uuid: string, errorCode: number): LoadFailureAction => ({
  type: LOAD_FAILURE,
  uuid,
  errorCode,
})

export type Action = LoadStartAction | LoadSuccessAction | LoadFailureAction

function loadProfileRequest(uuid: string, headers?: AuthedHeaders) {
  return Api(headers).get<APIResponse<Profile>>(`/profiles/${uuid}`)
}

export function loadProfile(uuid: string, headers?: AuthedHeaders) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<Profile>((resolve, reject) => {
      dispatch(loadStart(uuid))

      if (!uuid) {
        dispatch(loadFailure(uuid, 400))
        return
      }

      loadProfileRequest(uuid, headers)
        .then(({ data }) => {
          const profile = data.data

          dispatch(loadSuccess(uuid, profile))

          if (profile.id !== uuid) dispatch(loadSuccess(profile.id, profile))

          resolve(profile)
        })
        .catch(err => {
          const errorCode = err.response?.status || 400
          dispatch(loadFailure(uuid, errorCode))
          reject(
            `Error calling loadProfile (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
