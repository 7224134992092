import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AxiosError } from 'axios'

import { Api } from '@dashboard/lib/api'
import { APIPagedResponse, Profile } from '@dashboard/lib/types'
import { GroupDepartment } from './departments'

export interface GetGroupsParams {
  page?: number
  per_page?: number
  query?: string
}

export interface GroupsResponse {
  id: string
  name: string
  privacy: 'private' | 'teammate' | 'organization'
  created_by: Profile
  team_id: string
  created_at: string
  updated_at: string
  profiles: Profile[]
  total_profiles: number
  leader_experience_level: string
  group_age: string
  departments: GroupDepartment[]
  collaborators: { id: string; name: string }[]
  member: boolean
  leader: Profile
}

export function getGroupsKey({ page, per_page, query }: GetGroupsParams) {
  return `${page || 1}-${per_page}-${query}`
}

export const REQUEST = 'GROUPS_INDEX_GET_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'GROUPS_INDEX_GET_SUCCESS'
const requestSuccess = (key: string, data: APIPagedResponse<GroupsResponse>) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'GROUPS_INDEX_GET_FAILURE'
const requestFailure = (key: string, error: unknown) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function getGroups(params: GetGroupsParams = {}) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = getGroupsKey(params)
      dispatch(request(key))

      Api()
        .get<APIPagedResponse<GroupsResponse>>('/groups', { params })
        .then(({ data }) => {
          dispatch(requestSuccess(key, data))
          resolve()
        })
        .catch((err: AxiosError) => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling getGroups (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
