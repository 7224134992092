import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { appName, sessionToken } from '@dashboard/lib/user'
import { APIResponse } from '@dashboard/lib/types'

export interface LoginAttributes {
  email: string
  password: string
  reactivate?: boolean
  gToken?: string
  code?: string
}

interface EndpointParams {
  auth: {
    username?: string
    password?: string
    reactivate: boolean
    gtoken?: string
  }
  session_app: string
  session_token: string
  code?: string
}

export const REQUEST = 'USER_LOGIN_START'
type RequestAction = { type: typeof REQUEST }
const requestStart = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'USER_LOGIN_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  email: string
  token: string
}
const requestSuccess = (
  email: string,
  token: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  email,
  token,
})

export const REQUEST_FAILURE = 'USER_LOGIN_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  email: string
  error: unknown
}
const requestFailure = (
  email: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  email,
  error,
})

export const REQUEST_TWO_FACTOR = 'USER_LOGIN_TWO_FACTOR'
type RequestTwoFactorAction = {
  type: typeof REQUEST_TWO_FACTOR
  email: string
}
const requestTwoFactor = (email: string): RequestTwoFactorAction => ({
  type: REQUEST_TWO_FACTOR,
  email,
})

export const REQUEST_FORBIDDEN = 'USER_LOGIN_FORBIDDEN'
type RequestForbiddenAction = {
  type: typeof REQUEST_FORBIDDEN
  email: string
}
const requestForbidden = (email: string): RequestForbiddenAction => ({
  type: REQUEST_FORBIDDEN,
  email,
})

export type Action =
  | RequestAction
  | RequestSuccessAction
  | RequestFailureAction
  | RequestTwoFactorAction
  | RequestForbiddenAction

export function login(attributes: LoginAttributes) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(requestStart())

      const params: EndpointParams = {
        auth: {
          reactivate: attributes.reactivate ? attributes.reactivate : false,
        },
        session_app: appName(),
        session_token: sessionToken(),
      }

      if (attributes.email) {
        params.auth.username = attributes.email
        params.auth.password = attributes.password
      }

      if (attributes.gToken) params.auth.gtoken = attributes.gToken
      if (attributes.code) params.code = attributes.code

      Api()
        .post<APIResponse<{ token: string }>>('user_token', params)
        .then(({ data }) => {
          if (!data?.data?.token) {
            dispatch(requestTwoFactor(attributes.email))
          } else {
            dispatch(requestSuccess(attributes.email, data.data.token))
          }
          resolve(data?.data?.token)
        })
        .catch(error => {
          if (error.response?.status === 403)
            dispatch(requestForbidden(attributes.email))
          else dispatch(requestFailure(attributes.email, error))

          reject(error.response?.data)
        })
    })
  }
}
