import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { Profile, APIPagedResponse } from '@dashboard/lib/types'
import md5 from 'md5'

export const REQUEST = 'PROFILES_GET_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'PROFILES_GET_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  profiles: APIPagedResponse<Profile>
  query: string
}
const requestSuccess = (
  profiles: APIPagedResponse<Profile>,
  query: string,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  profiles,
  query,
})

export const REQUEST_FAILURE = 'PROFILES_GET_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  error: unknown
  query: string
}
const requestFailure = (
  error: unknown,
  query: string,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
  query,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
export type Filter = 'predicted' | 'verified'
export type ProfileType =
  | 'teammate'
  | 'coworker'
  | 'predicted'
  | 'candidate'
  | 'celebrity'
  | 'viewed'
  | 'assessment'
export type SortField = null | 'recently_viewed'

interface RouteParams {
  page: number
  query: string
  exclude_self: boolean
  per_page?: number
  trait_key?: number
  disc_type?: string
  tags?: string
  verified?: boolean
  predicted?: boolean
  types?: string
  sort?: SortField
  direction?: null | 'asc' | 'desc'
  with_personality?: true
  exclude_team_predictions?: true
  allow_empty_query?: string
  order_by_type?:
    | 'api'
    | 'owned'
    | 'unowned'
    | 'candidate'
    | 'assessment'
    | 'celebrity'
  job_id?: string
  job_fit?: number
}

export interface GetProfileOptions {
  page: number
  filter?: Filter[]
  query?: string
  limit?: number
  trait_key?: number
  disc_type?: string
  tags?: string[]
  exclude_self?: boolean
  types?: ProfileType[]
  sort?: SortField
  direction?: null | 'asc' | 'desc'
  with_personality?: true
  exclude_team_predictions?: true
  order_by_type?:
    | 'api'
    | 'owned'
    | 'unowned'
    | 'candidate'
    | 'assessment'
    | 'celebrity'
  job_id?: string
  job_fit?: number
}

export const profileQuery = ({
  page,
  filter,
  query,
  limit,
  trait_key,
  disc_type,
  tags,
  exclude_self,
  types,
  sort,
  direction,
  with_personality,
  exclude_team_predictions,
  order_by_type,
  job_id,
  job_fit,
}: GetProfileOptions) => {
  return md5(
    [
      page,
      filter?.join('-'),
      query,
      limit,
      trait_key,
      disc_type,
      tags?.join('-'),
      exclude_self,
      types?.join('-'),
      sort,
      direction,
      with_personality,
      exclude_team_predictions,
      order_by_type,
      job_id,
      job_fit,
    ].join('-'),
  )
}

export function getProfiles(options: GetProfileOptions) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise((resolve, reject) => {
      const queryString = profileQuery(options)
      const {
        page,
        filter,
        query,
        limit,
        trait_key,
        disc_type,
        sort,
        direction,
        with_personality,
        order_by_type,
        exclude_team_predictions,
        job_id,
        job_fit,
      } = options
      const exclude_self = options.exclude_self ?? true
      const types = options.types || []
      const tags = options.tags || []
      dispatch(request(queryString))

      const params: RouteParams = {
        page,
        query: '',
        per_page: 20,
        exclude_self,
        allow_empty_query: 'true',
      }

      if (filter && filter.includes('predicted')) params.predicted = true
      if (filter && filter.includes('verified')) params.verified = true
      if (with_personality) params.with_personality = true
      if (exclude_team_predictions) params.exclude_team_predictions = true

      if (query) params.query = query
      if (limit) params.per_page = limit
      if (trait_key) params.trait_key = trait_key
      if (disc_type) params.disc_type = disc_type
      if (types.length > 0) params.types = types.join(',')
      if (tags.length > 0) params.tags = tags.join(',')
      if (sort) params.sort = sort
      if (direction) params.direction = direction
      if (order_by_type) params.order_by_type = order_by_type
      if (job_id) params.job_id = job_id
      if (job_fit !== undefined) params.job_fit = job_fit
      const profileEndPoint =
        types[0] === 'viewed' ? '/profiles/viewed' : '/profiles'
      Api()
        .get<APIPagedResponse<Profile>>(profileEndPoint, { params })
        .then(({ data }) => {
          dispatch(requestSuccess(data, queryString))

          resolve(data)
        })
        .catch(err => {
          dispatch(requestFailure(err, queryString))

          reject(
            `Error calling getProfiles (Status ${err.response?.status}): ${err.response?.data}`,
          )
        })
    })
  }
}
