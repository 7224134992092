import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse, JobSurveyTaker, Profile } from '@dashboard/lib/types'

export interface JobSurveyAnswer {
  id: number
  job_survey_id: string
  job_survey_question_id: number
  score: number
  taken_by: Profile | JobSurveyTaker
  question: {
    id: number
    text: string
  }
}

export type CompareSurveyAnswers = [JobSurveyAnswer, JobSurveyAnswer]

export type CompareSurveysResponse = Record<number, CompareSurveyAnswers>

export function compareSurveysKey(jobId: string, survey_ids: [string, string]) {
  return `${jobId}-${survey_ids.join('-')}`
}

export const REQUEST = 'SURVEY_COMPARE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'SURVEY_COMPARE_SUCCESS'
const requestSuccess = (key: string, data: CompareSurveysResponse) =>
  ({
    type: REQUEST_SUCCESS,
    key,
    data,
  }) as const

export const REQUEST_FAILURE = 'SURVEY_COMPARE_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function compareSurveys(jobId: string, survey_ids: [string, string]) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<CompareSurveysResponse>((resolve, reject) => {
      const key = compareSurveysKey(jobId, survey_ids)
      dispatch(request(key))

      Api()
        .post<APIResponse<CompareSurveysResponse>>(
          `/jobs/${jobId}/surveys/compare`,
          {
            survey_ids,
          },
        )
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))
          resolve(data.data)
        })
        .catch(err => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling compareSurveys (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
