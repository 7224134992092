import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'
import { UserCharge } from '.'

export interface SingleChargeResponse {
  customer: {
    address: SingleChargeCustomerAddress
  } | null
  charge: UserCharge
}

export interface SingleChargeCustomerAddress {
  city: string
  country: string
  line1: string
  line2: string
  postal_code: string
  state: string
}

export const REQUEST = 'GET_RECEIPTS_START'
interface RequestAction {
  type: typeof REQUEST
  chargeId: string
}
const request = (chargeId: string): RequestAction => ({
  type: REQUEST,
  chargeId,
})

export const REQUEST_SUCCESS = 'GET_RECEIPTS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  chargeId: string
  receipt: SingleChargeResponse
}
const RequestSuccess = (
  chargeId: string,
  receipt: SingleChargeResponse,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  chargeId,
  receipt,
})

export const REQUEST_FAILURE = 'GET_RECEIPTS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  chargeId: string
  error: unknown
}
const RequestFailure = (
  chargeId: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  chargeId,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getCharge(chargeId: string) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<SingleChargeResponse>((resolve, reject) => {
      dispatch(request(chargeId))

      Api()
        .get<APIResponse<SingleChargeResponse>>(`charges/${chargeId}`)
        .then(({ data }) => {
          dispatch(RequestSuccess(chargeId, data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(RequestFailure(chargeId, err))
          reject(
            `Error calling getCharge (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
