import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api, AuthedHeaders } from '@dashboard/lib/api'
import { FullAuthUser, APIResponse } from '@dashboard/lib/types'
import { deleteCookie } from '@dashboard/lib/browser'
import * as ls from 'local-storage'
import {
  CRYSTAL_AUTH_TOKEN,
  CRYSTAL_SESSION_TOKEN,
} from '@dashboard/lib/constants'

interface AuthMeResponse {
  user: FullAuthUser
  refreshed_token: string
  paragon_token: string
}

export const REQUEST = 'GET_ME_REQUEST'
interface RequestAction {
  type: typeof REQUEST
}
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'GET_ME_REQUEST_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: FullAuthUser
}
const requestSuccess = (data: FullAuthUser): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  data,
})

export const REQUEST_FAILURE = 'GET_ME_REQUEST_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}
const requestFailure = (): RequestFailureAction => ({
  type: REQUEST_FAILURE,
})

export const REQUEST_UNAUTHORIZED = 'USER_LOAD_UNAUTHORIZED'
type requestUnauthorizedAction = {
  type: typeof REQUEST_UNAUTHORIZED
  error: unknown
}
const requestUnauthorized = (error: unknown): requestUnauthorizedAction => ({
  type: REQUEST_UNAUTHORIZED,
  error,
})

export type Action =
  | RequestAction
  | RequestSuccessAction
  | RequestFailureAction
  | requestUnauthorizedAction

export function loadMeRequest(headers?: AuthedHeaders) {
  return Api(headers).get<APIResponse<AuthMeResponse>>('me')
}

export function loadMe(headers?: AuthedHeaders) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<FullAuthUser>((resolve, reject) => {
      dispatch(request())

      loadMeRequest(headers)
        .then(({ data }) => {
          dispatch(requestSuccess(data.data.user))
          resolve(data.data.user)
        })
        .catch(err => {
          reject(
            `Error calling loadMe (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )

          if (err.response && err.response.status === 401 && !headers) {
            ls.clear()
            deleteCookie(CRYSTAL_AUTH_TOKEN)
            deleteCookie(CRYSTAL_SESSION_TOKEN)
            dispatch(requestUnauthorized(err))
          } else {
            dispatch(requestFailure())
          }
        })
    })
  }
}
