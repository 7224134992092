import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse, DiscType, ContentSuggestion } from '@dashboard/lib/types'

export const REQUEST = 'GET_SUGGESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
  query: string
}
const request = (query: string): RequestAction => ({
  type: REQUEST,
  query,
})

export const REQUEST_SUCCESS = 'GET_SUGGESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  query: DiscType
  data: ContentSuggestion
}
const requestSuccess = (
  query: DiscType,
  data: ContentSuggestion,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  query,
  data,
})

export const REQUEST_FAILURE = 'GET_SUGGESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  query: DiscType
  error: unknown
}
const requestFailure = (
  query: DiscType,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  query,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getDiscSuggestions(query: DiscType) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<ContentSuggestion[] | null>((resolve, reject) => {
      dispatch(request(query))

      Api()
        .post<APIResponse<ContentSuggestion>>('/suggestions', {
          disc_type: query,
        })
        .then(({ data }) => {
          dispatch(requestSuccess(query, data.data))

          resolve([data.data])
        })
        .catch(err => {
          dispatch(requestFailure(query, err))

          reject(
            `Error calling getDiscSuggestions (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
