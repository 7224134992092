import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { Products } from '@dashboard/lib/types'

export const REQUEST = 'UNASSIGN_PRODUCT_START'
type RequestAction = { type: typeof REQUEST; profile_id: string }
const request = (profile_id: string): RequestAction => ({
  type: REQUEST,
  profile_id,
})

export const REQUEST_SUCCESS = 'UNASSIGN_PRODUCT_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  profile_id: string
}
const requestSuccess = (profile_id: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  profile_id,
})

export const REQUEST_FAILURE = 'UNASSIGN_PRODUCT_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  profile_id: string
  error: unknown
}

const requestFailure = (
  profile_id: string,
  error: unknown,
): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  profile_id,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function unassignProducts(profile_id: string, type: Products) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(request(profile_id))

      Api()
        .put('/products/unassign', {
          profile_id,
          type,
        })
        .then(() => {
          dispatch(requestSuccess(profile_id))
          resolve()
        })
        .catch(err => {
          dispatch(requestFailure(profile_id, err))
          reject(
            `Error calling unassignProduct (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
