import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'

import { APIResponse } from '@dashboard/lib/types'
import { AxiosError } from 'axios'

export interface UpdateJobSurveyParams {
  exclude: boolean
}

export function updateJobSurveyQueryKey(jobId: string, surveyId: string) {
  return `${jobId}-${surveyId}`
}

export const REQUEST = 'SURVEY_UPDATE_START'
const request = (key: string) =>
  ({
    type: REQUEST,
    key,
  }) as const

export const REQUEST_SUCCESS = 'SURVEY_UPDATE_SUCCESS'
const requestSuccess = (key: string) =>
  ({
    type: REQUEST_SUCCESS,
    key,
  }) as const

export const REQUEST_FAILURE = 'SURVEY_UPDATE_FAILURE'
const requestFailure = (key: string, error: string) =>
  ({
    type: REQUEST_FAILURE,
    key,
    error,
  }) as const

export type Action =
  | ReturnType<typeof request>
  | ReturnType<typeof requestSuccess>
  | ReturnType<typeof requestFailure>

export function updateJobSurvey(
  jobId: string,
  surveyId: string,
  params: UpdateJobSurveyParams,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<void>((resolve, reject) => {
      const key = updateJobSurveyQueryKey(jobId, surveyId)
      dispatch(request(key))

      Api()
        .put<APIResponse<{ id: string }>>(
          `/jobs/${jobId}/surveys/${surveyId}`,
          params,
        )
        .then(() => {
          dispatch(requestSuccess(key))
          resolve()
        })
        .catch((err: AxiosError<{ message?: string }>) => {
          const errorMessage = err.response?.data?.message || ''
          dispatch(requestFailure(key, errorMessage))
          reject(
            `Error calling updateJobSurvey (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
