import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Api } from '@dashboard/lib/api'
import { ProfileContent, APIResponse } from '@dashboard/lib/types'

export interface candidateRelationshipParams {
  candidate_id: string
  profile_id: string
}

export const candidateRelationshipQueryKey = (
  params: candidateRelationshipParams,
) => {
  const { candidate_id, profile_id } = params
  return [candidate_id, profile_id].join('-')
}

export const REQUEST = 'CANDIDATE_RELATIONSHIP_START'
interface RequestAction {
  type: typeof REQUEST
  key: string
}
const request = (key: string): RequestAction => ({
  type: REQUEST,
  key,
})

export const REQUEST_SUCCESS = 'CANDIDATE_RELATIONSHIP_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  key: string
  data: ProfileContent
}
const requestSuccess = (
  key: string,
  data: ProfileContent,
): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  key,
  data,
})

export const REQUEST_FAILURE = 'CANDIDATE_RELATIONSHIP_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
  key: string
  error: unknown
}
const requestFailure = (key: string, error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  key,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function candidateRelationship(params: candidateRelationshipParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<ProfileContent>((resolve, reject) => {
      const { profile_id, candidate_id } = params
      const key = candidateRelationshipQueryKey(params)

      dispatch(request(key))

      Api()
        .post<APIResponse<ProfileContent>>(
          `/candidates/${candidate_id}/relationship`,
          { profile_id },
        )
        .then(({ data }) => {
          dispatch(requestSuccess(key, data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(key, err))
          reject(
            `Error calling candidateRelationship (Status: ${err.response
              ?.status}): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
