export interface SelfAssessmentQuestion {
  id: number
  text: string
  options: {
    datum_id: string
    text: string
  }[]
}

export const REQUEST = 'SELF_ASSESSMENT_QUESTIONS_START'
interface RequestAction {
  type: typeof REQUEST
}

export const REQUEST_SUCCESS = 'SELF_ASSESSMENT_QUESTIONS_SUCCESS'
interface RequestSuccessAction {
  type: typeof REQUEST_SUCCESS
  data: SelfAssessmentQuestion[]
}

export const REQUEST_FAILURE = 'SELF_ASSESSMENT_QUESTIONS_FAILURE'
interface RequestFailureAction {
  type: typeof REQUEST_FAILURE
}

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction
